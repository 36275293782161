import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useReserveHstStore = defineStore("reserve-hst", () => {
  const hst = ref([]);
  const msg = ref("");
  let p = 1;
  async function Init(page) {
    p = page;
    const mainProc = useLazyAsyncState(() => api.reserveHst(p), {});
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }

    if (mainProcData.length !== 0) {
      hst.value = mainProcData;
      msg.value = "";
    } else {
      msg.value = "予約履歴がありません";
    }
  }

  return {
    hst,
    Init,
    msg,
  };
});
