import { registerPlugin } from '@capacitor/core';

import type { IabPlugin } from './definitions';

const Iab = registerPlugin<IabPlugin>('Iab', {
  // web: () => import('./web').then(m => new m.IabWeb()),
});

export * from './definitions';
export { Iab };

