<script setup lang="ts">
import BlockingLoader from '@/components/blocking-loader.vue';

import { useRouter } from 'vue-router';
import { useRegisterEmailConfirmTokenStore } from './register-email-confirm-token.store';

const props = withDefaults(defineProps<{
  token: string,
}>(), {
})

const store = useRegisterEmailConfirmTokenStore();
store.init(props.token)

const router = useRouter();

</script>
<template>
  <main class="flex flex-col flex-1">
    <div class="p-4">
      <div v-if="store.mainProc.error" class="flex flex-col gap-4 text-center">
        エラーが発生しました。
        <button @click="e => {
          router.replace({ name: 'home' });
        }" class="block w-full btn">トップへ戻る
        </button>
      </div>
    </div>
    <BlockingLoader v-if="store.mainProc.isLoading"/>
  </main>
</template>
