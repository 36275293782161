<script setup lang="ts">
import TitleBar from '../../components/title-bar.vue';

import router from '@/router';
import { useRegisterEmailConfirmStore } from './register-email-confirm.store';

const store = useRegisterEmailConfirmStore();

function send(){
  store.send();
  if(store.msg == "")
  {
    router.push({ name: 'register-form' });
  }
}

function focusNext(e: any) {
  const inputs = Array.from<any>(e.target.form.querySelectorAll('input'));
  const index = inputs.indexOf(e.target);

  if (e.target.value.length > e.target.maxLength)
    e.target.value = e.target.value.slice(0, e.target.maxLength)

  if (index < inputs.length && e.data !== null) {
    inputs[index + 1]?.focus();
  } else if (index > 0 && e.data === null) {
    inputs[index - 1]?.focus();
  }
}

function onFocus(e: any) {
  e.target.setSelectionRange(0, e.target.value.length)
}

</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="メールアドレス確認" />

    <hr class="mx-4" />

    <div class="p-8">
      <p class="pb-4 font-medium">次の宛先に送信された4桁のコードを入力してください</p>

      <form class="flex gap-2">
        <input 
        v-for="(code, idx) in store.codes"
        :key="idx"
        v-model="store.codes[idx]" 
        type="tel" min="0" max="9" maxlength="1" class="text-3xl text-center w-14 h-14 form-input" @input="focusNext" @focus="onFocus"/>
      </form>
      <div class="flex items-center gap-4 mt-4">
        <button @click="send()"
          class="w-full-- text-white bg-gray-400 hover:bg-gray-300 focus:ring-4 focus:ring-[#2557D6]/50 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center items-center dark:focus:ring-[#2557D6]/50">
          確認
        </button>
        <div class="text-red-600">
          {{ store.msg }}
        </div>
      </div>
      <p class="my-4">
        メールアドレスが届かない場合は迷惑メールフォルダを確認してください。<br>
        既に登録済のメールアドレスまたはブロックしている場合メールは届きません。
      </p>
      <router-link  @click="store.send()"
        class="w-full-- text-white bg-gray-400 hover:bg-gray-300 focus:ring-4 focus:ring-[#2557D6]/50 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center items-center dark:focus:ring-[#2557D6]/50"  :to="{ name: 'register-email-send' }">
        再送信
      </router-link>
    </div>
</main>
</template>
