import { useAppStore } from '@/modules/app/app.store';
import axios from 'axios';

const APP_API_BASE_URL = window['env'].APP_API_BASE_URL;
const instance = axios.create({
  // baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  baseURL: APP_API_BASE_URL,
  withCredentials: true,
});
instance.interceptors.request.use(async (config) => {
  const appStore = useAppStore();
  config.headers.Authorization = 'Bearer ' +  await appStore.session.accessToken;
  return config;

  /** In dev, intercepts request and logs it into console for dev */
  // if (DEBUG) { console.info("✉️ ", config); }
}, (error) => {
  // if (DEBUG) { console.error("✉️ ", error); }
  return Promise.reject(error);
});



async function doRefreshToken(refreshToken) {
  return await fetch(APP_API_BASE_URL + '/refresh_token.php', {
    method: 'post',
    body: JSON.stringify({ refreshToken })
  }).then(v => v.json())
}

let refreshTokenProc = null;
instance.interceptors.response.use(response => {
  return response
}, async error => {
  const originalRequest = error.config;
  if (error.response.status == 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    const appStore = useAppStore();
    const localRefreshToken = appStore.session.refreshToken;

    if (localRefreshToken) {
      try {
        if (!refreshTokenProc) {
          refreshTokenProc = doRefreshToken(localRefreshToken);
        }

        const { accessToken, refreshToken } = await refreshTokenProc;
        refreshTokenProc = null;

        // if (accessToken && refreshToken) {
          appStore.session = {
            ...appStore.session,
            accessToken,
            refreshToken,
          }
        // }

        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        return instance(originalRequest);
      } catch (error) {
        console.log({error})
      }
    }
  }

  return Promise.reject(error);
})


export async function httpGet(url: string) {
  return (await instance.get(url)).data
}

export async function httpPost(url: string, data?: any) {
  return (await instance.post(url, data)).data
}

// export function httpGetAsync(url: string, immediate: boolean = false) {
//   return useAxios(url, instance, { immediate });
// }

// export function httpPostAsync(url: string, data?: any, immediate: boolean = false) {
//   return useAxios(url, {
//     method: 'POST',
//     data,
//   }, instance, { immediate });
// }

// export function httpPostAsync(url: string, dataProvider: Function) {
//   return useAsyncState(
//     instance
//       .post(url, dataProvider())
//       .then(t => t.data),
//     {  },
//     { immediate: false },
//   )
// }

