// Ref: https://github.com/yiminghe/async-validator

import { MaybeComputedRef } from '@vueuse/core';
import { useAsyncValidator as _useAsyncValidator, UseAsyncValidatorOptions, UseAsyncValidatorReturn } from '@vueuse/integrations/useAsyncValidator';
import { Rules, ValidateMessages } from 'async-validator';
import * as _ from 'lodash';

const messages: ValidateMessages = {
  "default": "有効な値ではありません",
  "required": "必須項目です",
  "enum": "%s must be one of %s",
  "whitespace": "%s cannot be empty",
  "date": {
    "format": "有効な日付フォーマットではありません%s%s",
    "parse": "%s date could not be parsed, %s is invalid ",
    "invalid": "有効な日付ではありません"
  },
  "types": {
    "string": "有効な文字列ではありません",
    "method": "有効な関数ではありません",
    "array": "有効な配列ではありません",
    "object": "有効なオブジェクトではありません",
    "number": "有効な数値ではありません",
    "date": "有効な日付ではありません",
    "boolean": "有効なBooleanではありません",
    "integer": "有効な数値ではありません",
    "float": "有効なFloatではありません",
    "regexp": "有効なパータンではありません",
    "email": "有効なメールアドレスではありません",
    "url": "有効なURLではありません",
    "hex": "有効なHEXではありません"
  },
  "string": {
    "len": "%s must be exactly %s characters",
    //"min": "%s must be at least %s characters",
    "min": "有効な値ではありません",
    "max": "%s cannot be longer than %s characters",
    "range": "%s must be between %s and %s characters"
  },
  "number": {
    "len": "%s must equal %s",
    // "min": "%s cannot be less than %s",
    "min": "有効な値ではありません",
    // "max": "%s cannot be greater than %s",
    "max": "有効な値ではありません",
    // "range": "%s must be between %s and %s",
    "range": "有効な値ではありません",
  },
  "array": {
    "len": "%s must be exactly %s in length",
    "min": "%s cannot be less than %s in length",
    "max": "%s cannot be greater than %s in length",
    "range": "%s must be between %s and %s in length"
  },
  "pattern": {
    "mismatch": "有効なパータンではありません"
  }
};

const useAsyncValidator = (value: MaybeComputedRef<Record<string, any>>, rules: MaybeComputedRef<Rules>, options?: UseAsyncValidatorOptions): UseAsyncValidatorReturn & PromiseLike<UseAsyncValidatorReturn> => {

  const opts = _.merge({
    validateOption: {
      messages
    },
    options
  })

  return _useAsyncValidator(value, rules, opts);
}

export { useAsyncValidator };
