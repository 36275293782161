<script setup lang="ts">
import { VueBottomSheet } from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import "leaflet/dist/leaflet.css";
import { Check, Eraser, Fish, ListChecks, Map, Search } from "lucide-vue-next";
import { onMounted, ref } from "vue";
import { useAppStore } from "@/modules/app/app.store";
import { useRouter } from "vue-router";
import { useSearchFiltersStore } from "./search-filters.store";

const store = useSearchFiltersStore();
const router = useRouter();
const appStore = useAppStore();
const prefectureSheet = ref(null);
const fishingSheet = ref(null);
const targetSheet = ref(null);

const popen = () => {
  prefectureSheet.value.open();
};
const fopen = () => {
  fishingSheet.value.open();
};
const topen = () => {
  targetSheet.value.open();
};
const pclose = (name, uuid) => {
  prefectureSheet.value.close();
  store.setPrefecture(name, uuid);
};
const fclose = (name, uuid) => {
  fishingSheet.value.close();
  store.setFishing(name, uuid);
};
function tclose(name, uuid) {
  targetSheet.value.close();
  store.setTarget(name, uuid);
}

function doSubmit() {
  store.multiFilter.ship_name = '';
  router.push({ name: "search-result", query: store.multiFilter });
}

function doSubmitShipName(name) {
  store.multiFilter.ship_name = name;
  router.push({ name: "search-result", query: store.multiFilter });
}

onMounted(() => {
  store.init();
});
</script>

<template>
  <div class="w-[90vw] mt-8 mx-auto">
    <div class="px-4 py-2 text-lg font-bold text-center bg-gray-100 text-black rounded-t-lg">
      釣り船検索
    </div>
    <div class="grid grid-cols-2 p-2">
      <a class="flex items-center justify-center p-4 border-b border-r" @click="popen">
        <Map />
        <div class="flex-1 text-center whitespace-nowrap">
          {{ store.selectPrefecture }}
        </div>
      </a>
      <vue-bottom-sheet ref="prefectureSheet" max-height="50%">
        <div @click="pclose('都道府県', '')" class="sticky top-0 z-10 flex items-center justify-between px-4 py-3 text-white bg-red-600">
          クリア<Eraser class="w-4 h-4"/>
        </div>
        <div
          @click="pclose(v, k)"
          v-for="(v, k) in store.prefecture_div"
          v-bind:key="k"
          class="flex justify-between p-4 border-t"
        >
          {{ v }}
          <Check v-if="store.prefectureCd == k"/>
        </div>
      </vue-bottom-sheet>

      <div class="flex items-center justify-center p-4 border-b" @click="fopen()">
        <Fish />
        <div class="flex-1 text-center whitespace-nowrap">{{ store.selectFishing }}</div>
      </div>

      <vue-bottom-sheet ref="fishingSheet">
        <div @click="fclose('釣り方', '')" class="sticky top-0 z-10 flex items-center justify-between px-4 py-3 text-white bg-red-600">
          クリア<Eraser class="w-4 h-4"/>
        </div>
        <div
          @click="fclose(f.fishing_name, f.fishing_uuid)"
          v-for="f in store.fishing"
          v-bind:key="f"
            class="flex justify-between p-4 border-b"
          >
          {{ f.fishing_name }}
            <Check v-if="store.multiFilter.fishing_uuid == f.fishing_uuid"/>
          </div>
      </vue-bottom-sheet>

      <div class="flex items-center justify-center p-4 border-r" @click="topen()">
        <ListChecks />
        <div class="flex-1 text-center whitespace-nowrap">{{ store.selectTarget }}</div>
      </div>
      <vue-bottom-sheet ref="targetSheet">
        <div @click="tclose('ターゲット', '')" class="sticky top-0 z-10 flex items-center justify-between px-4 py-3 text-white bg-red-600">
          クリア<Eraser class="w-4 h-4"/>
        </div>
        <div
          @click="tclose(t.target_name, t.target_uuid)"
          v-for="t in store.target"
          v-bind:key="t"
            class="flex justify-between p-4 border-b"
          >
          {{ t.target_name }}
            <Check v-if="store.targetUuid == t.target_uuid"/>
        </div>
      </vue-bottom-sheet>
    </div>

    <button class="w-full rounded-md btn bg-blue-600" @click="doSubmit">検索する</button>

    <div class="p-1 mt-10 bg-gray-200 rounded-md">
      <div class="flex">
        <div class="relative flex-1">
          <input
            placeholder="釣り船名で検索"
            class="w-full h-10 pr-2 rounded-r-none pl-9 form-input"
            v-model="store.ship_name"
          />
          <Search class="absolute w-5 -h-5 top-2 left-2" />
        </div>
        <button
          class="rounded-l-none rounded-r-md btn black"
          @click="doSubmitShipName(store.ship_name)"
        >
          検索
        </button>
      </div>
    </div>
    <div class="pt-5">
      <router-link v-if="!appStore.isAuthenticated" :to="{ name: 'register-home' }" class="text-[1.5em] block mx-auto mt-5 border-0 bg-blue-600 w-full btn p-5 focus:ring-0 focus:bg-transparent hover:bg-transparent">
        会員登録はこちら
      </router-link>
    </div>
  </div>
</template>
<style>
.bottom-sheet__card {
  @apply flex flex-col;
}
.bottom-sheet__content {
  @apply flex-1;
}
</style>
