<script setup lang="ts">
import { BusinessInfo } from '@/api/types';
import { useAppStore } from '@/modules/app/app.store';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  businessInfo: BusinessInfo,
}>(), {})

const appStore = useAppStore();
const address = computed(() => {
  return appStore.masterData.prefecture[props.businessInfo.business_prefecture_div] +
    props.businessInfo.business_city +
    props.businessInfo.business_area +
    props.businessInfo.business_address +
    props.businessInfo.business_building;
})

</script>
<template>
  <div class="flex gap-4 p-2">
    <v-img :src="businessInfo.img" class="w-24 aspect-square" v-skeletor/>
    <div class="flex flex-col flex-1 gap-1">
      <div class="font-medium">{{ businessInfo.business_name }}<span v-if="businessInfo.business_name_2">／{{ businessInfo.business_name_2 }}</span></div>
      <div v-if="businessInfo.business_place" class="text-xs text-gray-400">
        {{ businessInfo.business_place }}
      </div>
      <div v-if="!businessInfo.business_place" class="text-xs text-gray-400">
        {{ address }}
      </div>
      <div class="inline-block max-w-full overflow-hidden text-xs line-clamp-3 text-ellipsis whitespace-pre-wrap">
        {{ businessInfo.business_explain }}
      </div>
    </div>
  </div>
</template>

<style></style>
