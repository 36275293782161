import * as api from "@/api";
import { SetPasswordEmailParams } from "@/api/types";
import { useLazyAsyncState } from "@/utils";
import { useAsyncValidator } from "@/validator";
import type { Rules } from 'async-validator';
import { defineStore } from "pinia";
import { reactive, ref } from "vue";

export const useSetPasswordStore = defineStore("set-password", () => {
  // フォーム
  const form = reactive(new SetPasswordEmailParams);

  // フォームが触られたかどうか
  // const touched = ref(false);

  // フォームが触られたなら検証を行う
  const rules = {
    password: [{ required: true }],
    password_conf: [
      { required: true },
      {
        validator(rule, value, callback) {
          return value === form.password;
        },
        message: 'パスワードと一致しません',
      }
    ],

  } as Rules;
  
  // 検証用オブジェクト
  const validate = useAsyncValidator(form, rules);

  const msg = ref("");
  const suppressValidateError = ref(true);
  const mainProc = useLazyAsyncState(() => api.setPasswordEmail(form), {})
  const done = ref(false);

  return {
    suppressValidateError,
    form,
    // touched,
    validate,
    mainProc,
    done,
    msg,
    async doSubmit() {
      suppressValidateError.value = false;

      if (!validate.pass.value) {
        return;
      }
      
      const mainProcData = await mainProc.execute();
      done.value = true;
    },
  };
});
