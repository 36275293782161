import { Iab } from '../capacitor-iab/src';
import { useAppStore } from './modules/app/app.store';

const LICENSE_KEY = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgrXYFjuu78SVQ2jW5Mb8d8bESOGo7eB8yHZ2IU35gCsatRngO1aXSsDERv5xsSMiDJW3PUNV5RgStOusFBDGp/EjBEeGRHFjOJtKRaILynQ6gMnnRqvY6vQVQATn85S235Nnw62q4P6DN7x1ca0tqqWctVygKVk6Ii2uZOVzcppinKVj+EWO11qTQGVHQf9vmxJOyU9OHIc8ANCOkO6Zxb1Ipw+1/qXRaoFpXPvS4Qit+YvGr90G61beB+VL1W1fY9YoUYyb9GRQJ5JwsGnuZZCiphdiUs5QKh2oHvjwgdg9OnCUsao2dqGhOcewjC+BFMhenUQewD+Bu4r1QXRuPwIDAQAB';
const PRODUCT_ID_GOOGLE = 'scf_monthly_basic';
const PRODUCT_ID_APPLE = 'com.seacleaning.f.sub1';
export const iabSubscribe = async () => {
  const isSetupOk = await Iab.register({
    licenseKey: LICENSE_KEY,
    timeoutMillis: 30000
  })

  console.log('iab isSetupOk', isSetupOk.value)

  if (!isSetupOk.value) {
    throw new Error('アプリ内課金初期化エラー');
  }

  const appStore = useAppStore()
  const productId = appStore.isIos ? PRODUCT_ID_APPLE : PRODUCT_ID_GOOGLE;

  console.log('iab subscribe:', productId)
  const result = await Iab.subscribe({
    productId
  });

  console.log('iab release')
  await Iab.release();

  console.log('iab result', result)
  return result;
}