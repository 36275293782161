<script setup lang="ts">
import { VueBottomSheet } from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import "leaflet/dist/leaflet.css";
import { Check } from "lucide-vue-next";
import TitleBar from '@/components/title-bar.vue';
import { vMaska } from "maska";
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useBusinessReserveStore } from './business-reserve.store';

const store = useBusinessReserveStore();
const itemSheet = ref(null);
const womanSheet = ref(null);
const childSheet = ref(null);
store.initForm();

const router = useRouter();
const msg = ref("");
// onMounted(() => {
//   store.initForm();
// });
const iopen = (idx) => {
  itemSheet.value[idx].open();
};
const wopen = () => {
  womanSheet.value.open();
};
const copen = () => {
  childSheet.value.open();
};
const iclose = (idx, cnt, uuid) => {
  itemSheet.value[idx].close();
  store.form.peopleRecords[idx].item_uuid = uuid;
  store.form.peopleRecords[idx].board_count = cnt;
  store.setItem(idx, cnt, uuid);
};
const wclose = (cnt) => {
  womanSheet.value.close();
  store.form.women_count = cnt;
};
const cclose = (cnt) => {
  childSheet.value.close();
  store.form.child_count = cnt;
};
function moveCalendar() {
  msg.value = "";

  // let sums = [0, 0];
  // let arr: number[] = Object.values(store.form.people).flat();
  // for(let i in arr) {
  //   const idx = (Number(i) % 2 === 0) ? 0 : 1;
  //   sums[idx] += Number(arr[i]);
  // }

  // let [adult, child] = sums;

  // let msg = 
  //   // 合計が0チェック
  //   adult + child == 0 ? `人数を入力してください`
  //   // 合計が最大値超えチェック
  //   : adult + child > store.limit ? `${store.limit}人以下で予約してください`
  //   // 子供のみチェック
  //   : adult == 0 ? `子供だけでは予約できません`
  //   : null
  // console.log(sums)

  // 合計チェック
  // var sum = arr.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
  // console.log({ sum })
  // if (sum == 0) {
  //   msg.value = "人数を入力してください"
  //   return;
  // }

  // let null_flg = true;
  // let only_child_flgs = store.items.map(v => false);
  // let all_people = 0;
  // let child_people = 0;
  // for (let i = 0; i < store.items.length; i++) {
  //   const adult = store.form.people[store.items[i].item_uuid][0];
  //   if (adult !== null && Number(adult) > 0) {
  //     null_flg = false;
  //     all_people += Number(adult);
  //     // break; 
  //   }

  //   const child = store.form.people[store.items[i].item_uuid][1];
  //   if (child !== null && Number(child) > 0) {
  //     null_flg = false;
  //     all_people += Number(child);
  //     child_people += Number(child);
  //     // break;
  //   }

  //   only_child_flgs[i] = (adult == null || Number(adult) == 0) && (child !== null && Number(child) > 0);
  // }
  // if (null_flg) {
  //   msg.value = "人数を入力してください"
  // } else if (only_child_flgs.indexOf(true) != -1) {
  //   msg.value = "子供だけでは予約できません"
  // } else if (all_people > store.peopleCountLimit) {
  //   msg.value = store.peopleCountLimit + "人以下で予約してください"
  // } else if (child_people > 0 && store.form.separate_flg) {
  //   msg.value =  "子供がいる場合分乗できません"
  // }else {
  //   router.push({ name: 'business-reserve-calendar' });
  // }

  if (store.peopleCount == 0) {
    msg.value = "予約人数を入力してください";
  } else if (store.peopleCount > store.peopleCountLimit) {
    msg.value = "予約人数は" + store.peopleCountLimit + "人までです。";
  } else if (store.form.women_count + store.form.child_count > store.peopleCount) {
    msg.value = `女性や高校生以下の人数を${store.peopleCount}人以下で入力してください`;
  } else if (store.form.child_count >= store.peopleCount) {
    msg.value = `高校生以下だけの予約はできません`;
  } else {
    router.push({ name: 'business-reserve-calendar' });
  }
}

// const sum = computed(() => {
//   var sum = Object.values(store.form.people).flat().reduce((partialSum, a) => partialSum + a, 0);
//   return sum;
// });

const ensureNumberLimit = (val, limit) => { 
  if (val > limit || val < 0) {
    val = limit;
}
}


</script>

<template>
  <main class="flex flex-col">
    <TitleBar class="text-xl" :title="store.ship.ship_name + `人数入力`"/>
    <v-async-block :proc="store.getItemProc">
      <div v-if="store.items.length == 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
        データがありません
      </div>
      <template v-else>
        <div class="flex flex-col flex-1 gap-4 p-4 overflow-auto pb-14">
          <div class="text-red-600 font-bold">
            最大{{ store.peopleCountLimit }}人まで予約できます。<br>
            アプリで大人数の予約、予約の変更、キャンセルは行えませんので、その際はお電話をお願い致します。
            <div v-if="store.ship.ship_note" class="text-red-600 font-bold">
              <br>{{ store.ship.ship_note }}
            </div>
          </div>
          <div
            class="flex items-center justify-center gap-4">
            <div 
              :class="`grid-cols-4`"
              class="grid flex-1 gap-2">
              <div 
                v-for="(v, k) in store.items"
                class="flex-1">
                <div class="bg-highlight/30 border-highlight flex flex-col items-center justify-center gap-2 p-2 border rounded-md ">
                  {{ v.item_name }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center justify-center gap-4">
            <div 
              :class="`grid-cols-4`"
              class="grid flex-1 gap-2">
              <div 
                v-for="(v, k) in store.form.peopleRecords"
                class="flex-1">
                <a class="flex items-center justify-center p-4 border-b border-r" @click="iopen(k)">
                  <div class="flex-1 text-center whitespace-nowrap">
                    {{ v['board_count'] }}人
                  </div>
                </a>
                <vue-bottom-sheet ref="itemSheet">
                  <div
                    @click="iclose(k, i - 1, v['item_uuid'])"
                    v-for="i in store.peopleCountLimit + 1"
                    v-bind:key="v['item_uuid']"
                    class="flex justify-between p-4 border-t"
                  >
                    {{ i - 1 }}人
                    <Check v-if="v['board_count'] == (i - 1)"/>
                  </div>
                </vue-bottom-sheet>
              </div>
            </div>
          </div>
        <hr/>

        <div class="flex gap-4 font-bold">
          総人数：{{ store.getPeopleCount() }}人
        </div>
        <div v-if="(store.ship.boat_fare_woman != store.ship.boat_fare) || (store.ship.boat_fare_child != store.ship.boat_fare)" class="p-4 bg-gray-100">
          総人数のうち
          <div v-if="(store.ship.boat_fare_woman != store.ship.boat_fare) || (store.ship.boat_fare_child != store.ship.boat_fare)" class="flex gap-4 p-4 font-bold bg-gray-100 rounded-md">
            <div v-if="store.ship.boat_fare_woman != store.ship.boat_fare" class="flex flex-col flex-1 gap-1">
              女性
              <div class="flex items-center justify-center gap-2">
                <div 
                  class="grid flex-1 gap-2">
                  <div class="flex-1 bg-white">
                    <a class="flex items-center justify-center p-4 border-b border-r" @click="wopen()">
                      <div class="flex-1 text-center whitespace-nowrap">
                        {{ store.form.women_count }}人
                      </div>
                    </a>
                    <vue-bottom-sheet ref="womanSheet">
                      <div
                        @click="wclose(i - 1)"
                        v-for="i in store.peopleCountLimit + 1"
                        class="flex justify-between p-4 border-t"
                      >
                        {{ i - 1 }}人
                        <Check v-if="store.form.women_count == (i - 1)"/>
                      </div>
                    </vue-bottom-sheet>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="store.ship.boat_fare_child != store.ship.boat_fare" class="flex flex-col flex-1 gap-1">
              高校生以下
              <div class="flex items-center justify-center gap-2">
                <div 
                  class="grid flex-1 gap-2">
                  <div class="flex-1 bg-white">
                    <a class="flex items-center justify-center p-4 border-b border-r" @click="copen()">
                      <div class="flex-1 text-center whitespace-nowrap">
                        {{ store.form.child_count }}人
                      </div>
                    </a>
                    <vue-bottom-sheet ref="childSheet">
                      <div
                        @click="cclose(i - 1)"
                        v-for="i in store.peopleCountLimit + 1"
                        class="flex justify-between p-4 border-t"
                      >
                        {{ i - 1 }}人
                        <Check v-if="store.form.child_count == (i - 1)"/>
                      </div>
                    </vue-bottom-sheet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
          <div class="text-red-600">
            {{ msg }}
          </div>
          <button @click="moveCalendar()" class="btn">
            日程選択へ
          </button>
        </div>
      </template>
    </v-async-block>
  </main>
</template>

<style></style>