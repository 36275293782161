import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useAppStore } from "../app/app.store";

export const useWithdrawalReasonStore = defineStore("withdrawal-reason", () => {
  const reason = ref("");
  const displayReason = ref(true);
  const displayComp = ref(false);
  const appStore = useAppStore();

  async function doSubmit() {
    let params = {
      withdraw_reason: reason.value,
    };
    const mainProc = useLazyAsyncState(() => api.withdraw(params), {});
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }

    if (mainProcData) {
      appStore.logout();
      displayReason.value = false;
      displayComp.value = true;
    }
  }

  return {
    reason,
    doSubmit,
    displayReason,
    displayComp,
  };
});
