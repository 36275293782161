import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useBusinessHomeStore = defineStore("business-home", () => {

  const news = ref([]);
  const img = ref('');
  const canReserve = ref(false);
  // const canUserReserve = ref(false);
  const catchList = ref([]);

  async function getBusiness(uuid) {

    let reserveParams = {
      business_uuid: uuid,
    };
    const reserveProc = useLazyAsyncState(() => api.canReserve(reserveParams), {});
    const reserveProcData = await reserveProc.execute();
    //canReserve.value = reserveProcData === '1';
    canReserve.value = reserveProcData.user;

    let params = {
      business_uuid: uuid
    };
    const mainProc = useLazyAsyncState(() => api.businessHome(params), {});
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }

    news.value = mainProcData.news;
    img.value = mainProcData.img;
    catchList.value = mainProcData.catch;
    //canReserve.value = mainProcData.reserveFlg;
    // canUserReserve.value = mainProcData.reservePattern === 1;

  }

  return {
    getBusiness,
    news,
    img,
    catchList,
    canReserve,
    // canUserReserve,
  };
});
