import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useUserInfoStore = defineStore("profile-user-info", () => {
  const userInfo = ref({
    last_name: "",
    first_name: "",
    last_name_kana: "",
    first_name_kana: "",
    user_tel: "",
    user_birthday: "",
    user_mail: "",
    have_point: "",
  });
  const mainProc = useLazyAsyncState(() => api.profileHome(), {});

  async function Init() {
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }

    userInfo.value = mainProcData;
  }

  return {
    userInfo,
    Init,
  };
});
