<script setup lang="ts">
import { ArrowLeft } from "lucide-vue-next";
import { useRouter } from "vue-router";
const props = withDefaults(defineProps<{
  titleBarClass?: string,
  title?: string,
  back?: boolean,
}>(), {
  titleBarClass: '',
  title: '',
  back: true,
})

const router = useRouter();

function doNavBack() {
  router.back();
}
</script>
<template>
  <section class="relative h-14">
    <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-b-- from-white--"></div>
    <div class="absolute top-0 left-0 z-10 flex items-center w-full h-full gap-1" :class="titleBarClass">
      <!-- <router-link class="p-4" :to="{ path: router.options.history.state.back?.toString() ?? '/' }" replace>
          <ArrowLeft class="w-6 h-6" />
        </router-link> -->
      <button
        :class="{'visible': back, 'invisible' : !back }"
        type="button" class="p-4 z-10" @click="doNavBack">
        <ArrowLeft class="w-6 h-6" />
      </button>
      <div class="flex-1 p-4 pl-0 text-center -ml-10 pointer-events-none--">
        {{ title }}
      </div>
    </div>
</section>
</template>

<style></style>
