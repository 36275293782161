import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";

export const useRegisterEmailConfirmTokenStore = defineStore(
  "register-email-confirm",
  () => {
    let _token = '';
    const mainProc = useLazyAsyncState(() => api.registerEmailTokenConfirm(_token), '');
    const sentAddress = useSessionStorage('address', null);
    const router = useRouter();
    
    return {
      mainProc,
      async init(token: string) {
        _token = token;
        const email = await await mainProc.execute();

        if (mainProc.error.value) {
          return;
        }
        
        sentAddress.value = email;
        router.replace({ name: 'register-form' })
      },
    };
  }
);
