<script setup lang="ts">
import LoadingIcon from '@/components/loading-icon.vue';
import { AlertOctagon } from "lucide-vue-next";
import { onMounted, ref } from 'vue';
import TitleBar from '../../components/title-bar.vue';
import { useAppStore } from '../app/app.store';
import { useAuthLoginStore } from './auth-login.store';
const props = withDefaults(defineProps<{
  redirect?: string,
}>(), {
})
const showPassword = ref(false);
const appStore = useAppStore();
const store = useAuthLoginStore();
onMounted(() => {
  store.$reset();
})
</script>

<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="ログイン" />

    <hr class="mx-4" />

    <form class="p-8" @submit="e => {
      e.preventDefault();
      store.doSubmit(redirect);
    }" novalidate>
      <fieldset class="relative flex flex-col gap-4" :disabled="store.mainProc.isLoading">
        <div class="flex items-center gap-2 p-3 text-red-800 border border-red-500 rounded-lg bg-red-50" v-if="store.msg">
          <AlertOctagon class="w-4 h-4"/>
          {{ store.msg }}
        </div>
        <label class="flex flex-col flex-1 gap-1"> <span>電話番号</span>
          <input v-model="store.form.email" placeholder="電話番号を入力してください。" type="email" class="w-full form-input">
        </label>


        <label class="flex flex-col flex-1 gap-1">パスワード
          <input v-model="store.form.password" :type="showPassword ? 'text' : 'password'"
            placeholder="パスワードを入力してください。"
            class="w-full pr-[100px] form-input">

          <button @click="showPassword = !showPassword" type="button"
            class="absolute w-[80px] text-gray-400 bg-gray-200 rounded-full bottom-3 right-3">表示{{ showPassword ? 'しない' :
              'する' }}</button>
        </label>
      </fieldset>

      <button :disabled="!store.touched || !store.validate.pass || store.mainProc.isLoading" class="relative flex justify-center w-full gap-2 mt-5 btn">
        <LoadingIcon class="absolute w-4 h-4 left-4" v-if="store.mainProc.isLoading"/>
        ログインする
      </button>

      <router-link :to="{ name: 'reset-password' }" class="block mx-auto mt-4 bg-transparent border-0 block-- w-fit primary btn focus:ring-0 focus:bg-transparent hover:bg-transparent">
        パスワードを忘れた方はこちら
      </router-link>
      
      <div class="pt-5">
        <router-link v-if="!appStore.isAuthenticated" :to="{ name: 'register-home' }" class="text-[1.5em] block mx-auto mt-5 border-0 bg-blue-600 w-full btn p-5 focus:ring-0 focus:bg-transparent hover:bg-transparent">
          会員登録はこちら
        </router-link>
      </div>
    </form>
  </main>
</template>
