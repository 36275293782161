import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useProfileHomeStore = defineStore("profile-home", () => {
  const point = ref({
    have_point: '',
  });

  const mainProc = useLazyAsyncState(() => api.profileHome(), {});

  async function Init() {
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }

    point.value = mainProcData;
  }

  return {
    point,
    Init,
  };
});
