<script setup lang="ts">
import * as api from "@/api";
import { formatMoney, formatNumber } from '@/utils';
import { onMounted } from 'vue';
import TitleBar from '../../components/title-bar.vue';
import { useReserveHstStore } from './reserve-hst.store';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
const store = useReserveHstStore();
onMounted(() => {
  store.Init(page);
})
let page = 1;
async function loadMore() {
  return api.reserveHst(++page)
}
const load = async $state => {
  if(!(store.hst !== undefined && store.hst.length === 0)){
    console.log("loading...");
    try {
      const res = await loadMore()
      if (res === undefined || res.length < 1) {
        $state.complete();
      }
      else {
        store.hst = store.hst.concat(res)
        $state.loaded();
      }
    } catch (error) {
      $state.error();
    }
  }else{
    $state.complete();
  }
};
</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="予約確認" />
    <div class="flex flex-col flex-1 gap-4 p-4 overflow-auto">
      <div
        v-for="(v, k) in store.hst" :key="k"
        class="px-4 border rounded">
        <table class="w-full">
          <tr class="border-b">
            <th class="w-20 font-semibold text-left whitespace-nowrap">予約日</th>
            <td class="px-2 py-3 text-left">{{ v.operate_at }}&nbsp;</td>
          </tr>

          <tr class="border-b">
            <th class="w-20 font-semibold text-left whitespace-nowrap">船名</th>
            <td class="px-2 py-3 text-left">{{ v.ship_name }}&nbsp;</td>
          </tr>

          <tr class="border-b">
            <th class="w-20 font-semibold text-left whitespace-nowrap">番船</th>
            <td class="px-2 py-3 text-left">{{ v.service_no }}&nbsp;</td>
          </tr>
          

          <tr class="border-b">
            <th class="w-20 font-semibold text-left whitespace-nowrap">船便</th>
            <td class="px-2 py-3 text-left">{{ v.ship_service_name }}&nbsp;</td>
          </tr>

          <tr class="border-b">
            <th class="w-20 font-semibold text-left whitespace-nowrap">出航予定</th>
            <td class="px-2 py-3 text-left">
            {{ {
              1: '未確定',
              2: '出航確定',
              3: '欠航確定',
            }[v.departure_div] }}
            &nbsp;
            </td>
          </tr>
  
          <tr class="border-b">
            <th class="font-semibold text-left whitespace-nowrap">乗船人数</th>
            <td class="px-2 py-3 text-left">{{ formatNumber(v.real_board_count) }}人</td>
          </tr>

          <tr class="border-b">
            <th class="font-semibold text-left whitespace-nowrap">船賃</th>
            <td class="px-2 py-3 text-left">{{ formatMoney(v.real_boat_fare) }}&nbsp;</td>
          </tr>

          <tr class="">
            <th class="py-3 font-semibold text-left align-top whitespace-nowrap">コメント</th>
            <td class="px-2 py-3 text-left whitespace-pre-wrap">{{ v.user_comment }}&nbsp;</td>
          </tr>
        </table>
      </div>
      <InfiniteLoading @infinite="load">
        <template #complete>
          <span> </span>
        </template>
      </InfiniteLoading>
      <div v-if="store.hst.length === 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
        予約がありません
      </div>
    </div>
  </main>
</template>
