import { useAppStore } from "@/modules/app/app.store";
import { httpPost } from "./http";
import {
  BusinessInfo,
  BusinessSearchParams,
  BusinessUuid,
  CreditParams,
  Inquiry,
  LoginParams,
  Notification,
  RefreshTokenParams,
  RegisterEmailConfirmParams,
  RegisterEmailParams,
  RegisterFcmTokenParams,
  RegisterParams,
  ReserveParams,
  ResetPasswordEmailParams,
  ResetPasswordParams,
  SearchFilters,
  SearchShipName,
  SetPasswordEmailParams,
  SpotUuid,
  UserInfo,
  Withdraw,
  catchUuid,
  shipUuid
} from "./types";

/**
 * ログイン
 * @param param0
 * @returns
 */
export async function login(params: LoginParams) {
  // const url = `https://jsonplaceholder.typicode.com/posts`;
  const url = `login.php`;
  return httpPost(url, params);
}

/**
 * Refresh Token
 * @param param0
 * @returns
 */
export async function refreshToken(params: RefreshTokenParams) {
  const url = `refresh_token.php`;
  return httpPost(url, params);
}

/**
 * パスワード再設定
 * @param param0
 * @returns
 */
export async function resetPassword(params: ResetPasswordParams) {
  const url = `auth_reset_password.php`;
  return httpPost(url, params);
}

/**
 * パスワード再設定案内送信
 * @param param0
 * @returns
 */
export async function resetPasswordEmail(params: ResetPasswordEmailParams) {
  const url = `auth_reset_password.php`;
  return httpPost(url, params);
}

/**
 * パスワード再設定
 * @param param0
 * @returns
 */
export async function setPasswordEmail(params: SetPasswordEmailParams) {
  const url = `set_password.php`;
  return httpPost(url, params);
}

/**
 * メール登録
 * @param param0
 * @returns
 */
export async function registerEmail(params: RegisterEmailParams) {
  const url = `register_email.php`;
  return httpPost(url, params);
}

/**
 * メール登録
 * @param param0
 * @returns
 */
export async function registerEmailConfirm(params: RegisterEmailConfirmParams) {
  const url = `register_email_confirm.php`;
  return httpPost(url, params);
}

/**
 * メール登録
 * @param param0
 * @returns
 */
export async function registerEmailTokenConfirm(token: string) {
  const url = `register_token_confirm.php`;
  return httpPost(url, { token });
}

/**
 * 会員登録
 * @param param0
 * @returns
 */
export async function regsiterAccount(params: RegisterParams) {
  const mobile_flg = useAppStore().isMobileVersion;
  const url = `register.php`;
  return httpPost(url, {
    ...params,
    mobile_flg,
  });
}

/**
 * マイページ
 * @param param0
 * @returns
 */
export function profileHome() {
  const url = `profile_home.php`;
  return httpPost(url);
}

/**
 * 会員登録
 * @param param0
 * @returns
 */
export async function updateAccount(params: UserInfo) {
  const url = `profile_edit.php`;
  return httpPost(url, {
    ...params,
  });
}

/**
 * クレジットカード情報一覧
 * @param param0
 * @returns
 */
export function creditInfo() {
  const url = `credit_info.php`;
  return httpPost(url);
}

/**
 * クレジットカード情報詳細
 * @param param0
 * @returns
 */
export function creditInfoDetail(user_pay_uuid) {
  let params = {
    user_pay_uuid: user_pay_uuid,
  };
  const url = `credit_info_detail.php`;
  return httpPost(url, params);
}

/**
 * クレジットカード情報追加
 * @param param0
 * @returns
 */
export function creditInsert(params: CreditParams) {
  const url = `credit_insert.php`;
  return httpPost(url, params);
}

/**
 * クレジットカード情報削除
 * @param param0
 * @returns
 */
export function creditDelete(userPayId, member_id, card_seq) {
  let params = {
    user_pay_uuid: userPayId,
    member_id: member_id,
    card_seq: card_seq,
  };
  const url = `credit_delete.php`;
  return httpPost(url, params);
}

/**
 * クレジットカード情報更新
 * @param param0
 * @returns
 */
export function creditUpdate(userPayId, memberID, cardSeq, mm, yy, defaultFlg) {
  let params = {
    user_pay_uuid: userPayId,
    member_id: memberID,
    card_seq: cardSeq,
    mm: mm,
    yy: yy,
    defaultFlg: defaultFlg,
  };
  const url = `credit_update.php`;
  return httpPost(url, params);
}

/**
 * 退会
 * @param param0
 * @returns
 */
export function withdraw(params: Withdraw) {
  const url = `withdraw.php`;
  return httpPost(url, params);
}

/**
 * 退会
 * @param param0
 * @returns
 */
export function inquiry(params: Inquiry) {
  const url = `inquiry.php`;
  return httpPost(url, params);
}

/**
 * 予約履歴
 * @param param0
 * @returns
 */
export function reserveHst(p) {
  const url = `reserve_hst.php`;
  return httpPost(url, {p: p});
}

/**
 * マイショップ
 * @param param0
 * @returns
 */
export function myshop() {
  const url = `myshop.php`;
  return httpPost(url);
}

/**
 * 船からお知らせ
 * @param param0
 * @returns
 */
export async function notification(p): Promise<Notification[]> {
  const url = `notification.php`;
  return httpPost(url, {p: p});
}

/**
 * デバイス登録
 * @param param0
 * @returns
 */
export function registerFcmToken(params: RegisterFcmTokenParams) {
  console.log('registerFcmToken', params)
  const url = `fcm_token.php`;
  return httpPost(url, params);
}

/**
 * ポイント履歴
 * @param param0
 * @returns
 */
export function profilePointHst(p) {
  const url = `profile_point_hst.php`;
  return httpPost(url, {p: p});
}

/**
 * アプリマスタ取得
 * @param param0
 * @returns
 */
export function initialize() {
  const url = `init.php`;
  return httpPost(url);
}
/**
 * 検索（条件）
 * @param param0
 * @returns
 */
export function searchFilters(params: SearchFilters): Promise<BusinessInfo[]> {
  const url = `search_filters.php`;
  return httpPost(url, params);
}
/**
 * 検索（船名）
 * @param param0
 * @returns
 */
export function searchShipName(params: SearchShipName) {
  const url = `search_ship_name.php`;
  return httpPost(url, params);
}
/**
 * 検索(map)
 * @param param0
 * @returns
 */
export async function searchBusiness(
  params: BusinessSearchParams
): Promise<BusinessInfo[]> {
  // return Promise.resolve([{
  //   business_uuid: "b96cb098-7cd6-11ec-8892-00163e8b7f58",
  //   business_id: "matsukaze",
  //   business_id_omit: "ms",
  //   business_name: "matukaze",
  //   business_tel: "08777889966",
  //   business_mail: "yosihno",
  //   business_postal_code: "8000222",
  //   business_prefecture_div: "46",
  //   business_city: "\u798f\u5ca1\u5e02",
  //   business_area: "\u798f\u5ca1",
  //   business_address: "123",
  //   business_building: null,
  //   business_latitude: "33.595443408965124",
  //   business_longitude: "130.40572705558873",
  //   business_explain: "\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059\u4e8b\u696d\u8005\u306e\u8aac\u660e\u6b04\u3067\u3059",
  //   business_reserve_limit: "5",
  //   business_last_reserve_day: "2",
  //   business_password: "test",
  //   back_margin: "0",
  //   bank_name: "\u798f\u5ca1\u9280\u884c",
  //   branch_name: "\u3042\u3042\u3042",
  //   account_div: "1",
  //   account_no: "2",
  //   account_name: "",
  //   remarks: null,
  //   insert_at: "0000-00-00 00:00:00",
  //   insert_uuid: "",
  //   update_at: null,
  //   update_uuid: null,
  //   delete_flg: "0",
  //   img: "https:\/\/app.seacleaning-f.com\/shipReceive\/img\/now_printing.png",
  //   photos: [
  //     "https://www.adorama.com/alc/wp-content/uploads/2018/11/landscape-photography-tips-yosemite-valley-feature.jpg",
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_mKipquNNKcGYMaGu-Sw7ItI6jMuKfjx2bXoLf3NF29HHTwyDtVDvAHkoiZSdqgBa-90&usqp=CAU",
  //     "http://t2.gstatic.com/licensed-image?q=tbn:ANd9GcTfmAkPLqgYetZYOs09_EUgxO-nQqWIYGM2_cHjI3xovgAsvD9PawHjxvIvLxWwG9eq8AXhTtb-XKQJ8AVzo-U",
  //     "http://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTPUiIbEFstepJEbzdzMeuS8lxZ7VrL0PFCbAgVaY_2UJkiYg_e3PoZsbH2EglE3cPHtgRHut8RE_MkpOYZOqs",
  //     "http://t1.gstatic.com/licensed-image?q=tbn:ANd9GcS50kGfSe3u7ckrQbkuEjT5S4_9Uf-WHq2lYGWIQOpXdakfMYDhY5fFXipaiciwXL-DlxX0vmjP1PO0VMVzIXw",
  //   ],
  // }])
  const url = `search_maps_init.php`;
  return httpPost(url, params);
}

/**
 * マイショップ取得
 * @param param0
 * @returns
 */
export async function getMyShops() {
  const url = `myshop.php`;
  return httpPost(url);
}

/**
 * 事業者トップ
 * @param param0
 * @returns
 */
export function businessHome(params: BusinessUuid) {
  const url = `business_home.php`;
  return httpPost(url, params);
}

/**
 * 事業者新着詳細
 * @param param0
 * @returns
 */
export function businessNews(news_uuid: string) {
  const url = `business_news.php`;
  return httpPost(url, { news_uuid });
}

/**
 * 事業者新着一覧
 * @param param0
 * @returns
 */
export function businessNewsList(BusinessUuid, p) {
  const url = `business_news_list.php`;
  return httpPost(url, { business_uuid : BusinessUuid, p : p });
}

/**
 * 事業者予約
 * @param param0
 * @returns
 */
export function businessReserve(params: shipUuid) {
  const url = `business_reserve.php`;
  return httpPost(url, params);
}

/**
 * 事業者予約人数
 * @param param0
 * @returns
 */
export function businessReserveItem(params: shipUuid) {
  const url = `business_reserve_item.php`;
  return httpPost(url, params);
}

/**
 *
 * @param param0
 * @returns
 */
export function businessReserveDateList(ship_uuid: string) {
  const url = `business_reserve_date_available_list.php`;
  return httpPost(url, { ship_uuid });
}

/**
 *
 * @param param0
 * @returns
 */
export function businessReserveHoliday(year: string, month: string, uuid: string) {
  const url = `business_reserve_holiday.php`;
  return httpPost(url, { year: year, month: month, ship_uuid: uuid });
}

/**
 * 事業者予約便
 * @param param0
 * @returns
 */
export function businessReserveService(params: BusinessUuid) {
  const url = `business_reserve_service.php`;
  return httpPost(url, params);
}

/**
 * 事業者予約連絡ポイント
 * @param param0
 * @returns
 */
export function businessReservePoint(uuid, people_count, women_count, child_count, detail_flg) {
  const url = `business_reserve_point.php`;
  return httpPost(url, { uuid, people_count, women_count, child_count, detail_flg });
}

/**
 * 事業者予約確認
 * @param param0
 * @returns
 */
export async function businessReserveConf(business_uuid, uuid, separate, people, date, detail_flg): Promise<number> {
  let params = {
    business_uuid,
    uuid,
    separate: separate,
    people: people,
    date: date,
    detail_flg
  };
  const url = `business_reserve_conf.php`;
  return httpPost(url, params);
}

/**
 * 事業者予約完了
 * @param param0
 * @returns
 */
export function businessReserveComp(params: ReserveParams) {
  const url = `business_reserve_comp.php`;
  return httpPost(url, params);
}

export async function businessRegisterShop(business_uuid: string) {
  const url = `business_reserve_reg_shop.php`;
  return httpPost(url, { business_uuid });
}

/**
 * 事業者釣果一覧
 * @param param0
 * @returns
 */
export function businessCatchList(BusinessUuid, p) {
  const url = `business_catch_list.php`;
  return httpPost(url, { business_uuid : BusinessUuid, p : p });
}

/**
 * 事業者釣果詳細
 * @param param0
 * @returns
 */
export function businessCatchDetail(params: catchUuid) {
  const url = `business_catch_detail.php`;
  return httpPost(url, params);
}

// /**
//  * 事業者エリア情報
//  * @param param0
//  * @returns
//  */
//  export function businessArea(params: BusinessUuid) {
//   const url = `business_area.php`;
//   return httpPost(url, params);
// }

/**
 * 事業者磯情報
 * @param param0
 * @returns
 */
export function businessInfo(params: BusinessUuid) {
  const url = `business_info.php`;
  return httpPost(url, params);
}

/**
 * 事業者磯情報
 * @param param0
 * @returns
 */
export function businessInfoList(ground_uuid: String) {
  const url = `business_info_list.php`;
  return httpPost(url, {
    ground_uuid
  });
}

/**
 * 事業者磯情報詳細
 * @param param0
 * @returns
 */
export function businessInfoDetail(params: SpotUuid) {
  const url = `business_info_detail.php`;
  return httpPost(url, params);
}

/**
 * 事業者釣船情報
 * @param param0
 * @returns
 */
export async function businessShop(params: BusinessUuid) {
  const url = `business_shop.php`;
  return httpPost(url, params);
}

/**
 * 予約可能フラグ
 * @param param0
 * @returns
 */
export function canReserve(params: BusinessUuid) {
  const url = `can_reserve.php`;
  return httpPost(url, params);
}

/**
 * 船選択
 * @param param0
 * @returns
 */
export function businessShip(business_uuid: string) {
  const url = `business_ship.php`;
  return httpPost(url, { business_uuid });
}

/**
 * 項目取得
 * @param param0
 * @returns
 */
export function businessItem(ship_uuid,business_uuid): Promise<{
  businessReserveLimit: string,
  item: [],
}> {
  const url = `business_item.php`;
  let params = {
    ship_uuid: ship_uuid,
    business_uuid: business_uuid,
  };
  return httpPost(url, params);
}

/**
 * 項目取得
 * @param param0
 * @returns
 */
export function businessServices(ship_uuid, people, separate_flg, date) {
  const url = `business_reserve_date.php`;

  return httpPost(url, {
    ship_uuid,
    people,
    separate_flg,
    date,
  });
}

/**
 * 通知更新
 * @param param0
 * @returns
 */
export async function notificationRead(uuid): Promise<boolean> {
  const url = `notification_read.php`;
  return httpPost(url, { uuid });
}

/**
 * 通知更新
 * @param param0
 * @returns
 */
export async function isUserIdExist(user_tel): Promise<boolean> {
  const url = `register_user_id_check.php`;
  return httpPost(url, { user_tel });
}

/**
 * 
 * @param param0
 * @returns
 */
export async function isAppleAutoSubscription(): Promise<{
  ios: boolean,
  android: boolean
}> {
  const url = `purchase_status.php`;
  return httpPost(url);
}