<script setup lang="ts">
import { CheckCircle } from "lucide-vue-next";
import { vMaska } from "maska";
import { onMounted } from 'vue';
import TitleBar from '../../components/title-bar.vue';
import { useCreditInfoStore } from './credit-info.store';
const props = withDefaults(defineProps<{
  userPayId: string,
}>(), {})
const store = useCreditInfoStore();
store.updateRes = false;
onMounted(() => {
  store.Init();
  store.UpdateInit(props.userPayId);
})
store.updateRes = false;
</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="クレジットカード情報更新" />
    <hr class="mx-4" />
    <div class="p-4" v-if="!store.updateRes">
      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          カード番号
          <div class="form-control">
            **** **** **** {{ store.creditInfo[props.userPayId].card_number_4 }}
          </div>
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          有効期限
          <div class="flex items-center gap-4 w-[50%]">
            <input v-model="store.form.credit.mm" v-maska data-maska="##" type="text" placeholder="MM"
              class="w-full form-input" />
            /
            <input v-model="store.form.credit.yy" v-maska data-maska="##" type="text" placeholder="YY"
              class="w-full form-input" />
          </div>
        </label>
      </div>

      <div v-if="store.creditInfo.length >= 1" class="flex items-center mt-4 mb-4">
        <input v-model="store.form.credit.defaultFlg" id="default-checkbox" type="checkbox" value=""
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">常にこのカードで決済</label>
      </div>

      <button @click="store.Update()" class="w-full mt-4 btn">この内容で登録する</button>

    </div>
    <div class="flex items-center gap-2 p-3 mx-4 mt-4 text-green-800 border border-green-500 rounded-lg bg-green-50" v-else>
      <CheckCircle class="w-4 h-4"/>
      更新完了しました
    </div>

  </main>
</template>
