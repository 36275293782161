import { useAsyncState, UseAsyncStateOptions } from "@vueuse/core";
import { format, isValid } from "date-fns";
import Cookies from "js-cookie";

const nf = new Intl.NumberFormat();

export const formatNumber = (val) => nf.format(val);
export const formatMoney = (val, sign = '¥') => sign + formatNumber(val);

export const parseDate = (dateStr) => new Date(dateStr.replace(' ', 'T'));
export const formatDate = (val: Date | string, jpFlg = true) => {
  const targetDate = typeof val === 'string' ? parseDate(val) : val;
  return format(targetDate, jpFlg ? 'yyyy年MM月dd日' : 'yyyy/MM/dd');
}
export const formatDateTime = (val: Date | string, jpFlg = true) => {
  const targetDate = typeof val === 'string' ? parseDate(val) : val;
  return format(targetDate, jpFlg ? 'yyyy年MM月dd日 HH時mm分ss秒' : 'yyyy/MM/dd HH:mm:ss');
}

export const DefaultImgUrl =
  "https://placehold.jp/014c7a/ffffff/500x500.png?text=画像なし";

// export const isLoggedIn = () => Cookies.get('HASYSTEMVOOST');
export const isOnboardingDone = () => localStorage.getItem('onboardingDoneFlg') === '1';
export const markOnboardingDone = () => localStorage.setItem('onboardingDoneFlg', '1');
export const isLoggedIn = () => Cookies.get("HASYSTEMVOOST");

let gmoAPIKey = "9200005753852";
export const gmoInit = async(test = true) => {
  if (test) {
    gmoAPIKey = "tshop00035695";
  }
  const scriptId = "payScript";
  if (!document.getElementById(scriptId)) {
    const scriptElement = document.createElement('script');
    scriptElement.id = scriptId;
    scriptElement.src = test ? 'https://stg.static.mul-pay.jp/ext/js/token.js' : 'https://static.mul-pay.jp/ext/js/token.js';
    document.body.appendChild(scriptElement);
  }
}

export const gmoErr = `
  カード情報をご確認ください
  該当のカードをご利用いただけない場合には、カード会社にお問い合わせいただくか、別のクレジットカードをご利用ください。
  一定回数エラーになると、カードのご利用が制限される場合がありますのでご注意ください。
  
  エラーコード：{ERROR_CODE}
`.split('\n').map(v => v.trim()).join('\n').trim();

export const gmoGetToken = async (cardno, expire): Promise<any> => {
  const brand = "";
  return new Promise((resolve, reject) => {
    window['Multipayment'].init(gmoAPIKey);
    window['Multipayment'].getToken(
      {
        cardno: cardno,
        expire: expire,
      },
      (response) => {

        if (response.resultCode !== "000") {
          return reject(
            new Error(
              // "【" + response.resultCode + "】処理中にエラーが発生しました"
              gmoErr.replaceAll('{ERROR_CODE}', response.resultCode)
            )
          );
        } else {
          const brand = gmoGetCardBrand(cardno);
          if (!brand) {
            return reject(new Error(
              gmoErr.replaceAll('{ERROR_CODE}', '99')
              // "カード情報が正しくありません"
            ));
          }
        }

        return resolve({
          token: response.tokenObject.token,
          token_at: response.tokenObject.toBeExpiredAt.replace(/-/g, ""),
          brand: brand,
        });
      }
    );
  });
};

export const gmoGetCardBrand = (cardno) => {
  let rtn = "";
  if (cardno.length > 13) {
    let top = cardno.substr(0, 1);
    switch (top) {
      case "2":
        rtn = "2";
        break;
      case "3":
        if (cardno.length === 16) {
          rtn = "3";
        } else if (cardno.length === 15) {
          rtn = "4";
        } else if (cardno.length === 14) {
          rtn = "5";
        }
        break;
      case "4":
        rtn = "1";
        break;
      case "5":
        rtn = "2";
        break;
    }
  }
  return rtn;
};


// 1：VISA、2：MASTER、3：JCB、4：AMEX、5：DINERS	
export const gmoGetCardBrandName = (brandDiv) => {
  return {
    "1": "VISA",
    "2": "MASTER",
    "3": "JCB",
    "4": "AMEX",
    "5": "DINERS",
  }[brandDiv] || "不明";
};


export function useLazyAsyncState
  <Data, Params extends any[] = [], Shallow extends boolean = true>
  (promise: Promise<Data> | ((...args: Params) => Promise<Data>), initialState: Data, options?: UseAsyncStateOptions<Shallow, Data>) {
    return useAsyncState(
      promise,
      initialState,
      {
        ...(options??{}),
        immediate: false,
      }
    );
}

export function hex2rgba (hex, alpha = 1) {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export function isValidBirthYear(year) {
  return year >= (new Date).getFullYear() - 200 && year <= (new Date).getFullYear();
}

export function isValidDate(year, month, date) {
  return isValid(new Date(year, month - 1, date))
}

export function isValidOptionalBirthDate(year, month, date) {
  return (!year && !month && !date)
    || (isValidBirthYear(year) && isValidDate(year, month, date))
}