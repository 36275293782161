import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";

export const useMyshopStore = defineStore("my-shop", () => {
  const mainProc = useLazyAsyncState(() => api.myshop(), []);

  async function init() {
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }
  }
  return {
    mainProc,
    init,
  };
});
