export const PURCHASE_STATE_SUCCESS = 0;
export const PURCHASE_STATE_CANCELED = 1;
export const PURCHASE_STATE_REFUNDED = 2;
export const PURCHASE_STATE_SUBS_EXPIRED = 3;
export interface PurchaseData {
  orderId: string,
  packageName: string,
  purchaseTime: Date,
  purchaseState: number,
  purchaseToken: string,
}

export interface PurchaseInfo {
  responseData: string,
  signature: string,
  purchaseData: PurchaseData,
}

export interface PurchasedResult {
  productId?: string,
  // details?: PurchaseInfo,
  responseData: string,
  errorCode?: number,
  error?: any,
}

export interface IabPlugin {
  register(options: {
    licenseKey: string,
    timeoutMillis?: number,
  }): Promise<{ value: boolean }>;
  subscribe(options: { productId: string }): Promise<PurchasedResult>;
  release(): Promise<void>;
}
