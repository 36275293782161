import { isOnboardingDone, markOnboardingDone } from '@/utils';
import { defineStore } from "pinia";
import { ref } from "vue";

export const useOnboardingStore = defineStore("onboarding", () => {
  const currentPageIndex = ref(0);
    const pages = ref([
    {
      img: "https://img.freepik.com/free-vector/summer-landscape-background-zoom_52683-42067.jpg?w=1380&t=st=1676442960~exp=1676443560~hmac=a6c829c6a9d4ff4c27544a58fe20d402044c254ae8e2d126f55b69c337c1b41f",
      title: "111",
      body: "1111111111",
    },
    {
      img: "https://img.freepik.com/free-vector/summer-landscape-background-zoom_52683-42067.jpg?w=1380&t=st=1676442960~exp=1676443560~hmac=a6c829c6a9d4ff4c27544a58fe20d402044c254ae8e2d126f55b69c337c1b41f",
      title: "222",
      body: "2222222222",
    },
  ]);
  return {
    pages,
    currentPageIndex,
    isDone() {
      return isOnboardingDone();
    },
    markAsDone() {
      return markOnboardingDone();
    }
  };
});
