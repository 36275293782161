import * as api from "@/api";
import { useAppStore } from '@/modules/app/app.store';
import { useOnboardingStore } from '@/modules/onboarding/onboarding.store';
import { useSessionStorage } from '@vueuse/core';
import { NavigationGuardNext, RouteLocationNormalized, createRouter, createWebHashHistory } from "vue-router";
import routes from './routes';
import { AppRouteMeta } from './types';

const isMobileVersion = window['env'].APP_FLG;
const firstTimeRedirectMyshopFlg = useSessionStorage('firstTimeRedirectMyshopFlg', false);

const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
});

function isBackRoute(route: RouteLocationNormalized) {
  return window.event?.type === 'popstate';
}

// Ref: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isBack = isBackRoute(to);
  to.meta = {
    ...to.meta,
    isBack
  };

  if (isMobileVersion) {
    if (handleOnboarding(to, from, next)) {
      router
        .getRoutes()
        .find((v) => v.name == "home").redirect = {
        name: 'search'
      }
      return;
    }

    if (to.name === 'onboarding' && isBack) {
      return next({ name: 'home' });
    }
  }

  if (handleProtectedRoutes(to, from, next)) {
    return;
  }

  const appStore = useAppStore();
  const isAuthenticated = appStore.isAuthenticated;

  let hasMyShop = false;
  if (to.name == 'home') {
    if (isMobileVersion) {
      if (!firstTimeRedirectMyshopFlg.value && isAuthenticated) {
        firstTimeRedirectMyshopFlg.value = true;
        try {
          const myshop = await api.myshop();
          const hasMyShop = myshop && myshop.length;
          if (hasMyShop) {
            return next({ name: 'my-shop' });
          } else {
            return next({ name: 'search' });
          }
        } catch (error) {
        }
      } else {
        return next({
          name: isMobileVersion ? 'search' : 'profile-home'
        })
      }
    } else {
      return next({
        name: 'profile-home'
      })
    }
  }

  const loginRouteName = 'login';
  if (to.name === loginRouteName) {
    if (isAuthenticated) {
      try {
        const myshop = await api.myshop();
        const hasMyShop = myshop && myshop.length;
        if (hasMyShop) {
          return next({ name: 'my-shop' });
        }
      } catch (error) {

      }
      return next({ name: 'home' });
    }
  }

  // OK
  next();
});

/**
 * 
 * @param to 
 * @param from 
 * @returns 
 */
function handleOnboarding(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const store = useOnboardingStore();
  const onboardingRouteName = 'onboarding';
  if (!store.isDone() && to.name !== onboardingRouteName) {
    next({ name: onboardingRouteName });
    return true;
  }

  return false;
}

/**
 * 
 * @param to 
 * @param from 
 * @returns 
 */
function handleProtectedRoutes(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const toMeta = to.meta;
  const requireAuth = (toMeta ?? false) && (toMeta as AppRouteMeta).protected;
  if (requireAuth) {
    const appStore = useAppStore();
    const isAuthenticated = appStore.isAuthenticated;
    const loginRouteName = 'login';
    if (
      // make sure the user is authenticated
      !isAuthenticated &&
      // ❗️ Avoid an infinite redirect
      to.name !== loginRouteName
    ) {
      // redirect the user to the login page
      next({ name: loginRouteName, query: { redirect: to.name.toString() } })
      return true;
    }
  }

  return false;
}

export default router;
