<script setup lang="ts">
import * as api from "@/api";
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import TitleBar from '../../components/title-bar.vue';
import { useBusinessNewsListStore } from './business-news-list.store';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
const props = withDefaults(defineProps<{
  businessId: string,
}>(), {
})
const router = useRouter();

const store = useBusinessNewsListStore();
store.init(props.businessId, 1);

function moveNews(uuid) {
  router.push({ name: 'business-news-detail', params: { newsId: uuid } })
}
let page = 1;
async function loadMore() {
  return api.businessNewsList(props.businessId, ++page)
}
const load = async $state => {
  if(!(store.mainProc.state !== undefined && store.mainProc.state.length === 0)){
    console.log("loading...");
    try {
      const res = await loadMore()
      if (res === undefined || res.length < 1) {
        $state.complete();
      }
      else {
        store.mainProc.state = store.mainProc.state.concat(res)
        $state.loaded();
      }
    } catch (error) {
      $state.error();
    }
  }else{
    $state.complete();
  }
};
</script>

<template>
  <main class="flex flex-col flex-1 no-select-drag">
    <TitleBar title="お知らせ一覧" />
    <div class="flex-1 gap-1 overflow-auto">
      <v-async-block :proc="store.mainProc">
        <div @click="moveNews(v.news_uuid)"  v-for="(v, k) in store.mainProc.state" :key="k" class="p-4 m-4 bg-gray-100">
          <div class="p-4 bg-white rounded-md">
            <div class="flex justify-between text-sm text-gray-400">
              {{ v.date?.replaceAll(/-/g, '.') }}
              <span class="text-white/80">
                <span class="px-2 py-1 text-xs rounded" :style="{
                  background: `#${v.news_background_color}`,
                  color: `#${v.news_character_color}`,
                }">{{ v.category_news_name }}</span>
              </span>
            </div>
            <div class="mt-2 font-bold">
              {{ v.category_news_title }}
            </div>
          </div>
        </div>
        <InfiniteLoading @infinite="load">
          <template #complete>
            <span> </span>
          </template>
        </InfiniteLoading>
        <div v-if="store.mainProc.state.length === 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
          お知らせがありません
        </div>
      </v-async-block>
    </div>
  </main>
</template>

<style></style>