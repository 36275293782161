import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";

export const useBusinessInfoListStore = defineStore("business-info-list", () => {

  let groundId = null;

  const mainProc = useLazyAsyncState(() => api.businessInfoList(groundId), {},);

  return {
    mainProc,
    async init(uuid) {
      this.$reset();
      groundId = uuid;
      await mainProc.execute();
    }
  };
});
