<script setup lang="ts">
import TitleBar from '@/components/title-bar.vue';
import { computed, ref } from 'vue';
import { useBusinessCatchDetailStore } from './business-catch-detail.store';


const props = withDefaults(defineProps<{
  catchId: string,
}>(), {})

const store = useBusinessCatchDetailStore();
store.init(props.catchId);


const imgList = computed(() => store.mainProc.state.img || [])
const mainPhotoIdx = ref(0);
</script>

<template>
  <main class="flex flex-col flex-1 no-select-drag">
    <TitleBar :title="store.mainProc.state?.ship?.ship_name" />
    <v-async-block :proc="store.mainProc">
      <div class="flex-1 overflow-auto">
        <div class="p-4 pt-0">
          <p v-if="store.mainProc.state.catch.catch_title" class="font-bold text-justify text-primary">
            {{ store.mainProc.state.catch.catch_title }}
          </p>
          <p v-if="store.mainProc.state.catch.catch_body" class="mt-2 text-justify whitespace-pre-wrap">
            {{ store.mainProc.state.catch.catch_body }}
          </p>
        </div>

        
        <!-- <div class="p-4 pt-0">
          <v-img :src="imgList[mainPhotoIdx]" class="object-cover aspect-[4/3] w-full" />
          <div class="relative grid grid-cols-5 gap-2 mt-2">
            <div class="w-full h-full"
                v-for="(v, k) in imgList" :key="k"
                @click="e => { mainPhotoIdx = k }">
              <v-img 
                :src="v"
                class="object-cover w-full h-full" />
            </div>
          </div>
        </div> -->

        <div class="flex flex-col gap-4 p-4 pt-0 ">
        <!-- <template v-for="x in 10"> -->
          <div class="w-full h-full aspect-[4/3]"
              v-for="(v, k) in imgList" :key="k"
              @click="e => { mainPhotoIdx = k }">
            <v-img
              v-if="(v ?? '').indexOf('now_printing.png') == -1"
              :src="v"
              class="object-cover w-full h-full" />
          </div>
        <!-- </template> -->
        </div>
      </div>
    </v-async-block>
  </main>
</template>

<style></style>