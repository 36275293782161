class BaseDto<T> {
  constructor(params?: Partial<T>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export class LoginParams extends BaseDto<LoginParams> {
  email: string;
  password: string;
}

export class RefreshTokenParams extends BaseDto<RefreshTokenParams> {
  refreshToken: string;
}

export class ResetPasswordEmailParams extends BaseDto<ResetPasswordEmailParams> {
  address: string;
}

export class ResetPasswordParams extends BaseDto<ResetPasswordParams> {
  user_id: string;
  last_name: string;
  first_name: string;
  user_tel: string;
  user_password: string;
  user_password_conf: string;
}

export class SetPasswordEmailParams extends BaseDto<SetPasswordEmailParams> {
  password: string;
  password_conf: string;
  token: string;
}

export class RegisterEmailParams extends BaseDto<RegisterEmailParams> {
  address: string;
  web_flg: boolean;
}

export class RegisterEmailConfirmParams extends BaseDto<RegisterEmailConfirmParams> {
  code: string;
}

export class CreditInfo extends BaseDto<CreditInfo> {
  number: string;
  mm: string;
  yy: string;
  cvc: string;
  token: string;
  token_at: string;
  card_div: string;
  defaultFlg: boolean;
}

export class UserInfo extends BaseDto<UserInfo> {
  user_id: string;
  user_mail: string;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  user_tel: string;
  user_password: string;
  user_password_conf: string;
  user_birthday_yy: string;
  user_birthday_mm: string;
  user_birthday_dd: string;
}


export class TokenInfo extends BaseDto<TokenInfo> {
  accessToken: string;
  refreshToken: string;
}

export class RegisterParams extends BaseDto<RegisterParams> {
  user_info = new UserInfo();
  credit = new CreditInfo();
  purchaseToken: string;
  platform: string;
}

export class CreditParams extends BaseDto<RegisterParams> {
  credit = new CreditInfo();
}
export class BusinessSearchParams extends BaseDto<BusinessSearchParams> {
  maps: boolean;
}
export class RegisterFcmTokenParams extends BaseDto<RegisterFcmTokenParams> {
  token: string;
}


export class Withdraw extends BaseDto<Withdraw> {
  withdraw_reason: string;
}

export class Inquiry extends BaseDto<Withdraw> {
  user_name: string;
  user_mail: string;
  user_tel: string;
  inquiry_title: string;
  inquiry_text: string;
}

export class SearchFilters extends BaseDto<SearchFilters> {
  prefecture_cd: string;
  fishing_uuid: string;
  target_uuid: string;
  ship_name: string;
  p: number = 1;
}
export class SearchShipName extends BaseDto<SearchFilters> {
  ship_name: string;
}


export class BusinessInfo extends BaseDto<BusinessInfo> {
  business_uuid: string;
  business_id: string;
  business_id_omit: string;
  business_name: string;
  business_tel: string;
  business_mail: string;
  business_postal_code: string;
  business_prefecture_div: string;
  business_city: string;
  business_area: string;
  business_address: string;
  business_building: string;
  business_latitude: string;
  business_longitude: string;
  business_explain: string;
  business_place: string;
  business_name_2: string;
  business_tel_2: string;
  business_latitude_2: string;
  business_longitude_2: string;
  img: string;
  photos: string[] = [];
}

// export class SearchMapResults extends BaseDto<BusinessInfo[]> {

// }

export class SearchFiltersResults extends BaseDto<SearchFiltersResults> {
  business_list: BusinessInfo[];
  start_num: number;
  last_num: number;
  total_cnt: number;
}

export class BusinessUuid extends BaseDto<BusinessUuid> {
  business_uuid: string;
}

export class NewsUuid extends BaseDto<NewsUuid> {
  news_uuid: string;
}
export class catchUuid extends BaseDto<catchUuid> {
  catch_uuid: string;
}

export class PeopleRecord extends BaseDto<PeopleRecord> {
  //name: string;
  board_count: number;
  item_uuid: string;
}

export class ReserveParams extends BaseDto<ReserveParams> {
  business_uuid: string;
  ship_uuid: string;
  ship_name: string;
  // seats_top_adult: number;
  // seats_top_child: number;
  // seats_bottom_adult: number;
  // seats_bottom_child: number;
  // seats_side_adult: number;
  // seats_side_child: number;
  date: Date;
  time: string;
  time_name: string;
  info: string;
  use_point_flg: Boolean;
  separate_flg: Boolean = false;
  fare: number;
  peopleRecords: PeopleRecord[] = [];
  women_count: number;
  child_count: number;
  serviceNumber: number = 0;
  detail_flg: number = 0;
}

export class shipUuid extends BaseDto<shipUuid> {
  ship_uuid: string;
}

export class GroundUuid extends BaseDto<GroundUuid> {
  ground_uuid: string;
}

export class SpotUuid extends BaseDto<SpotUuid> {
  spot_uuid: string;
}

export class Notification extends BaseDto<Notification> {
    user_push_history_uuid: string
    user_push_uuid: string
    business_uuid: string
    push_at: string
    push_title: string
    push_body: string
    reserve_uuid: string
    read_flg: string
    remarks: string
    insert_at: string
    insert_uuid: string
    update_at: string
    update_uuid: string
    delete_flg: string
}