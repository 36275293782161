import { createPinia } from 'pinia';
import { register as registerSwiperLibrary } from 'swiper/element/bundle';
import { createApp } from 'vue';
import resetStore from './reset-store';

import App from '@/modules/app/app.vue';
import router from './router';

import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

import VueLazyLoad from 'vue3-lazyload';

import './assets/main.scss';

import DefaultImgUrl from '@/assets/now_printing.png';
import AsyncBlock from '@/components/async-block.vue';
import img from '@/components/image.vue';

// import validator from './validator';

// Ref: https://swiperjs.com/element
registerSwiperLibrary();
// validator.validator.rules;

export const API_SERVER = 'http://localhost';

const app = createApp(App);

const pinia = createPinia();
pinia.use(resetStore);

app.use(pinia);
app.use(router);

app.use(VCalendar, {});

app.use(VueLazyLoad, {
  // error: "https://placehold.jp/014c7a/ffffff/500x500.png?text=エラー",
  error: DefaultImgUrl,
  // loading: LoadingSvg,
  log: false,
})

app.component('v-img', img);
app.component('v-async-block', AsyncBlock);

app.directive('skeletor', (el, binding) => {
  // console.log(el.classList) // => "white"
})

app.mount('#app');
