import * as api from "@/api";
import { BusinessInfo } from "@/api/types";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { computed } from "vue";

export const useBusinessShopStore = defineStore("business-shop", () => {

  let businessId = null;

  const mainProc = useLazyAsyncState(() => api.businessShop({ business_uuid: businessId, }), new BusinessInfo());


  return {
    mainProc,
    async init(id) {
      businessId = id;
      await mainProc.execute();
    },
    photos: computed(() => {
      return mainProc.state.value.photos || [];
    }),
  };
});
