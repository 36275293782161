<script setup lang="ts">
import BusinessPreview from '@/components/business-preview.vue';
import { LMap, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import { ChevronRight } from "lucide-vue-next";
import { computed, onMounted, ref } from 'vue';
import { useSearchMapStore } from './search-map.store';

const store = useSearchMapStore();
const zoom = ref(10);

const center = computed(() => {
  if (store.mainProc.isReady && store.mainProc.state.length) {
    return [
      store.mainProc.state[0].business_latitude,
      store.mainProc.state[0].business_longitude
    ];
  }

  return [31.30092, 130.22278];
})

onMounted(() => {
  store.doSubmit();
});

</script>
<template>
  <main class="relative w-full h-full">
    <v-async-block :proc="store.mainProc" class="absolute z-10 w-10 h-10 top-2 right-2"/>
    <l-map ref="map" v-model:zoom="zoom" :center="center" class="absolute z-0 " :useGlobalLeaflet="false">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      
      <l-marker
        v-for="(business, idx) in store.mainProc.state" :key="idx"
        :lat-lng="[business.business_latitude, business.business_longitude]"
        @click="store.activeBusinessInfo = business">
      </l-marker>
      <l-marker
        v-for="(business, idx) in store.mainProc.state" :key="idx"
        :lat-lng="[business.business_latitude_2, business.business_longitude_2]"
        @click="store.activeBusinessInfo = business">
      </l-marker>
    </l-map>
    <div v-if="store.activeBusinessInfo" @click.self="store.activeBusinessInfo = null"
      class="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen bg-black/50">
      <div class="bg-white w-[80vw]">
        <BusinessPreview :business-info="store.activeBusinessInfo"/>
        <div class="flex justify-center m-4">
          <router-link :to="{ name: 'business-home', params: { businessId: store.activeBusinessInfo.business_uuid } }"
            class="flex items-center gap-4 p-1 px-4 border border-solid rounded">
            詳しく見る
            <ChevronRight class="w-4 h-4"/>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>
<style></style>
