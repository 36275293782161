import * as api from "@/api";
import { Notification } from "@/api/types";
import { useLazyAsyncState } from "@/utils";
import _ from "lodash";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const useNotificationStore = defineStore("notification", () => {
  const activeNotification = ref<Notification>(null);
  let p = 1;
  const mainProc = useLazyAsyncState(
    () => api.notification(p),
    []
  );

  async function init(userPushHistoryUuid?: string, page?: number) {
    p = page;
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }

    if (userPushHistoryUuid) {
      activeNotification.value = mainProcData.find(v => v.user_push_history_uuid);
    }
  }

  watch(activeNotification, async (val) => {
    if (!val) {
      return;
    }
    await api.notificationRead(activeNotification.value.user_push_history_uuid);
    // init(null);
    // activeNotification.value.read_flg = "1";
    // mainProc.state.value.find(v => v.user_push_history_uuid).read_flg = "1";

    const list = mainProc.state.value;
    list.find(v => v.user_push_history_uuid == activeNotification.value.user_push_history_uuid).read_flg = "1";
    mainProc.state.value = _.cloneDeep(list);
  });

  return {
    activeNotification,
    mainProc,
    unreadCount: computed(() => {
      const count = mainProc.state.value.filter(v => v.read_flg == "0").length;
      if (count <= 99) {
        return count;
      }

      return '99+';
    }),
    init,
  };
});
