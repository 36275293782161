<script setup lang="ts">
import CommonHeaderView from '@/components/CommonHeaderView.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const body = ref<HTMLElement | null>(null)
// const { x, y, isScrolling, arrivedState, directions } = useScroll(body)

const route = useRoute();
const transitionName = computed(() => {
  const name = route.meta.isBack ? 'slide-fade-right'
    : route.meta.transition ? (route.meta.transition + '')
      : 'slide-fade-left';

  return name;
})

</script>

<template>
  <main class="flex flex-col w-full h-full">
    <CommonHeaderView class="sticky top-0 left-0 z-20 w-full bg-white shadow--"/>
    <div class="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
      <router-view v-slot="{ Component }">
        <transition :name="transitionName">
          <component :is="Component" class="absolute w-full h-full bg-white" />
        </transition>
      </router-view>
    </div>
    <!-- {{ arrivedState }} -->
  </main>

  
</template>

<style>

</style>
