<script setup lang="ts">
import { Info } from "lucide-vue-next";
import { useRouter } from "vue-router";
const props = withDefaults(defineProps<{
  message: string,
}>(), {
  message: '',
})

const router = useRouter();
</script>
<template>
  <div v-if="message" class="flex items-center gap-2 error-message" v-bind="$attrs">
    <Info class="w-4 h-4"/>
    {{ message }}
  </div>
</template>

<style>

</style>
