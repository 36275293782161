<script setup lang="ts">
import { AlertOctagon, CreditCard, HelpCircle, X } from "lucide-vue-next";
import TitleBar from "../../components/title-bar.vue";

import ErrorMessage from "@/components/error-message.vue";
import LoadingIcon from "@/components/loading-icon.vue";
import { ChevronDown, ChevronUp } from "lucide-vue-next";
import { vMaska } from "maska";
import { ref } from "vue";
import { useAppStore } from "../app/app.store";
import { useRegisterFormStore } from "./register-form.store";

const appStore = useAppStore();
const store = useRegisterFormStore();
const showPassword = ref(false);
const showCardInstruction = ref(true);
store.init();

</script>

<template>
  <fieldset xxv-if="store.form.user_info.user_mail" class="flex flex-col flex-1 gap-1" :disabled="!store.initialized || store.isGlobalProcessing">
    <TitleBar title="会員登録" />

    <hr class="mx-4" />

    <form
      class="flex-1 p-4 overflow-auto"
      @submit="
        async (e) => {
          e.preventDefault();
          await store.doSubmit();
        }
      "
    >
      <div class="p-4 mt-4 border border-yellow-600 rounded-lg bg-yellow-50">
        お名前とお電話番号は、運航状況の決定情報等をお知らせするために使用します。
      </div>
      <p class="mt-4 text-gray-600">名前</p>

      <div class="flex gap-2">
        <label class="flex flex-col flex-1 gap-1">
          姓
          <input
            v-model="store.form.user_info.last_name"
            :class="{ error: !store.suppressValidateError && store.validate.errorFields['user_info.last_name']?.length }"
            type="text"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.last_name'] ?? [])[0]?.message
            "
          />
        </label>

        <label class="flex flex-col flex-1 gap-1">
          名
          <input
            v-model="store.form.user_info.first_name"
            :class="{ error: !store.suppressValidateError && store.validate.errorFields['user_info.first_name']?.length }"
            type="text"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.first_name'] ?? [])[0]?.message
            "
          />
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          セイ
          <input
            v-model="store.form.user_info.last_name_kana"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_info.last_name_kana']?.length,
            }"
            type="text"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.last_name_kana'] ?? [])[0]?.message
            "
          />
        </label>

        <label class="flex flex-col flex-1 gap-1">
          メイ
          <input
            v-model="store.form.user_info.first_name_kana"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_info.first_name_kana']?.length,
            }"
            type="text"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.first_name_kana'] ?? [])[0]?.message
            "
          />
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          電話番号
          <input
            v-model="store.form.user_info.user_tel"
            :class="{ error: !store.suppressValidateError && store.validate.errorFields['user_info.user_tel']?.length }"
            type="tel"
            maxlength="13"
            v-maska
            data-maska="#"
            data-maska-tokens="#:\d:multiple"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.user_tel'] ?? [])[0]?.message
            "
          />
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          生年月日
          <input
            v-model.number="store.form.user_info.user_birthday_yy"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_info.user_birthday_yy']?.length,
            }"
            type="tel"
            placeholder="年"
            v-maska
            data-maska="####"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.user_birthday_yy'] ?? [])[0]?.message
            "
          />
        </label>
        <label class="flex flex-col flex-1 gap-1">
          &nbsp;
          <input
            v-model.number="store.form.user_info.user_birthday_mm"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_info.user_birthday_mm']?.length,
            }"
            type="tel"
            v-maska
            data-maska="##"
            placeholder="月"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.user_birthday_mm'] ?? [])[0]?.message
            "
          />
        </label>
        <label class="flex flex-col flex-1 gap-1">
          &nbsp;
          <input
          v-model.number="store.form.user_info.user_birthday_dd"
          :class="{
            error: !store.suppressValidateError && store.validate.errorFields['user_info.user_birthday_dd']?.length,
          }"
          type="tel"
            v-maska
            data-maska="##"
            placeholder="日"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.user_birthday_dd'] ?? [])[0]?.message
            "
          />
        </label>
      </div>

      <!-- <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          メールアドレス
          <span>{{ store.form.user_info.user_id }}</span>
        </label>
      </div> -->

      <!--<div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          <span
            >ID<span class="text-xs text-gray-600"
              >（半角英数字6-20字）</span
            ></span
          >
          <input
            v-model="store.form.user_info.user_id"
            :class="{ error: !store.suppressValidateError && store.validate.errorFields['user_info.user_id']?.length }"
            type="text"
            maxlength="20"
            v-maska
            data-maska="#"
            data-maska-tokens="#:[0-9A-Za-z]:multiple"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.user_id'] ?? [])[0]?.message
            "
          />
        </label>
      </div>-->

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          <span
            >パスワード<span class="text-xs text-gray-600"
              >（半角英数字8-32字）</span
            ></span
          >
          <div class="relative">
            <input
              v-model="store.form.user_info.user_password"
              :class="{
                error: !store.suppressValidateError && store.validate.errorFields['user_info.user_password']?.length,
              }"
              :type="showPassword ? 'text' : 'password'"
              maxlength="32"
              class="w-full pr-[100px] form-input"
            />

            <button
              @click="showPassword = !showPassword"
              type="button"
              class="absolute w-[80px] text-gray-400 bg-gray-200 rounded-full bottom-3 right-3"
            >
            表示{{ showPassword ? "しない" : "する" }}
            </button>
          </div>
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.user_password'] ?? [])[0]?.message
            "
          />
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          <span
            >パスワード確認<span class="text-xs text-gray-600"
              >（半角英数字8-32字）</span
            ></span
          >
          <input
            v-model="store.form.user_info.user_password_conf"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_info.user_password_conf']?.length,
            }"
            type="password"
            maxlength="32"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_info.user_password_conf'] ?? [])[0]
                ?.message
            "
          />
        </label>
      </div>

      <template v-if="!appStore.isMobileVersion">
        <hr class="mt-4" />

        <p class="mt-4 mb-2">クレジットカード登録</p>

        <div class="flex justify-center gap-2 p-4 bg-gray-100 border-t-gray-300">
          <img class="h-5" src="@/assets/visa.svg" alt="visa" />
          <img class="h-5" src="@/assets/mastercard.svg" alt="mastercard" />
          <img class="h-5" src="@/assets/jcb.svg" alt="jcb" />
          <img class="h-5" src="@/assets/amex.svg" alt="amex" />
          <img class="h-5" src="@/assets/diners.svg" alt="diners" />
        </div>

        <div class="mt-4 border border-yellow-600 rounded-lg bg-yellow-50">
          <div class="flex items-center justify-between pl-4" @click="showCardInstruction = !showCardInstruction">
            <HelpCircle class="w-4 h-4"/>
            クレジットカード情報の入力ヒント
            <span class="p-4">
              <ChevronDown v-if="!showCardInstruction" class="w-4 h-4"/>
              <ChevronUp v-if="showCardInstruction" class="w-4 h-4"/>
            </span>
          </div>
          <!-- <transition name="rawfade"> -->
            <div 
              @click.self="showCardInstruction = false">
              <div class="overflow-hidden transition-all duration-300 ease-in-out rounded-xl" :class="{
                'max-h-0': !showCardInstruction,
                'max-h-[500px]': showCardInstruction,
              }">
                <hr>
                <div class="p-4">
                  <img src="@/assets/card_instructions.png"/>
                </div>
              </div>
            </div>
          <!-- </transition> -->
        </div>

        <div class="flex gap-2 mt-4">
          <label class="flex flex-col flex-1 gap-1">
            <span class="flex items-center gap-2">
              カード番号
            </span>
            <div class="relative">
              <input
                  :class="{ error: !store.suppressValidateError && store.validate.errorFields['credit.number']?.length }"
                  v-maska="store.creditCardMask"
                  placeholder="0000 0000 0000 0000"
                  data-maska="#"
                  data-maska-tokens="#:\d:multiple"
                  type="tel"
                  maxlength="16"
                  class="w-full pl-11 form-input"
                />
              <CreditCard class="absolute w-6 text-gray-400 top-2 left-3" />
            </div>
            <ErrorMessage
              v-if="!store.suppressValidateError"
              :message="(store.validate.errorFields['credit.number'] ?? [])[0]?.message"
            />
          </label>
        </div>

        <div class="flex gap-2 mt-4">
          <label class="flex flex-col flex-1 gap-1">
            <span class="flex items-center gap-2">
              有効期限
            </span>
            <div class="flex items-center gap-4 w-[50%]">
              <input
                v-model="store.form.credit.mm"
                :class="{ error: !store.suppressValidateError && store.validate.errorFields['credit.mm']?.length }"
                v-maska
                data-maska="##"
                type="tel"
                placeholder="MM"
                class="w-full form-input"
              />
              /
              <input
                v-model="store.form.credit.yy"
                :class="{ error: !store.suppressValidateError && store.validate.errorFields['credit.yy']?.length }"
                v-maska
                data-maska="##"
                type="tel"
                placeholder="YY"
                class="w-full form-input"
              />
            </div>
            
            <ErrorMessage
              v-if="!store.suppressValidateError"
              :message="
                ((store.validate.errorFields['credit.mm'] || store.validate.errorFields['credit.yy']) ?? [])[0]?.message
              "
            />
          </label>
        </div>

        <div class="flex gap-2 mt-4">
          <label class="flex flex-col flex-1 gap-1">
            <span class="flex items-center gap-2">
              セキュリティコード
            </span>
            <input
              v-model="store.form.credit.cvc"
              :class="{ error: !store.suppressValidateError && store.validate.errorFields['credit.cvc']?.length }"
              type="tel"
              class="w-full form-input"
            />
            
            <ErrorMessage
              v-if="!store.suppressValidateError"
              :message="
                (store.validate.errorFields['credit.cvc'] ?? [])[0]?.message
              "
            />
          </label>
        </div>

        <div class="flex items-center gap-2 p-3 mt-4 text-red-800 border border-red-500 rounded-lg bg-red-50" v-if="store.cardErrorMessage">
          <AlertOctagon class="self-start w-4 h-6"/>
          <div class="self-start flex-1 whitespace-pre-wrap">
            {{ store.cardErrorMessage }}
          </div>
        </div>
      </template>

      <div class="flex items-center gap-2 p-3 mt-4 text-red-800 border border-red-500 rounded-lg bg-red-50" v-if="!store.suppressValidateError && !store.validate.pass">
        <AlertOctagon class="w-4 h-4"/>
        入力エラーがあります、再度確認ください。
      </div>

      <button
        :disabled="!store.initialized || !store.validate.isFinished || store.isGlobalProcessing"
        class="w-full mt-4 btn"
      >
        <LoadingIcon class="absolute w-4 h-4 left-4" v-if="store.isGlobalProcessing"/>
        {{ store.isGlobalProcessing ? '処理中' : 'この内容で登録する' }}
      </button>
    </form>

    <div 
      v-if="store.mainError"
      @click.self="store.mainError = null"
      class="fixed top-0 z-50 flex items-center justify-center w-full h-full bg-black/60">
      <div class="m-4 whitespace-pre-wrap bg-white rounded-xl">
        <div class="flex justify-between ">
          <div class="p-4 font-bold">エラー</div>
          <button class="p-4" type="button" @click="store.mainError = null">
            <X class="w-4 h-4"/>
          </button>
        </div>
        <hr>
        <div class="p-4">
          {{ store.mainError.message }}
        </div>
      </div>
    </div>

    <!-- <transition name="rawfade">
      <div 
        v-if="showCardInstruction"
        @click.self="showCardInstruction = false"
        class="fixed top-0 z-50 flex items-center justify-center w-full h-full bg-black/60">
        <div class="m-4 bg-white rounded-xl">
          <div class="flex items-center justify-between pl-4">
            クレジットカード情報の入力ヒント
            <button class="p-4" @click="showCardInstruction = false">
              <X class="w-4 h-4"/>
            </button>
          </div>
          <hr class="mx-4">
          <div class="p-4">
            <img src="@/assets/card_instructions.jpg"/>
          </div>
        </div>
      </div>
    </transition> -->

    
  </fieldset>
</template>
