<script setup lang="ts">
import LoadingIcon from "@/components/loading-icon.vue";
import TitleBar from "@/components/title-bar.vue";
import { formatDate, formatNumber } from "@/utils";
import { useAppStore } from "../app/app.store";
import { useBusinessReserveStore } from "./business-reserve.store";

const store = useBusinessReserveStore();
store.initConfirm();

const appStore = useAppStore();

</script>

<template>
  <main class="flex flex-col">
    <TitleBar class="text-xl" :title="store.ship.ship_name + `予約内容確認`"/>
    <div class="flex flex-col flex-1 gap-4 p-4 overflow-auto">
      <div class="px-4 py-2 font-bold bg-gray-100">船情報</div>
      <table class="w-full">
        <tr class="border-b--">
          <th class="w-20 px-4 font-semibold text-left text-gray-400 whitespace-nowrap">船名</th>
          <td class="px-2 py-1 text-left">{{ store.form.ship_name }}</td>
        </tr>
  
        <tr class="border-t">
          <th class="px-4 font-semibold text-left text-gray-400 whitespace-nowrap">番船</th>
          <td class="px-2 py-1 text-left">
            <span v-if="store.serviceNumberProc.isLoading" class="flex items-center gap-2"><LoadingIcon class="w-4 h-4"/> ロード中</span>
            <span v-else-if="store.form.serviceNumber == 0" >
              エラー
            </span>
            <span v-else >
                {{ store.form.serviceNumber }}番船
              </span>
          </td>
        </tr>
      </table>
      
      <div class="px-4 py-2 font-bold bg-gray-100">人数</div>
        <table class="w-full">
        <tr class="bg-gray-50-- ">
          <th class="w-20 px-4 py-1 font-semibold text-left text-gray-400">お名前</th>
          <td colspan="2" class="w-20 px-2 py-1 text-left">{{ appStore.session.user.last_name }} {{ appStore.session.user.first_name }}</td>
        </tr>

        <tr class="border-t">
          <th class="w-20 px-4 font-semibold text-left text-gray-400 whitespace-nowrap">
            総人数
          </th>
          <td class="w-20 px-2 py-1 text-left whitespace-nowrap">
            合計：{{ store.getPeopleCount() }}人
            <template v-if="store.form.women_count || store.form.child_count">
            <br>
              男性：{{ store.getPeopleCount() - (store.form.women_count??0) - (store.form.child_count??0) }}人
            </template>
            <br>
            <template v-if="store.form.women_count">
              女性：{{ store.form.women_count }}人
            </template>
            <template v-if="store.form.child_count">
              <template v-if="store.form.women_count">
                <br>
              </template>
              学生：{{ store.form.child_count }}人
            </template>
          </td>
          <td></td>
        </tr>
      </table>

      <div class="px-4 py-2 font-bold bg-gray-100">日時</div>
      <table class="w-full">
        <tr class="border-b">
          <th class="w-20 px-4 font-semibold text-left text-gray-400 whitespace-nowrap">日程</th>
          <td class="px-2 py-1 text-left">{{ formatDate(store.form.date) }}</td>
        </tr>

        <tr class="border-b--">
          <th class="px-4 font-semibold text-left text-gray-400 whitespace-nowrap">時間等</th>
          <td class="px-2 py-1 text-left">{{ store.form.time_name }}</td>
        </tr>

      </table>

      <!--<div class="px-4 py-2 font-bold bg-gray-100">同乗希望</div>
      <div class="px-4 text-left" v-if="store.form.separate_flg">希望しない</div>
      <div class="px-4 text-left" v-if="!store.form.separate_flg">希望する</div>-->

      <!--<div class="px-4 py-2 font-bold bg-gray-100">連絡事項</div>
      <div class="px-4 whitespace-pre-wrap">{{ store.form.info ?? 'なし'}}</div>-->

      <!-- <div class="px-4 py-2 font-bold bg-gray-100">船賃</div>
      <table class="w-full">
        <tr class="border-b">
          <th class="w-20 px-4 font-semibold text-left text-gray-400 align-top whitespace-nowrap">船賃</th>
            <td class="px-2 py-1 text-left">
              <div class="font-semibold--">
                ￥{{ formatNumber(store.fare * (store.peopleSums[0] + store.peopleSums[1])) }}
              </div>
              <div>
                (￥{{ formatNumber(store.fare) }}／人)
              </div>
            </td>
        </tr>
      </table> -->

      <div class="px-4 py-2 font-bold bg-gray-100">船賃</div>
      <div class="px-4 whitespace-pre-wrap">
        <div class="flex flex-col">
          <table class="w-fit self-end">
            <tr><td class="px-4 whitespace-nowrap">合計</td><td class="px-4">{{ store.getPeopleCount() }}人</td><td class="text-right">￥{{ formatNumber(store.calculatedFare) }}</td></tr>
            <tr><td colspan="3"><hr></td></tr>
            <tr v-if="(store.form.women_count??0) > 0 || (store.form.child_count??0) > 0">
              <td class="px-4">男性</td><td class="px-4">{{ store.getPeopleCount() - (store.form.women_count??0) - (store.form.child_count??0) }}人</td><td class="text-right">￥{{ formatNumber(store.fare * (store.getPeopleCount() - (store.form.women_count??0) - (store.form.child_count??0))) }}</td>
            </tr>
            <tr v-if="(store.form.women_count??0) > 0">
              <td class="px-4">女性</td><td class="px-4">{{ store.form.women_count }}人</td><td class="text-right">￥{{ formatNumber(store.ship.boat_fare_woman * store.form.women_count) }}</td>
            </tr>
            <tr v-if="(store.form.child_count??0) > 0">
              <td class="px-4">学生</td><td class="px-4">{{ store.form.child_count }}人</td><td class="text-right">￥{{ formatNumber(store.ship.boat_fare_child * store.form.child_count) }}</td>
            </tr>
          </table>
        </div>
      </div>
      

      <template v-if="store.calculatedFare <= store.point">
        <div class="px-4 py-2 font-bold bg-gray-100">ポイントを利用して乗船する</div>
        <div class="px-4 text-left">{{ store.form.use_point_flg ? "利用する" : "利用しない" }}</div>
      </template>
      <div class="text-red-600 font-bold">
        アプリで予約の変更、キャンセルは行えませんので、その際はお電話をお願い致します。
      </div>
      <button class="btn" @click="store.doSubmit">
        予約する
      </button>
      <router-link :to="{ name: 'business-reserve' }" class="secondary btn">
        キャンセル
      </router-link>
    </div>
  </main>
</template>

<style></style>
