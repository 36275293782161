<script setup lang="ts">
import { useBusinessReserveStore } from "./business-reserve.store";

const store = useBusinessReserveStore();

</script>

<template>
  <main class="flex flex-col">
    <div 
      class="flex flex-col gap-4 p-4">
      <div class="px-4 py-2 font-bold text-center whitespace-pre-wrap bg-red-100">{{ store.submitError }}</div>

      <router-link :to="{ name: 'business-reserve' }" class="secondary btn">
        やり直す
      </router-link>

      <router-link :to="{ name: 'business-home' }" class="btn">
        ホームへ
      </router-link>
    </div>
  </main>
</template>

<style></style>
