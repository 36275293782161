import * as api from "@/api";
import { useLazyAsyncState } from '@/utils';
import { defineStore } from "pinia";
import { ref } from "vue";

export const useBusinessInfoStore = defineStore("business-info", () => {
  const businessUuid = ref(null);

  const mainProc = useLazyAsyncState(() => api.businessInfo({ business_uuid: businessUuid.value }), {},);

  return {
    businessUuid,
    mainProc,
    async init() {
      await mainProc.execute();
    }
  };
});