<script setup lang="ts">
import { AlertOctagon } from 'lucide-vue-next';
import { useRouter } from 'vue-router';
import TitleBar from '../../components/title-bar.vue';
import { useWithdrawalStore } from './withdrawal.store';

const router = useRouter();
const store = useWithdrawalStore();
store.init();
</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="退会" />
    <hr class="mx-4" />
    <div class="flex flex-col flex-1 gap-4 p-4 overflow-auto">
      <v-async-block :proc="store.mainProc">
        <div class="text-center">
          退会してもよろしいですか？
        </div>

        <div class="flex items-center gap-2 p-3 text-orange-800 border border-orange-500 rounded-lg bg-orange-50" v-if="store.mainProc.state.ios">
          <AlertOctagon class="self-start w-4 h-4 mt-1"/>
          <div class="flex-1">
            退会する前にAppleのサブスクリプションを解約する必要です。
            案内はこちら：<br>
            <a href="https://support.apple.com/ja-jp/HT202039" class="block pt-4 font-bold text-blue-600">https://support.apple.com/ja-jp/HT202039</a>
          </div>
        </div>
        
        <button class="block w-full btn" type="button" 
        @click="e => { router.push({ name: 'withdrawal-reason' }) }"
        :disabled="store.mainProc.state.ios">
          退会へ進む
        </button>
      </v-async-block>
    </div>
</main>
</template>
