import * as api from "@/api";
import { LoginParams } from "@/api/types";
import { useLazyAsyncState } from '@/utils';
import { useAsyncValidator } from "@/validator";
import type { Rules } from "async-validator";
import { defineStore } from "pinia";
import { computed, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useAppStore } from '../app/app.store';
import { TokenInfo } from './../../api/types';

export const useAuthLoginStore = defineStore("auth-login", () => {
  const appStore = useAppStore();
  const tokenInfo = ref<TokenInfo>(null);

  const msg = ref("");

  // フォーム
  const form = reactive(new LoginParams({
  }));

  // フォームが触られたかどうか
  const touched = ref(false);

  // フォームが触られたなら検証を行う
  const rules = computed(
    () =>
      (!touched.value
        ? {}
        : {
            email: { required: true },
            password: { required: true },
          }) as Rules
  );

  // 検証用オブジェクト
  const validate = useAsyncValidator(form, rules);

  watch(form as any, () => {
    touched.value = true;
  });

  const mainProc = useLazyAsyncState(() => api.login(form), {});

  const router = useRouter();


  return {
    tokenInfo,
    form,
    touched,
    validate,
    mainProc,
    msg,

    async tryGetAccessToken(): Promise<string> {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      return accessToken;
    },

    async setFormData(email: string, password: string) {
      form.email = email;
      form.password = password;
    },

    async doSubmit(redirect?: string) {
      msg.value = "";
      if (!validate.pass.value) {
        return;
      }
      const mainProcData = await mainProc.execute();

      if (mainProc.error.value) {
        msg.value = "エラー";
        return;
      }

      tokenInfo.value = mainProcData;

      const { accessToken, refreshToken } = mainProcData;

      if (accessToken == null) {
        return (msg.value = "IDまたはパスワードが違います");
      }

      const appStore = useAppStore();
      appStore.session = {
        ...appStore.session,
        accessToken,
        refreshToken,
      }

      if (redirect) {
        if (redirect.startsWith('http')) {
          const decoded = decodeURIComponent(decodeURIComponent(redirect));
          location.href = decoded;
          return;
        } else {
          await router.replace({ name: redirect });
        }
        return;
      }

      if (appStore.isMobileVersion) {
        try {
          const myshop = await api.myshop();
          const hasMyShop = myshop && myshop.length;
          if (hasMyShop) {
            await router.replace({ name: 'my-shop' });
            return;
          }
        } catch (error) {

        }
      }
      
      await router.replace({ name: 'home' });
    },
  };
});
