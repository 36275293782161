import * as api from "@/api";
import { RegisterParams } from "@/api/types";
import { iabSubscribe } from "@/iab";
import { useAppStore } from '@/modules/app/app.store';
import { gmoInit, isValidOptionalBirthDate, useLazyAsyncState } from "@/utils";
import { useAsyncValidator } from "@/validator";
import { useDebounceFn } from '@vueuse/core';
import { Rules } from "async-validator";
import { MaskaDetail } from 'maska';
import { defineStore } from "pinia";
import { computed, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { PURCHASE_STATE_SUCCESS, PurchaseData } from "../../../capacitor-iab/src";
import { useAuthLoginStore } from './../auth-login/auth-login.store';
export const useRegisterFormStore = defineStore("register-form", () => {
  // フォーム
  const form = reactive(new RegisterParams());
  const appStore = useAppStore();
  const loginStore = useAuthLoginStore();

  // // フォームが触られたかどうか
  // const touched = ref(false);

  const isUserIdExistDebounced = useDebounceFn(async (value) => {
    return await api.isUserIdExist(value);
  }, 1000);

  // フォームが触られたなら検証を行う
  const rules = computed(
    () =>
      ({
        user_info: {
          type: "object",
          required: true,
          fields: {
            last_name: { required: true },
            first_name: { required: true },
            last_name_kana: { required: true },
            first_name_kana: { required: true },
            user_tel: [
              { required: true },
              // {
              //   type: "pattern",
              //   pattern:
              //     /^(?:\d{3} - \d{3} - \d{4}|\d{2} - \d{4} - \d{4}|\d{3} - \d{4} - \d{4})$/,
              //   message: "有効な電話番号ではありません",
              // },
            ],
            /*user_id: [
              { required: true },
              { min: 6 },
              // {
              //   asyncValidator(rule, value, callback) {
              //     return new Promise(async (resolve, reject) => {
              //       const exist = await isUserIdExistDebounced(value);
              //       if (exist) {
              //         reject('aaaaa');
              //       } else {
              //         resolve();
              //       }
              //     })
              //   },
              //   message: '利用できない利用者IDです',
              // }
            ],*/
            user_password: [
              { required: true },
              { min: 8 },
            ],
            user_password_conf: [
              { required: true },
              {
                validator(rule, value, callback) {
                  return value === form.user_info.user_password;
                },
                message: 'パスワードと一致しません',
              }
            ],
            // user_birthday_yy: {
            //   required: true,
            //   transform: val => val ? Number(val) : val,
            //   type: 'number', min: (new Date).getFullYear() - 200, max: (new Date).getFullYear(),
            // },
            // user_birthday_mm: {
            //   required: true,
            //   transform: val => val ? Number(val) : val,
            //   type: 'number', min: 1, max: 12,
            // },
            // user_birthday_dd: {
            //   required: true,
            //   transform: val => val ? Number(val) : val,
            //   type: 'number', min: 1, max: 31,
            // },
          },
        },

        credit: {
          type: "object",
          required: !appStore.isMobileVersion,
          fields: {
            number: [
              { required: !appStore.isMobileVersion },
              // {
              //   type: "pattern",
              //   pattern:
              //     /^(\d{4}\d{4}\d{4}\d{4}|\d{2}\d{4}\d{4}\d{4}|\d{3}\d{4}\d{4}\d{4})$/,
              //   message: "有効なカード番号ではありません",
              // },
            ],
            mm: {
              required: !appStore.isMobileVersion,
              transform: val => val ? Number(val) : val,
              type: 'number', min: 1, max: 12,
            },
            yy: {
              required: !appStore.isMobileVersion,
              transform: val => val ? Number(val) : val,
              type: 'number', min: Number(((new Date).getFullYear()).toString().substring(2)),
            },
            cvc: { required: !appStore.isMobileVersion },
            //token: { required: true },
          },
        }
      }) as Rules
  );

  // 検証用オブジェクト
  const validate = useAsyncValidator(form, rules);
  const suppressValidateError = ref(true);

  // form.user_info.user_mail = sessionStorage.getItem('address');

  const mainError = ref<Error>();
  const mainProc = useLazyAsyncState(() => api.regsiterAccount(form), {});

  const creditCardMask = ref({} as MaskaDetail);
  const router = useRouter();

  // creditCardMaskが変わる時、フォームを更新
  watch(creditCardMask.value, (v: any) => {
    form.credit.number = v.unmasked;
  });

  watch(() => [form.credit.number, form.credit.mm, form.credit.yy, form.credit.cvc], (v: any) => {
    cardErrorMessage.value = null;
  });

  const initialized = ref(false);
  const cardErrorMessage = ref(null);

  const purchaseProc = useLazyAsyncState(() => iabSubscribe(), { responseData:'' });

  return {
    cardErrorMessage,
    suppressValidateError,
    form,
    validate,
    creditCardMask,
    initialized,
    mainProc,
    purchaseProc,
    isGlobalProcessing: computed(() => mainProc.isLoading.value || purchaseProc.isLoading.value),
    // isGlobalProcessing: computed(() => mainProc.isLoading.value),
    mainError,

    async init() {
      if (!appStore.isMobileVersion) {
        await gmoInit(window['env'].GMO_TEST);
      }
// test
// form.user_info.first_name = "菜緒";
// form.user_info.last_name = "角田";
// form.user_info.first_name_kana = "ナオ";
// form.user_info.last_name_kana = "スミタ";
// form.user_info.user_tel = "0987654321";
// form.user_info.user_password = "11111111";
// form.user_info.user_password_conf = "11111111";
// form.user_info.user_id = "11";
// form.user_info.user_birthday_yy = "1984";
// form.user_info.user_birthday_mm = "10";
// form.user_info.user_birthday_dd = "11";
      initialized.value = true;
    },

    async doSubmit() {
      suppressValidateError.value = false;
      if (!validate.pass.value) {
        return;
      }

      if (!isValidOptionalBirthDate(form.user_info.user_birthday_yy, form.user_info.user_birthday_mm, form.user_info.user_birthday_dd)) {
        mainError.value = new Error("生年月日を正しく入力してください。");
        return;
      }

      const isTelExist = await api.isUserIdExist(form.user_info.user_tel);
      if (isTelExist) {
        mainError.value = new Error("利用できない電話番号です。");
        return;
      }

      

      // if (!appStore.isMobileVersion) {
      //   let cardno = form.credit.number;
      //   let expire = form.credit.yy + form.credit.mm;

      //   try {
      //     const gmoTokenInfo = await gmoGetToken(cardno, expire);
      //     form.credit.token = gmoTokenInfo.token;
      //     form.credit.token_at = gmoTokenInfo.token_at;
      //     form.credit.card_div = gmoGetCardBrand(cardno);
      //   } catch (error) {
      //     console.log({ error })
      //     cardErrorMessage.value = error.message;
      //     return;
      //   }
      // }

      if (appStore.isMobile) {
        const iabResult = await purchaseProc.execute();
        console.log('complete', JSON.stringify(iabResult))
        // window.alert(JSON.stringify(iabResult))

        if (iabResult.error) {
          const msg = `購入エラー\nエラーコード：${iabResult.errorCode}`;
          console.error(msg)
          // mainError.value = new Error(msg);
          return;
        }

        const { purchaseState, purchaseToken } = JSON.parse(iabResult.responseData) as PurchaseData;
        const isPurchaseOK = purchaseState == PURCHASE_STATE_SUCCESS
        if (!isPurchaseOK) {
          const msg = `購入エラー\nエラーコード：${99999}`;
          console.error(msg)
          // mainError.value = new Error(msg);
          return;
        }

        form.purchaseToken = purchaseToken ?? '';
      }
      
      form.platform = appStore.platform;

      console.log('do register')
      const success = await mainProc.execute();
      console.log('success', success)

      if (mainProc.error.value) {
        // const { response } = mainProc.error.value as any;
        // if (response && response.data.code === 1000) {
        //   mainProc.error.value = new Error(response.data.trace.data[0].errInfo);
        // }
        console.log('error', mainProc.error.value)
        mainError.value = new Error(mainProc.error.value['response'].data.error);
        return;
      }

      if (success) {
        sessionStorage.removeItem('address');
        alert("正常に登録しました。")

        loginStore.setFormData(form.user_info.user_tel, form.user_info.user_password);
        loginStore.doSubmit();
        // router.replace({ name: "login" });
      }
    },
  };
});
