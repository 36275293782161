<script setup lang="ts">
import { Phone } from "lucide-vue-next";
import { useBusinessReserveStore } from './business-reserve.store';
const store = useBusinessReserveStore();
</script>

<template>
  <main class="flex flex-col">
    <div 
      class="flex flex-col gap-4 p-4">
      <div class="px-4 py-20 font-bold text-center bg-green-100 text-xl">予約の受付が完了しました。</div>
      <div class="px-4 py-2">ご予約ありがとうございました。<br>ご予約の内容は上の　☆予約確認　でご確認いただけます。</div>
      <div class="px-4 py-2 font-bold text-red-600">天候不良等の様々な理由により欠航となる可能性がありますのでご了承ください。</div>
      <div class="px-4 py-2">その他のお問い合わせはお電話下さい。</div>
      <div class="px-4 py-2">
        <a v-if="store.ship.ship_name === store.businessShipProc.state.business_name && store.businessShipProc.state.business_tel" 
          :href="'tel:' + store.businessShipProc.state.business_tel" 
          class="flex items-center justify-between gap-2 py-4 btn">
          <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel }}</span>
        </a>
        <a v-if="store.ship.ship_name === store.businessShipProc.state.business_name_2 && store.businessShipProc.state.business_tel_2" 
          :href="'tel:' + store.businessShipProc.state.business_tel_2" 
          class="flex items-center justify-between gap-2 py-4 btn">
          <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel_2 }}</span>
        </a>
        <a v-if="store.ship.ship_name !== store.businessShipProc.state.business_name && store.ship.ship_name !== store.businessShipProc.state.business_name_2 && store.businessShipProc.state.business_tel && !store.businessShipProc.state.business_tel_2" 
          :href="'tel:' + store.businessShipProc.state.business_tel" 
          class="flex items-center justify-between gap-2 py-4 btn">
          <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel }}</span>
        </a>
      </div>
      <router-link :to="{ name: 'business-home' }" class="btn">
        ホームへ
      </router-link>
    </div>
  </main>
</template>

<style></style>
