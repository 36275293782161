import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const usePointHstStore = defineStore("profile-point-hst", () => {
  const pointHst = ref([]);
  const mainProc = useLazyAsyncState(() => api.profilePointHst(p), {});
  let p = 1;
  async function Init(page) {
    p = page;
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }

    pointHst.value = mainProcData;
  }

  return {
    pointHst,
    Init,
  };
});
