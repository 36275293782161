<script setup lang="ts">
import BlockingLoader from '@/components/blocking-loader.vue';
import ErrorMessage from '../../components/error-message.vue';
import TitleBar from '../../components/title-bar.vue';
import { ref } from "vue";
import { useAuthResetPasswordStore } from './auth-reset-password.store';

const store = useAuthResetPasswordStore();
const showPassword = ref(false);
store.done = false;
// onMounted(() => {
//   store.$reset();
// })
</script>

<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="パスワード再設定" />

    <hr class="mx-4" />
    
    <form class="p-8" v-if="!store.done" @submit="e => {
      e.preventDefault();
      store.doSubmit();
    }" novalidate>
      <div class="relative flex flex-col gap-4">
        <p>
          ご登録されている情報を入力してください。<br>パスワードを再設定します。<br><br>
        </p>
        <!--<label class="flex flex-col flex-1">メールアドレス
          <input
            v-model="store.form.address"
            :class="{ 'error': !store.suppressValidateError && store.validate.errorFields?.address?.length }"
            placeholder="例：user@example.com"
            type="email"
            class="w-full form-input">
          <ErrorMessage v-if="!store.suppressValidateError" :message="(store.validate.errorFields?.address ??[])[0]?.message"/>
        </label>-->
      </div>
      <div class="flex gap-2">
        <label class="flex flex-col flex-1 gap-1">
          姓
          <input
            v-model="store.form.last_name"
            type="text"
            class="w-full form-input"
          />
        </label>
        <label class="flex flex-col flex-1 gap-1">
          名
          <input
            v-model="store.form.first_name"
            type="text"
            class="w-full form-input"
          />
        </label>
      </div>
      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          電話番号
          <input
            v-model="store.form.user_tel"
            type="tel"
            maxlength="13"
            v-maska
            data-maska="#"
            data-maska-tokens="#:\d:multiple"
            class="w-full form-input"
          />
        </label>
      </div>
      <!--<div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          ID
          <input
            v-model="store.form.user_id"
            type="text"
            maxlength="20"
            v-maska
            data-maska="#"
            data-maska-tokens="#:[0-9A-Za-z]:multiple"
            class="w-full form-input"
          />
        </label>
      </div>-->
      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          <span
            >新しいパスワード<span class="text-xs text-gray-600"
              >（半角英数字8-32字）</span
            ></span
          >
          <div class="relative">
            <input
              v-model="store.form.user_password"
              :class="{
                error: !store.suppressValidateError && store.validate.errorFields['user_password']?.length,
              }"
              :type="showPassword ? 'text' : 'password'"
              class="w-full pr-[100px] form-input"
            />
            <button
              @click="showPassword = !showPassword"
              type="button"
              class="absolute w-[80px] text-gray-400 bg-gray-200 rounded-full bottom-3 right-3"
            >
            表示{{ showPassword ? "しない" : "する" }}
            </button>
          </div>
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_password'] ?? [])[0]?.message
            "
          />
        </label>
      </div>
      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          <span
            >新しいパスワード確認<span class="text-xs text-gray-600"
              >（半角英数字8-32字）</span
            ></span
          >
          <input
            v-model="store.form.user_password_conf"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_password_conf']?.length,
            }"
            type="password"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_password_conf'] ?? [])[0]
                ?.message
            "
          />
        </label>
      </div>
      <button
        :disabled="store.mainProc.isLoading"
        class="w-full mt-4 btn">
        送信する
      </button>
    </form>

    <div v-if="store.done" class="p-8">
      パスワードの再設定ができませんでした。再度設定を行ってください。<br><br>
      ご不明な点等ございましたら下記からお問い合わせください。<br><br>
      <a href="https://mystyle-design.com/contact/" class="flex p-3 bg-gray-100 border-t--">
        <span class="flex-1">お問い合わせ</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </a>
    </div>
    <BlockingLoader v-if="store.mainProc.isLoading" />
  </main>
  
</template>
