<script setup lang="ts">
import { CheckCircle } from "lucide-vue-next";
import { onMounted } from 'vue';
import TitleBar from '../../components/title-bar.vue';
import { useCreditInfoStore } from './credit-info.store';
const props = withDefaults(defineProps<{
  userPayId: string,
}>(), {})
const store = useCreditInfoStore();
store.deleteRes = false;

onMounted(() => {
  store.Init();
  store.UpdateInit(props.userPayId);
})
store.deleteRes = false;
</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="クレジットカード情報削除" />
    <hr class="mx-4" />
    <div class="p-4" v-if="!store.deleteRes">
      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          カード番号
          <div class="form-control">
            **** **** **** {{ store.creditInfo[props.userPayId].card_number_4 }}
          </div>
        </label>
      </div>
      <button @click="store.Delete()" class="block w-full mt-4 bg-red-600 btn">削除する</button>
    </div>
    <div class="flex items-center gap-2 p-3 mx-4 mt-4 text-green-800 border border-green-500 rounded-lg bg-green-50" v-else>
      <CheckCircle class="w-4 h-4"/>
      削除完了しました
    </div>
  </main>
</template>
