import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useBusinessCatchListStore = defineStore("business-catch-list", () => {

  const businessId = ref(null);
  let p = 1;

  const mainProc = useLazyAsyncState(() => api.businessCatchList(businessId.value, p), {});

  return {
    businessId,
    mainProc,
  };
});