<script setup lang="ts">

</script>

<template>
  <Transition name="fade">
    <div class="fixed top-0 left-0 z-50 w-screen h-screen">
      <img name="logolabo" src="@/assets/splash.jpg" class="object-cover w-full h-full"/>
    </div>
</Transition>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
