import * as api from "@/api";
import {
  BusinessInfo,
  BusinessSearchParams,
  SearchFilters
} from "@/api/types";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { useAppStore } from "../app/app.store";

export const useSearchFiltersStore = defineStore("search-filters", () => {
  const prefecture_div = ref({});
  const fishing = ref([]);
  const target = ref([]);
  const ship_name = ref("");
  const selectTarget = ref("ターゲット");
  const targetUuid = ref("");
  const selectFishing = ref("釣り方");
  const fishingUuid = ref("");
  const selectPrefecture = ref("都道府県");
  const prefectureCd = ref("");
  const myBottomSheet = ref(null);
  const res = ref([]);

  const multiFilter = reactive(new SearchFilters());

  // フォーム
  const form = reactive(new BusinessSearchParams());

  const activeBusinessInfo = ref<BusinessInfo>(null);

  const appStore = useAppStore();

  async function init() {
    prefecture_div.value = appStore.masterData.prefecture;
    fishing.value = appStore.masterData.fishing;
    target.value = appStore.masterData.target;
  }

  async function setFishing(name, uuid) {
    selectFishing.value = name;
    // fishingUuid.value = uuid;
    multiFilter.fishing_uuid = uuid;
  }
  async function setTarget(name, uuid) {
    selectTarget.value = name;
    targetUuid.value = uuid;
    multiFilter.target_uuid = uuid;
  }
  async function setPrefecture(name, uuid) {
    selectPrefecture.value = name;
    prefectureCd.value = uuid;
    multiFilter.prefecture_cd = uuid;
  }
  return {
    form,
    activeBusinessInfo,
    ship_name,
    init,
    myBottomSheet,
    open,
    close,
    prefecture_div,
    fishing,
    target,
    setFishing,
    setTarget,
    setPrefecture,
    selectTarget,
    selectFishing,
    selectPrefecture,
    res,
    prefectureCd,
    fishingUuid,

    targetUuid,
    multiFilter,
  };
});
