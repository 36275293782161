import * as api from "@/api";
import { PeopleRecord, ReserveParams } from "@/api/types";
import { parseDate, useLazyAsyncState } from "@/utils";
import { useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useAppStore } from "../app/app.store";

export const useBusinessReserveStore = defineStore("business-reserve", () => {
  const router = useRouter();
  const submitError = ref(null);

  const form = useSessionStorage('reserve-form', new ReserveParams());

  const businessReserveProc = useLazyAsyncState(() => api.businessReserve(form.value), {});

  /**
   * 船一覧画面
   */
  const businessShipProc = useLazyAsyncState(() => api.businessShip(form.value.business_uuid), {});

  /**
   * 人数入力画面
   */
  const getItemProc = useLazyAsyncState(
    () => api.businessItem(form.value.ship_uuid, form.value.business_uuid),
    {
      businessReserveLimit: '0',
      item: [],
    }
  );

  const times = ref({
    day: '',
    sche: [],
    service: [],
    detail: [],
    reserved: 0,
    message: null,
  });
  const date = ref<Date>(null);
  const errorMsg = ref("");
  watch(date, (newVal) => {
    form.value.date = newVal;
    if (form.value.date) {
      getServices();
    }
  });

  const getServicesProc = useLazyAsyncState(
    () =>
      api.businessServices(
        form.value.ship_uuid,
        form.value.peopleRecords,
        form.value.separate_flg,
        form.value.date
      ),
    {}
  );

  async function getServices() {
    const getDateProcData = await getServicesProc.execute();

    if (getServicesProc.error.value) {
      return;
    }
    times.value = getDateProcData;
    let lastDate = parseDate(times.value.day);
    /*if (form.value.date < lastDate) {
      errorMsg.value = "予約不可能な日付です";
      times.value = {
        day: '',
        sche: [],
        service: [],
        detail: [],
        message: null,
      };
    }*/

    if (form.value.date < lastDate || times.value.service.length == 0 && times.value.sche.length == 0 && times.value.detail.length == 0 && !times.value.message) {
      errorMsg.value = "予約不可能な日付です";
      times.value = {
        day: '',
        sche: [],
        service: [],
        detail: [],
        reserved: 0,
        message: null,
      };
    }

  }

  const getPointProc = useLazyAsyncState(() => api.businessReservePoint(
    form.value.time,
    getPeopleCount(),
    form.value.women_count,
    form.value.child_count,
    form.value.detail_flg
  ), {});

  async function getPoint() {
    const mainProcData = await getPointProc.execute();
    form.value.fare = mainProcData.fare;
  }

  const serviceNumberProc = useLazyAsyncState(() => api.businessReserveConf(
    form.value.business_uuid,
    form.value.time,
    form.value.separate_flg,
    form.value.peopleRecords,
    form.value.date,
    form.value.detail_flg
  ), 0);

  function setItem(idx, cnt, uuid) {
    form.value.peopleRecords[idx].board_count = cnt;
    form.value.peopleRecords[idx].item_uuid = uuid;
  }

  function getPeopleCount(){
    return form.value.peopleRecords.reduce((sum, value) => {
      return sum + value.board_count;
    }, 0);
  }
  
  const businessReserveCompProc = useLazyAsyncState(() => api.businessReserveComp(form.value), {});

  const appStore = useAppStore();
  const ship = computed(() => businessShipProc.state.value?.ship?.filter(v => v.ship_uuid === form.value.ship_uuid)[0] ?? {});
  return {
    serviceNumberProc,
    getServicesProc,
    getItemProc,
    date,
    form,
    businessReserveCompProc,
    // getShip,
    businessShipProc,
    items: computed(() => getItemProc.state.value.item),
    business_tel: computed(() => [businessShipProc.state.value.business_tel]),
    business_reserve: computed(() => businessShipProc.state.value.business_reserve_flg),
    reserve: computed(() => businessShipProc.state.value.reserve_flg),
    reserve_alert: computed(() => businessShipProc.state.value.reserve_alert),
    // getItem,
    getServices,
    // date,
    times,
    point: computed(() => getPointProc.state.value.point),
    calculatedFare: computed(() => getPointProc.state.value.calculatedFare),
    fare: computed(() => getPointProc.state.value.fare),
    getPoint,
    errorMsg,
    submitError,
    peopleCountLimit: computed(() => Number(getItemProc.state.value.businessReserveLimit)),
    //peopleCount: computed(() => form.value.peopleRecords.filter(v => v.item_uuid !== undefined).length),
    peopleCount: computed(() => getPeopleCount()),
    ship,
    shouldShowWomenShipFare: computed(() => {
      console.log(ship.value.boat_fare_woman, ship.value.boat_fare)
      return ship.value.boat_fare_woman != ship.value.boat_fare;
    }),
    shouldShowChildShipFare: computed(() => ship.value.boat_child_woman != ship.value.boat_child),
    setItem,
    getPeopleCount,
    peopleSums: computed(() => {
      let sums = [0, 0];
      // let arr: number[] = Object.values(form.value.people).flat();
      // for (let i in arr) {
      //   const idx = (Number(i) % 2 === 0) ? 0 : 1;
      //   sums[idx] += Number(arr[i]);
      // }

      return sums;
    }),

    async loadServiceNumber() {
      const serviceNumber = await serviceNumberProc.execute();
      form.value.serviceNumber = serviceNumber;
      return serviceNumber;
    },

    async getBusinessReserveDateList() {
      return api.businessReserveDateList(form.value.ship_uuid);
    },

    async getBusinessReserveHoliday(year, month, uuid) {
      return await api.businessReserveHoliday(year, month, uuid);
    },

    async registerShop(business_uuid: string) {
      await api.businessRegisterShop(business_uuid);
    },

    /**
     * 
     * @param businessId 
     */
    async initShipList(businessId: string) {
      this.$reset();
      form.value = new ReserveParams();
      form.value.business_uuid = businessId;

      const shipData = await businessShipProc.execute();
    },

    /**
     * 
     */
    async initForm() {
      const items = await getItemProc.execute();

      if (!form.value.peopleRecords.length) {
        form.value.peopleRecords = [];
        //for (let idx = 0; idx < Number(items.businessReserveLimit); idx++) {
        for (let idx = 0; idx < Number(items.item.length); idx++) {
          const rec = new PeopleRecord();
          /*if (idx == 0) {
            rec.name = `${appStore.session.user.last_name} ${appStore.session.user.first_name}`;
            rec.item_uuid = items.item[0]?.item_uuid;
          }*/
          rec.board_count = 0;
          rec.item_uuid = items.item[idx]?.item_uuid;
          form.value.peopleRecords.push(rec);
        }
        form.value.women_count = 0;
        form.value.child_count = 0;
      }
    },

    /**
     * 
     */
    async initConfirm() {
      //await this.loadServiceNumber();
    },

    /**
     * 予約する
     */
    async doSubmit() {

      const mainProcData = await businessReserveCompProc.execute();
      if (businessReserveCompProc.error.value) {
        const { response: { data: { error, code, data } } } = businessReserveCompProc.error.value as any;

        if (code == 1001) {
          // alert(`【${error}\n最初からやり直してください。`);
          submitError.value = `${error}\n最初からやり直してください。`;
          router.push({ name: 'business-reserve-error' });
        } else if (code == 1002) {
          alert(`${error}\n再度確認をお願いします。`);
          await this.loadServiceNumber();
        }

        return;
      }

      router.push({ name: 'business-reserve-done' });

    }
  };
});
