import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useBusinessNewsDetailStore = defineStore(
  "business-news-detail",
  () => {
    let newsUUID = null;
    const mainProc = useLazyAsyncState(() => api.businessNews(newsUUID), {});
    async function init(uuid) {
      newsUUID = uuid;
      await mainProc.execute();
      if (mainProc.error.value) {
        return;
      }
    }

    return {
      mainProc,
      init,
    };
  }
);
