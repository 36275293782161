<script setup lang="ts">
import LoadingSvg from '@/assets/loading-circle.svg';
import { UseAsyncStateReturn } from "@vueuse/core";
import { ref, watch } from "vue";

const props = withDefaults(defineProps<{
  proc: UseAsyncStateReturn<any, Array<any>, boolean>,
  delayLoadingMs?: number
}>(), {
  delayLoadingMs: 200
})

const showLoading = ref(false);

setTimeout(() => {
  showLoading.value = true;
}, props.delayLoadingMs);

watch(() => props.proc.isLoading, val => {
  console.log({ val })
  if (val) {
    // {props.delayLoadingMs}ミリ秒以内なら、強制的にロード中アイコンを表示しない
    setTimeout(() => {
      showLoading.value = true;
    }, props.delayLoadingMs);
  } else {
    showLoading.value = false;
  }
});

</script>
<template>
  <div v-bind="$attrs">
    <div v-if="props.proc.isLoading && showLoading">
      <slot name="loading">
        <img :src="LoadingSvg" class="w-full"/>
      </slot>
    </div>

    <div v-if="proc.error">
      <slot name="error">
        {{ proc.error.value }}
      </slot>
    </div>
  </div>

  <template v-if="proc.isReady">
    <slot></slot>
  </template>
</template>

<style></style>
