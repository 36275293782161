<script setup lang="ts">
import { useAppStore } from "@/modules/app/app.store";
import { useNotificationStore } from "@/modules/notification/notification.store";
import { Lock, Menu, MessageSquare, Sailboat, Search, Star } from "lucide-vue-next";
  // const props = withDefaults(defineProps<{
  //   titleBarClass: string,
  //   title: string,
  // }>(), {
  //   titleBarClass: '',
  //   title: '',
  // })
  const appStore = useAppStore();
  const notificationStore = useNotificationStore();
</script>

<template>
  <header class="relative">
    <section class="flex items-center gap-1 p-4">
      <!-- <router-link :to="{ name: 'home' }">
        <LayoutGrid class="w-6 h-6" />
      </router-link> -->
      <div class="flex-1 whitespace-nowrap"></div>

      <div class="flex gap-4">
        <router-link v-if="appStore.isMobileVersion" class="flex flex-col items-center gap-1 -mx-0.5-- text-center text-black w-fit"
          :to="{ name: 'search' }">
          <Search class="w-5 h-5" />
          <span class="text-[12px] scale-75-- whitespace-nowrap">釣船検索</span>
        </router-link>

        <template v-if="!appStore.isAuthenticated">
          <router-link class="flex flex-col items-center gap-1 -mx-0.5 text-center text-black w-fit"
            :to="{ name: 'login' }">
            <Lock class="w-5 h-5" />
            <span class="text-[12px] scale-75-- whitespace-nowrap">ログイン</span>
          </router-link>
        </template>
        <template v-else>
          <router-link v-if="appStore.isMobileVersion" class="flex flex-col items-center gap-1 -mx-0.5-- text-center text-black w-fit"
            :to="{ name: 'my-shop' }">


            <Sailboat class="w-5 h-5" />
            <span class="text-[12px] scale-75-- whitespace-nowrap">マイ<br>ショップ</span>
          </router-link>
          <router-link v-if="appStore.isMobileVersion" class="relative flex flex-col items-center gap-1 -mx-0.5-- text-center text-black w-fit"
            :to="{ name: 'notification' }">


            <MessageSquare class="w-5 h-5" />
            <span class="text-[12px] scale-75-- whitespace-nowrap">船からの<br>お知らせ</span>
            <span
              v-if="notificationStore.unreadCount"
             class="absolute -top-2 -right-1 inline-block p-[3px] text-xs leading-none min-w-[calc(1em+(3px)*2)] text-white bg-red-600 rounded-full aspect-square--">
              {{ notificationStore.unreadCount }}
            </span>

          </router-link>
          <router-link class="flex flex-col items-center gap-1 -mx-0.5-- text-center text-black w-fit"
            :to="{ name: 'profile-home' }">


            <Star class="w-5 h-5" />
            <span class="text-[12px] scale-75-- whitespace-nowrap">予約確認</span>
          </router-link>
        </template>
        <router-link class="flex flex-col items-center gap-1 -mx-0.5-- text-center text-black w-fit"
          :to="{ name: 'top-menu' }">


          <Menu class="w-5 h-5" />
          <span class="text-[12px] scale-75-- whitespace-nowrap">メニュー</span>
        </router-link>
      </div>
    </section>
</header>
</template>

<style></style>
