<script setup lang="ts">
import TitleBar from "@/components/title-bar.vue";
import { computed, ref } from "vue";
import { useBusinessInfoDetailStore } from "./business-info-detail.store";

const props = withDefaults(
  defineProps<{
    shoreId: string;
  }>(),
  {}
);
const store = useBusinessInfoDetailStore();

store.spotId = props.shoreId;
store.init();

const imgList = computed(() => store.mainProc.state.img || [])
const mainPhotoIdx = ref(0);

</script>

<template>
  <main class="flex flex-col flex-1 no-select-drag">
    <TitleBar :title="store.mainProc.state.spot?.spot_title" />
      <div class="flex-1 overflow-auto">
        <v-async-block :proc="store.mainProc">
          <div class="p-4 pt-0">
            <v-img :src="imgList[mainPhotoIdx]" class="object-cover aspect-[4/3] w-full" />
            
            <div v-if="imgList.length > 1" class="relative grid grid-cols-5 gap-2 mt-2">
              <div class="w-full h-full"
                  v-for="(v, k) in imgList" :key="k"
                  @click="e => { mainPhotoIdx = k }">
                <v-img 
                  v-if="(v ?? '').indexOf('now_printing.png') == -1"
                  :src="v"
                  class="object-cover w-full h-full" />
              </div>
            </div>
          </div>

          <div class="p-4 pt-0">
            <!-- <p class="font-bold text-justify text-primary">
              {{ store.mainProc.state.spot.spot_title }}
            </p> -->
            <p class="mt-2 text-justify whitespace-pre-wrap">
              {{ store.mainProc.state.spot.spot_body }}
            </p>
          </div>
      </v-async-block>
    </div>
  </main>
</template>

<style></style>
