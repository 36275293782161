import { BusinessInfo, BusinessSearchParams } from '@/api/types';
import { useAsyncState } from '@vueuse/core';
import { defineStore } from "pinia";
import { reactive, ref } from "vue";

import * as api from "@/api";

export const useSearchMapStore = defineStore("search-map", () => {
  // フォーム
  const form = reactive(new BusinessSearchParams);

  const mapview = ref(null);

  const activeBusinessInfo = ref<BusinessInfo>(null);

  const mainProc = useAsyncState<BusinessInfo[]>(
    () => api.searchBusiness(form), 
    [], 
    { immediate: false }
  );

  return {
    mapview,
    form,
    mainProc,
    activeBusinessInfo,
    
    async doSubmit() {
      
      const mainProcData = await mainProc.execute();
    },

  };
});
