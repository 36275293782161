<script setup lang="ts">
import TitleBar from '@/components/title-bar.vue';
import { Phone } from "lucide-vue-next";
import { formatDate, formatNumber } from '@/utils';
import { useRouter } from 'vue-router';
import { useBusinessReserveStore } from './business-reserve.store';

const store = useBusinessReserveStore();
store.getPoint();
const router = useRouter();

async function next() {
  const serviceNumber = await store.loadServiceNumber();

  if (serviceNumber == 0) {
    store.submitError = `予約できませんでした。\n最初からやり直してください。`;
    await router.push({ name: 'business-reserve-error' });
    return;
  }
  await router.push({ name: 'business-reserve-confirm' });
}

</script>

<template>
  <main class="flex flex-col">
    <div v-if="store.reserve.business">
      <TitleBar class="text-xl" :title="store.ship.ship_name + `連絡事項`"/>
      <div class="flex flex-col flex-1 gap-4 p-4 overflow-auto">
        <!--<div>連絡事項</div>-->
        <div class="text-red-600 font-bold">{{ store.reserve_alert }}</div>
        <div v-if="!store.reserve_alert" class="font-bold">何か連絡事項がございましたらお電話をお願いいたします</div>
        <!--<textarea 
          v-model="store.form.info"
          placeholder="連絡事項等をご記入ください"
          rows="5" class="form-input min-h-[100px]"></textarea>-->
          <!--
          <a v-if="store.ship.ship_name === store.businessShipProc.state.business_name && store.businessShipProc.state.business_tel" 
            :href="'tel:' + store.businessShipProc.state.business_tel" 
            class="flex items-center justify-between gap-2 py-4 btn">
            <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel }}</span>
          </a>
          <a v-if="store.ship.ship_name === store.businessShipProc.state.business_name_2 && store.businessShipProc.state.business_tel_2" 
            :href="'tel:' + store.businessShipProc.state.business_tel_2" 
            class="flex items-center justify-between gap-2 py-4 btn">
            <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel_2 }}</span>
          </a>
          <a v-if="store.ship.ship_name !== store.businessShipProc.state.business_name && store.ship.ship_name !== store.businessShipProc.state.business_name_2 && store.businessShipProc.state.business_tel && !store.businessShipProc.state.business_tel_2" 
            :href="'tel:' + store.businessShipProc.state.business_tel" 
            class="flex items-center justify-between gap-2 py-4 btn">
            <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel }}</span>
          </a>
          -->
        <table class="w-full mt-4 mb-4">
          <tr class="border-b">
            <th class="w-20 font-semibold text-left text-gray-400 align-top whitespace-nowrap">船賃</th>
            <td class="px-2 py-1 text-right">
              <div class="flex flex-col">
                ￥{{ formatNumber(store.calculatedFare) }}
                <table class="w-fit self-end">
                  <tr v-if="(store.form.women_count??0) > 0 || (store.form.child_count??0) > 0">
                    <td class="px-4">男性</td><td class="px-4">{{ store.getPeopleCount() - (store.form.women_count??0) - (store.form.child_count??0) }}人</td><td>￥{{ formatNumber(store.fare * (store.getPeopleCount() - (store.form.women_count??0) - (store.form.child_count??0))) }}</td>
                  </tr>
                  <tr v-if="(store.form.women_count??0) > 0">
                    <td class="px-4">女性</td><td class="px-4">{{ store.form.women_count }}人</td><td class="text-right">￥{{ formatNumber(store.ship.boat_fare_woman * store.form.women_count) }}</td>
                  </tr>
                  <tr v-if="(store.form.child_count??0) > 0">
                    <td class="px-4">学生</td><td class="px-4">{{ store.form.child_count }}人</td><td class="text-right">￥{{ formatNumber(store.ship.boat_fare_child * store.form.child_count) }}</td>
                  </tr>
                </table>
              </div>
            </td>
            
          </tr>

          <!-- <tr class="border-b">
            <th class="font-semibold text-left text-gray-400 whitespace-nowrap">&nbsp;</th>
            <td class="px-2 py-1 text-right"></td>
          </tr> -->

          <tr class="border-b">
            <th class="font-semibold text-left text-gray-400 whitespace-nowrap">現在のポイント</th>
            <td class="px-2 py-1 text-right"> {{ formatNumber(store.point) }}</td>
          </tr>
        </table>
        <div v-if="store.calculatedFare <= store.point" class="flex items-center mb-4">
          <input
            v-model="store.form.use_point_flg"
            id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">ポイントを利用して乗船する</label>
        </div>

        <button class="btn" @click="next()">
          確認画面へ
        </button>
      </div>
    </div>
    <div v-else>
      <TitleBar class="text-xl" :title="store.ship.ship_name + `電話予約`"/>
      <div class="flex flex-col flex-1 gap-4 p-4 overflow-auto">
        <div class="text-red-600 font-bold">{{ store.reserve_alert }}</div>
        <div v-if="!store.reserve_alert" class="font-bold">ご予約はお電話でお願いいたします</div>
        <a v-if="store.ship.ship_name === store.businessShipProc.state.business_name && store.businessShipProc.state.business_tel" 
          :href="'tel:' + store.businessShipProc.state.business_tel" 
          class="flex items-center justify-between gap-2 py-4 btn">
          <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel }}</span>
        </a>
        <a v-if="store.ship.ship_name === store.businessShipProc.state.business_name_2 && store.businessShipProc.state.business_tel_2" 
          :href="'tel:' + store.businessShipProc.state.business_tel_2" 
          class="flex items-center justify-between gap-2 py-4 btn">
          <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel_2 }}</span>
        </a>
        <a v-if="store.ship.ship_name !== store.businessShipProc.state.business_name && store.ship.ship_name !== store.businessShipProc.state.business_name_2 && store.businessShipProc.state.business_tel && !store.businessShipProc.state.business_tel_2" 
          :href="'tel:' + store.businessShipProc.state.business_tel" 
          class="flex items-center justify-between gap-2 py-4 btn">
          <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel }}</span>
        </a>
        <table class="w-full mt-4 mb-4">
          <tr class="border-b">
            <th class="w-20 font-semibold text-left text-gray-400 align-top whitespace-nowrap">船賃</th>
            <td class="px-2 py-1 text-right">
              <div class="flex flex-col">
                ￥{{ formatNumber(store.form.fare) }}
              </div>
            </td>
          </tr>
          <tr class="border-b">
            <th class="font-semibold text-left text-gray-400 whitespace-nowrap">現在のポイント</th>
            <td class="px-2 py-1 text-right"> {{ formatNumber(store.point) }}</td>
          </tr>
        </table>
        <table class="w-full mt-4 mb-4">
          <tr class="border-b--">
            <th class="w-20 font-semibold text-left text-gray-400 align-top whitespace-nowrap">日程</th>
            <td class="px-2 py-1 text-right">{{ formatDate(store.form.date) }}</td>
          </tr>
          <tr class="border-b--">
            <th class="w-20 font-semibold text-left text-gray-400 align-top whitespace-nowrap">船名</th>
            <td class="px-2 py-1 text-right">{{ store.form.ship_name }}</td>
          </tr>
          <tr class="border-b--">
            <th class="w-20 font-semibold text-left text-gray-400 align-top whitespace-nowrap">便名</th>
            <td class="px-2 py-1 text-right">{{ store.form.time_name }}</td>
          </tr>
        </table>
        <a v-if="store.ship.ship_name === store.businessShipProc.state.business_name && store.businessShipProc.state.business_tel" 
          :href="'tel:' + store.businessShipProc.state.business_tel" 
          class="flex items-center justify-between gap-2 py-4 btn">
          <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_tel }}</span>
        </a>
      </div>
    </div>
  </main>
</template>

<style></style>