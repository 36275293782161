<script setup lang="ts">

</script>

<template>
  <Transition name="fade">
    <div>
      <div class="fixed top-0 left-0 z-50 w-screen h-screen">
        <img name="logolabo" src="@/assets/backimage-11.jpg" class="object-cover w-full h-full" />
      </div>

      <div class="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-screen h-screen gap-10">
        <div class="text-center w-80">
          <p class="mb-4 text-3xl font-bold text-white shadow-sm">今すぐ、ダウンロード</p>
          <img src="@/assets/app-screen-shoots.png"/>
        </div>
        <div class="flex flex-col items-center justify-center gap-4 w-52">
          
          <a href="https://play.google.com/store/apps/details?id=com.seacleaning.f">
            <img src="@/assets/download-google.png"/>
          </a>

          <a href="https://apps.apple.com/jp/app/sea-cleaning-f/id6446161033">
            <img src="@/assets/download-apple.png"/>
          </a>

          <router-link :to="{ name: 'home' }"
            class="w-full bg-white shadow-sm focus:bg-white btn text-primary">
            トップに戻る
          </router-link>
        </div>
      </div>
    </div>
  </Transition>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
