<script setup lang="ts">
import { AlertOctagon, CheckCircle, CreditCard } from "lucide-vue-next";
import { vMaska } from "maska";
import { onMounted } from "vue";
import TitleBar from "../../components/title-bar.vue";
import { useCreditInfoStore } from "./credit-info.store";
const store = useCreditInfoStore();
store.insertRes = false;

onMounted(() => {
  store.Init();
});
store.insertRes = false;
</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="クレジットカード情報登録" />
    <hr class="mx-4" />
    <div class="flex-1 p-4 overflow-auto" v-if="!store.insertRes">
      <div class="relative flex gap-2">
        <label class="flex flex-col flex-1 gap-1">
          カード番号
          <input v-maska="store.creditCardMask" data-maska="#### #### #### ####" type="text"
            class="w-full pl-11 form-input" />
        </label>
        <CreditCard class="absolute w-6 text-gray-400 bottom-3 left-3" />
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          有効期限
          <div class="flex items-center gap-4 w-[50%]">
            <input v-model="store.form.credit.mm" v-maska data-maska="##" type="text" placeholder="MM"
              class="w-full form-input" />
            /
            <input v-model="store.form.credit.yy" v-maska data-maska="##" type="text" placeholder="YY"
              class="w-full form-input" />
          </div>
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          セキュリティコード
          <input v-model="store.form.credit.cvc" type="text" class="w-full form-input" />
        </label>
      </div>

      <div v-if="store.creditInfo.length >= 1" class="flex items-center mt-4 mb-4">
        <input v-model="store.form.credit.defaultFlg" id="default-checkbox" type="checkbox" value=""
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
        <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">常にこのカードで決済</label>
      </div>

      <div class="flex items-center gap-2 p-3 mt-4 text-red-800 border border-red-500 rounded-lg bg-red-50" v-if="store.cardErrorMessage">
        <AlertOctagon class="w-4 h-4"/>
        {{ store.cardErrorMessage }}
      </div>

      <button @click="store.Insert()" class="w-full mt-4 btn">この内容で登録する</button>
    </div>

    <div class="flex items-center gap-2 p-3 mx-4 mt-4 text-green-800 border border-green-500 rounded-lg bg-green-50" v-if="store.insertRes">
      <CheckCircle class="w-4 h-4"/>
      登録完了
    </div>

  </main>
</template>
