<script setup lang="ts">
import { useWindowSize, watchOnce } from "@vueuse/core";
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useOnboardingStore } from './onboarding.store';
const props = withDefaults(defineProps<{
  backable?: boolean,
}>(), {
  backable: false,
})

const swiper = ref(null)
const slideEnd = ref(false)
const store = useOnboardingStore();
const router = useRouter();
const loaded = ref(false);
const windowSize = useWindowSize();
const scale = ref(1);

const onNext = () => {
  if (slideEnd.value) {
    store.markAsDone();

    if (props.backable) {
      router.back();
    } else {
      router.replace({ name: 'home' })
    }
  } else {
    swiper.value?.swiper?.slideNext(500);
  }
};
const onNextRegist = () => {
  if (props.backable) {
    router.back();
  } else {
    store.markAsDone();
    router.push({ name: 'register-form' })
  }
};
const imgWidth = 561;
const imgHeight = 1063;
onMounted(() => {
  swiper.value?.swiper?.on('slideChange', function ({ isEnd }) {
    slideEnd.value = isEnd;
  });

  watchOnce(loaded, () => {
    watch(windowSize.height, (height) => {
      if (height <= imgHeight) {
        return;
      }
      
      scale.value = height / swiper.value.clientHeight;

    }, { immediate: true });
  });
  
});

let imgCount = 5;
let imgLoaded = 0;
function handleLoad() {
  imgLoaded++;
  if (imgLoaded === imgCount) {
    loaded.value = true;
  }
}
function getImgUrl(name) {
  return new URL(`/src/assets/${name}`, import.meta.url).href
}
</script>

<template>
  <main class="flex-1 h-full overflow-hidden bg-white" :class="{'opacity-0': !loaded}">
    <button 
      v-if="backable"
      @click="() => { router.back() }" 
      type="button" class="fixed z-50 flex items-center gap-2 p-4 text-primary">閉じる</button>
    <div class="flex flex-col justify-center h-full">
      <div class="h-fit">
        <swiper-container ref="swiper" :pagination="false" class="flex items-center justify-center h-full" :style="{scale}">
          <swiper-slide v-for="i in imgCount" class="flex items-center self-center justify-center h-fit w-fit">
            <div class="max-w-full max-h-full h-fit w-fit">
              <img @load="handleLoad"  @error="handleLoad" :src="getImgUrl(`onboarding-${i}.jpg`)" class="object-contain h-full max-h-screen"/>
              
              <div v-if="i < imgCount" class="absolute bottom-0 z-10 flex items-center justify-center w-full">
                <button @click="onNext"
                  :class="{
                    'aspect-[250/140] mb-[10%]': i < imgCount,
                  }"
                  class=" bg-blue-100/80 w-[85%] rounded-md opacity-0">
                  <!-- {{ slideEnd ? '完了' : '次へ' }} -->
                </button>
              </div>
              <div v-if="i === imgCount" class="absolute bottom-0 z-10 flex items-center justify-center w-full">
                <button @click="onNextRegist"
                  :class="{
                    'aspect-[800/200] mb-[32%]': i === imgCount,
                  }"
                  class=" bg-blue-100/80 w-[85%] rounded-md opacity-0">
                  <!-- {{ slideEnd ? '完了' : '次へ' }} -->
                </button>
              </div>
              <div v-if="i === imgCount" class="absolute bottom-0 z-10 flex items-center justify-center w-full">
                <button @click="onNext"
                  :class="{
                    'aspect-[900/140] mb-[17%]': i === imgCount,
                  }"
                  class=" bg-blue-100/80 w-[85%] rounded-md opacity-0">
                  <!-- {{ slideEnd ? '完了' : '次へ' }} -->
                </button>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </main>
</template>