<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
// import { RouterLinkOptions } from 'vue-router';
type Items = {
  labelEn: string,
  labelJa: string,
  route?: {
    name: string,
    params?: any,
  }
}

const props = withDefaults(defineProps<{
  items: Items[]
}>(), {
  
})

const route = useRoute();
const router = useRouter();
// router.route

</script>
<template>
  <main class="p-2 bg-white">
    <div class="flex gap-[1px] bg-gray-400">
      <router-link 
        v-for="(item, idx) in items"
        v-bind:key="idx"
        :to="item.route"
        class="relative flex-1 bg-white">
        <img 
        :class="{'opacity-0': !(route.matched.some(v => v.name == item.route.name)) }"
        src="@/assets/selector-tabs.png" class="object-contain w-full h-full"/>
        <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center p-4 ">
          <div class="font-semibold font-shalimar">{{ item.labelEn }}</div>
          <div class="font-bold text-md whitespace-nowrap font-yuji">{{ item.labelJa }}</div>
        </div>
      </router-link>
  <!-- 
      <div class="relative bg-white">
        <img src="@/assets/selector-tabs.png" class="object-contain w-full h-full"/>
        <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center p-4 ">
          <div class="font-semibold font-shalimar">RESERVE</div>
          <div class="font-bold text-md whitespace-nowrap font-yuji">予約</div>
        </div>
      </div>

      <div class="relative bg-white">
        <img src="@/assets/selector-tabs.png" class="object-contain w-full h-full"/>
        <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center p-4 ">
          <div class="font-semibold font-shalimar">CATCH</div>
          <div class="font-bold text-md whitespace-nowrap font-yuji">釣果</div>
        </div>
      </div>

      <div class="relative bg-white">
        <img src="@/assets/selector-tabs.png" class="object-contain w-full h-full"/>
        <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center p-4 ">
          <div class="font-semibold font-shalimar">INFO</div>
          <div class="font-bold text-md whitespace-nowrap font-yuji">瀬情報</div>
        </div>
      </div>

      <div class="relative bg-white">
        <img src="@/assets/selector-tabs.png" class="object-contain w-full h-full"/>
        <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center p-4 ">
          <div class="font-semibold font-shalimar">SHOP</div>
          <div class="font-bold text-md whitespace-nowrap font-yuji">釣船情報</div>
        </div>
      </div> -->
    </div>
  </main>
</template>

<style>

</style>
