<script setup lang="ts">
import * as api from "@/api";
import TitleBar from '@/components/title-bar.vue';
import { formatDate } from '@/utils';
import { Clock10, Sailboat } from "lucide-vue-next";
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useBusinessCatchListStore } from './business-catch-list.store';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
const props = withDefaults(defineProps<{
  businessId: string,
}>(), {
})
const router = useRouter();

const store = useBusinessCatchListStore();
store.businessId = props.businessId;

onMounted(() => {
  store.mainProc.execute();
});

function moveDetail(uuid) {
  router.push({ name: 'business-catch-detail', params: { catchId: uuid } })
}
let page = 1;
async function loadMore() {
  return api.businessCatchList(props.businessId, ++page)
}
const load = async $state => {
  if(!(store.mainProc.state !== undefined && store.mainProc.state.length === 0)){
    console.log("loading...");
    try {
      const res = await loadMore()
      if (res === undefined || res.length < 1) {
        $state.complete();
      }
      else {
        store.mainProc.state = store.mainProc.state.concat(res)
        $state.loaded();
      }
    } catch (error) {
      $state.error();
    }
  }else{
    $state.complete();
  }
};
</script>

<template>
  <main class="flex flex-col flex-1 no-select-drag">
    <TitleBar title="釣果一覧" :back="false" />
    <v-async-block :proc="store.mainProc">
      <div v-if="store.mainProc.state.length == 0"
        class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
        釣果情報がありません
      </div>
      <div class="grid flex-1 grid-cols-2 gap-2 p-4 pt-0 overflow-auto auto-rows-min">

        <div v-for="(v, k) in store.mainProc.state" :key="k" class="relative aspect-square">
          <div @click="moveDetail(v.catch_uuid)" class="w-full h-full">
            <v-img :src="v.img" class="object-cover w-full h-full" />
            <div
              class="absolute bottom-0 flex flex-col w-full gap-2 p-2 overflow-hidden text-xs text-white bg-black/30 text-ellipsis">
              <div class="flex items-center gap-2"><Sailboat class="w-4 h-4"/>{{ v.ship_name }}</div>
              <div class="flex items-center gap-2"><Clock10 class="w-4 h-4"/>{{ formatDate(v.catch_at, false) }}</div>
            </div>
          </div>
        </div>
      </div>
      <InfiniteLoading @infinite="load">
        <template #complete>
          <span> </span>
        </template>
      </InfiniteLoading>
    </v-async-block>
  </main>
</template>

<style></style>