<script setup lang="ts">
import { useRouter } from 'vue-router';
import TitleBar from '../../components/title-bar.vue';
import { useAppStore } from '../app/app.store';
import { useWithdrawalReasonStore } from './withdrawal-reason.store';

const store = useWithdrawalReasonStore();
const appStore = useAppStore();
const router = useRouter();
</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar :title="store.displayComp ? '退会完了' : '退会確認'" :back="!store.displayComp"/>
    <hr class="mx-4" />
    <div class="p-4">
      <div v-if="store.displayReason" class="flex flex-col gap-4">
        <div>
          <div>退会理由</div>
          <textarea v-model="store.reason" class="w-full mt-1 form-input"></textarea>
        </div>
        <button @click="store.doSubmit" class="block w-full bg-red-600 btn">退会する</button>
      </div>
      <div v-if="store.displayComp" class="flex flex-col gap-4 text-center">
        ご利用頂きありがとうございました。
        <button @click="e => {
          router.replace({ name: 'login' });
        }" class="block w-full btn">トップへ戻る
        </button>
      </div>

    </div>
  </main>
</template>
