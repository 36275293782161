<script setup lang="ts">
import { ChevronRight } from "lucide-vue-next";
import { ref } from 'vue';
import TitleBar from '../../components/title-bar.vue';

const showPassword = ref(false);

</script>

<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="メニュー" />
    <hr class="mx-4" />
    <div class="flex-1 p-4 overflow-auto">
      <router-link :to="{ name: 'onboarding', query: { backable: true } }" class="flex p-3">
        <span class="flex-1">このアプリについて</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </router-link>

      <a href="https://seacleaning-f.mystyle-design.com/terms/" class="flex p-3 border-t">
        <span class="flex-1">利用規約</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </a>

      <a href="https://seacleaning-f.mystyle-design.com/specified/" class="flex p-3 border-t">
        <span class="flex-1">特定商取引法に基づく表記</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </a>

      <a href="https://seacleaning-f.mystyle-design.com/privacy-policy/" class="flex p-3 border-t">
        <span class="flex-1">プライバシーポリシー</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </a>

      <!-- <a href="https://seacleaning-f.mystyle-design.com/terms/" class="flex p-3 border-t">
        <span class="flex-1">よくある質問</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </a> -->

      <a href="https://mystyle-design.com/company/" class="flex p-3 border-t">
        <span class="flex-1">会社概要</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </a>

      <a href="https://mystyle-design.com/contact/" class="flex p-3 bg-gray-100 border-t--">
        <span class="flex-1">お問い合わせ</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </a>

    </div>
  </main>
</template>
