<script setup lang="ts">
import TitleBar from '@/components/title-bar.vue';
import { formatNumber } from '@/utils';
import { ChevronRight, CreditCard, Heart, Link, User, Wallet } from "lucide-vue-next";
import { useRouter } from 'vue-router';
import { useAppStore } from '../app/app.store';
import { useProfileHomeStore } from './profile-home.store';

const router = useRouter();
const appStore = useAppStore();
const store = useProfileHomeStore();
store.Init();


</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="マイページ" />
    <hr class="mx-4" />
    <div class="flex-1 p-4 overflow-auto">
      <div class="flex">
        <div class="flex flex-col items-center flex-1 gap-2">
          <div class="flex items-center justify-center w-6 h-6 text-white bg-blue-600 rounded-full">
            P
          </div>
          <div>ポイント</div>
          <div class="font-bold">{{ formatNumber(store.point.have_point) }}</div>
        </div>
      </div>

      <div class="p-3 mt-4 bg-gray-100">
        お客様情報
      </div>

      <router-link :to="{ name: 'profile-user-info' }" class="flex p-3">
        <User class="w-6 h-6 mr-3 primary" />
        <span class="flex-1">会員情報</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </router-link>

      <!--<router-link v-if="true || !appStore.isMobileVersion" :to="{ name: 'credit' }" class="flex p-3 border-t">
        <CreditCard class="w-6 h-6 mr-3 primary" />
        <span class="flex-1">クレジットカード情報</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </router-link>-->

      <router-link :to="{ name: 'reserve-hst' }" class="flex p-3 border-t">
        <Wallet class="w-6 h-6 mr-3 primary" />
        <span class="flex-1">予約確認</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </router-link>

      <router-link :to="{ name: 'profile-point-hst' }" class="flex p-3 border-t">
        <Heart class="w-6 h-6 mr-3 primary" />
        <span class="flex-1">ポイント履歴</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </router-link>

      <router-link :to="{ name: 'withdrawal' }" class="flex p-3 border-t">
        <Link class="w-6 h-6 mr-3 primary" />
        <span class="flex-1">退会</span>
        <ChevronRight class="w-6 h-6 text-gray-400" />
      </router-link>

      <!-- <div class="flex justify-between p-3 border-t">
        <BellRing class="w-6 h-6 mr-3 primary" />
        <span class="flex-1">
          通知受信
        </span>
        <Switch
          v-model="appStore.settings.notification"
          :class="appStore.settings.notification ? 'bg-primary' : 'bg-gray-200'"
          class="relative inline-flex items-center h-6 rounded-full w-11"
        >
          <span class="sr-only">通知受信</span>
          <span
            :class="appStore.settings.notification ? 'translate-x-6' : 'translate-x-1'"
            class="inline-block w-4 h-4 transition transform bg-white rounded-full"
          />
        </Switch>
      </div> -->

      <div class="p-0 mt-4 text-center bg-gray-100">
        <button 
          @click="e => {
            appStore.logout();
            router.replace({ name: 'login' });
          }"
          class="p-3 px-10 font-bold primary">
          ログアウト
        </button>
      </div>
    </div>
  </main>
</template>
