import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";

export const useBusinessCatchDetailStore = defineStore(
  "business-catch-detail",
  () => {
    let catch_uuid = null;

    const mainProc = useLazyAsyncState(() => api.businessCatchDetail({ catch_uuid, }), {});
    async function init(uuid) {
      catch_uuid = uuid;

      const mainProcData = await mainProc.execute();

      if (mainProc.error.value) {
        return;
      }
    }

    return {
      mainProc,
      init,
    };
  }
);
