import { RouteRecordRaw } from "vue-router";

const isMobileVersion = window['env'].APP_FLG;
export default <RouteRecordRaw[]> [
  {
    name: "onboarding",
    path: "/onboarding",
    component: () => import("../modules/onboarding/onboarding.vue"),
    props: route => route.query,
  },

  // register-home
  {
    name: "register-home",
    path: "/register-home",
    component: () => import("../modules/register-home/register-home.vue"),
  },
  {
    name: "download",
    path: "/download",
    component: () => import("../modules/download/download.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/layouts/BaseLayoutView.vue"),
    // redirect: {
    //   name: isMobileVersion ? 'search' : 'profile-home',
    // },
    children: [

      // reset-password
      {
        name: "reset-password",
        path: "reset-password",
        component: () => import("../modules/auth-reset-password/auth-reset-password.vue"),
      },

      // reset-password
      {
        name: "set-password",
        path: "set-password/:token",
        component: () => import("../modules/auth-reset-password/set-password.vue"),        
        props: route => {
          const { token } = route.params;
          return { token };
        },
      },
      // login
      {
        name: "login",
        path: "login",
        component: () => import("../modules/auth-login/auth-login.vue"),
        props: route => route.query,

      },

      // register-email-send
      {
        name: "register-email-send",
        path: "register-email-send",
        component: () => import("../modules/register-email-send/register-email-send.vue"),
      },

      // register-email-confirm
      {
        name: "register-email-confirm",
        path: "register-email-confirm",
        component: () => import("../modules/register-email-confirm/register-email-confirm.vue"),
      },

      // register-email-confirm-token
      {
        name: "register-email-confirm-token",
        path: "register-email-confirm-token",
        component: () => import("../modules/register-email-confirm-token/register-email-confirm-token.vue"),
        props: route => route.query,
      },

      // register-form
      {
        name: "register-form",
        path: "register-form",
        component: () => import("../modules/register-form/register-form.vue"),
      },

      // profile-home
      {
        name: "profile-home",
        path: "profile-home",
        component: () => import("../modules/profile-home/profile-home.vue"),
        meta: {
          protected: true,
        }
      },

      // profile-user-info
      {
        name: "profile-user-info",
        path: "profile-user-info",
        component: () => import("../modules/profile-user-info/profile-user-info.vue"),
        meta: {
          protected: true,
        }
      },

      // profile-user-edit
      {
        name: "profile-user-edit",
        path: "profile-user-edit",
        component: () => import("../modules/profile-user-edit/profile-user-edit.vue"),
        meta: {
          protected: true,
        }
      },

      // profile-point-hst
      {
        name: "profile-point-hst",
        path: "profile-point-hst",
        component: () => import("../modules/profile-point-hst/profile-point-hst.vue"),
        meta: {
          protected: true,
        }
        
      },

      // top-menu
      {
        name: "top-menu",
        path: "top-menu",
        component: () => import("../modules/top-menu/top-menu.vue"),
      },

      // search
      {
        name: "search",
        path: "search",
        component: () => import("../modules/search/search.vue"),
        meta: {
          transition: 'fade',
        },
      },

      // search-filters
      {
        name: "search-filters",
        path: "search-filters",
        component: () => import("../modules/search/search-filters.vue"),
        props: route => ({ filters: route.query }),
        meta: {
          transition: 'fade',
        },
      },

      // search-map
      {
        name: "search-map",
        path: "search-map",
        component: () => import("../modules/search/search-map.vue"),
        meta: {
          transition: 'fade',
        },
      },

      // withdrawal
      {
        name: "withdrawal",
        path: "withdrawal",
        component: () => import("../modules/withdrawal/withdrawal.vue"),
      },

      // withdrawal-reason
      {
        name: "withdrawal-reason",
        path: "withdrawal-reason",
        component: () => import("../modules/withdrawal-reason/withdrawal-reason.vue"),
      },

      // reserve-hst
      {
        name: "reserve-hst",
        path: "reserve-hst",
        component: () => import("../modules/reserve-hst/reserve-hst.vue"),
      },

      // my-shop
      {
        name: "my-shop",
        path: "my-shop",
        component: () => import("../modules/my-shop/my-shop.vue"),
        meta: {
          transition: 'fade',
          protected: true,
        }
      },
      // notification
      {
        name: "notification",
        path: "notification",
        component: () => import("../modules/notification/notification.vue"),
        props: route => {
          const { userPushHistoryUuid } = route.query;
          return { userPushHistoryUuid };
        },
        meta: {
          transition: 'fade',
          protected: true,
        }
      },

      // business
      {
        name: "business",
        path: "business/:businessId",
        component: () => import("../views/layouts/BusinessLayoutView.vue"),
        props: route => {
          const { businessId } = route.params;
          return { businessId };
        },
        children: [
          {
            name: "business-home",
            path: "",
            component: () => import("../modules/business-home/business-home.vue"),
            props: route => route.params,
            meta: {
              transition: 'fade',
            },
          },

          {
            name: "business-news",
            path: "news",
            redirect: { name: 'business-news-list' },
            children: [
              {
                name: "business-news-list",
                path: "",
                component: () => import("../modules/business-news/business-news-list.vue"),
                props: route => route.params,
              },
              {
                name: "business-news-detail",
                path: ":newsId",
                component: () => import("../modules/business-news/business-news-detail.vue"),
                props: route => route.params,
              },
            ]
          },

          {
            name: "business-catch",
            path: "catch",
            redirect: { name: 'business-catch-list' },
            children: [
              {
                name: "business-catch-list",
                path: "",
                component: () => import("../modules/business-catch/business-catch-list.vue"),
                props: route => route.params,
                meta: {
                  transition: 'fade',
                },
              },
              {
                name: "business-catch-detail",
                path: ":catchId",
                component: () => import("../modules/business-catch/business-catch-detail.vue"),
                props: route => route.params,
              },
            ]
          },

          {
            name: "business-info",
            path: "info",
            redirect: { name: 'business-info-home' },
            children: [
              {
                name: "business-info-home",
                path: "",
                component: () => import("../modules/business-info/business-info.vue"),
                // props: route => route.params,
                props: route => {
                  const { businessId } = route.params;
                  return { businessId };
                },
                meta: {
                  transition: 'fade',
                },
              },
              {
                name: "business-info-list",
                path: ":areaId/shore",
                component: () => import("../modules/business-info/business-info-list.vue"),
                props: route => {
                  const { businessId, areaId } = route.params;
                  return { businessId, areaId };
                },
              },
              {
                name: "business-info-detail",
                path: ":areaId/shore/:shoreId",
                component: () => import("../modules/business-info/business-info-detail.vue"),
                props: route => {
                  const { businessId, areaId, shoreId } = route.params;
                  return { businessId, areaId, shoreId };
                },
              },
            ]
          },

          {
            name: "business-reserve",
            path: "reserve",
            props: route => {
              const { businessId } = route.params;
              return { businessId };
            },
            redirect: { name: 'business-reserve-home' },
            children: [
              {
                name: "business-reserve-home",
                path: "",
                component: () => import("../modules/business-reserve/business-reserve.vue"),
                props: route => {
                  const { businessId } = route.params;
                  return { businessId };
                },
                meta: {
                  transition: 'fade',
                  // protected: true,
                },
              },
              {
                name: "business-reserve-form",
                path: "form",
                component: () => import("../modules/business-reserve/business-reserve-form.vue"),
                props: route => {
                  const { shipId } = route.params;
                  return { shipId };
                },
              },
              {
                name: "business-reserve-calendar",
                path: "calendar",
                component: () => import("../modules/business-reserve/business-reserve-calendar.vue"),
              },
              {
                name: "business-reserve-info",
                path: "info",
                component: () => import("../modules/business-reserve/business-reserve-info.vue"),
              },
              {
                name: "business-reserve-confirm",
                path: "confirm",
                component: () => import("../modules/business-reserve/business-reserve-confirm.vue"),
              },
              {
                name: "business-reserve-done",
                path: "done",
                component: () => import("../modules/business-reserve/business-reserve-done.vue"),
              },
              {
                name: "business-reserve-error",
                path: "error",
                component: () => import("../modules/business-reserve/business-reserve-error.vue"),
              },
            ]
          },
          {
            name: "business-shop",
            path: "shop",
            component: () => import("../modules/business-shop/business-shop.vue"),
            props: route => {
              const { businessId } = route.params;
              return { businessId };
            },
            meta: {
              transition: 'fade',
            },
          },
        ],
      },
      {
        name: "search-result",
        path: "search-result",
        component: () => import("../modules/search-result/search-result.vue"),
        props: route => ({ filters: route.query }),
        meta: {
          transition: 'fade',
        },
      },

      {
        name: "credit",
        path: "credit",
        props: (route) => {
          const { userPayId } = route.params;
          return { userPayId };
        },
        redirect: { name: 'credit-home' },
        children: [
          {
            name: "credit-home",
            path: "",
            component: () =>
              import("../modules/credit-info/credit-info.vue"),
              props: (route) => route.params,
          },
          {
            name: "credit-detail",
            path: "",
            props: (route) => route.params,
            children: [
              {
                name: "credit-update",
                path: ":userPayId/update",
                component: () =>
                  import("../modules/credit-info/credit-info-update.vue"),
                props: (route) => route.params,
              },
              {
                name: "credit-delete",
                path: ":userPayId/delete",
                component: () =>
                  import("../modules/credit-info/credit-info-delete.vue"),
                props: (route) => route.params,
              },
            ]
          },
          {
            name: "credit-insert",
            path: "insert",
            component: () =>
              import("../modules/credit-info/credit-info-insert.vue"),
          },

        ],
      },
    ],
  },
];
