<script setup lang="ts">
import TitleBar from "@/components/title-bar.vue";
import { useRouter } from 'vue-router';
import { useBusinessInfoListStore } from './business-info-list.store';

const router = useRouter();
const props = withDefaults(defineProps<{
  areaId: string,
}>(), {})

const store = useBusinessInfoListStore();
store.init(props.areaId);

function moveDetail(uuid) {
  router.push({ name: 'business-info-detail', params: { shoreId: uuid } })
}
</script>

<template>
  <main class="flex flex-col flex-1 no-select-drag">
    <TitleBar title="磯一覧" />
    <v-async-block :proc="store.mainProc">
      <div 
        v-if="store.mainProc.state.length"
        class="grid flex-1 grid-cols-2 gap-2 p-4 pt-0 overflow-auto">
        <div @click="moveDetail(v.spot_uuid)" v-for="(v, k) in store.mainProc.state" :key="k" class="relative aspect-square">
          <v-img :src=v.img class="object-cover w-full h-full" />
          <div
            class="absolute bottom-0 w-full p-2 overflow-hidden text-xs text-white bg-black/30 line-clamp-3 text-ellipsis">
            {{ v.spot_title }}
          </div>
        </div>
      </div>

      <div
        v-if="!store.mainProc.state.length"
        class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20"
      >
        磯情報がありません
      </div>
    </v-async-block>
  </main>
</template>

<style></style>