<script setup lang="ts">
import { useStorage } from '@vueuse/core';
import "leaflet/dist/leaflet.css";
import { Map, Settings } from "lucide-vue-next";
import SearchFilter from './search-filters.vue';
import SearchMap from './search-map.vue';

const mapMode = useStorage("mapMode", false);
</script>

<template>
  <main class="flex flex-col">
    <div class="flex bg-blue-600 border-b-2 border-b-blue-600">
      <div
        @click="mapMode = false"
        :class="{
          'bg-gray-100 text-black': mapMode,
          'bg-blue-600 text-white': !mapMode,
        }"
        class="flex items-center justify-center flex-1 gap-4 p-3">
        <Settings class="w-4 h-4"/>
        条件から探す
      </div>

      <div
        @click="mapMode = true"
        :class="{
          'bg-gray-100 text-black': !mapMode,
          'bg-blue-600 text-white': mapMode,
        }"
        class="flex items-center justify-center flex-1 gap-4 p-3">
        <Map class="w-4 h-4"/>
        地図から探す
      </div>
    </div>
    <div class="z-0 flex-1 overflow-auto">
      <SearchFilter v-if="!mapMode"/>
      <SearchMap v-if="mapMode"/>
    </div>
  </main>
  
</template>
<style></style>
