import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";

export const useWithdrawalStore = defineStore("withdrawal", () => {
  const mainProc = useLazyAsyncState(() => api.isAppleAutoSubscription(), {
    ios: false,
    android: false
  });

  return {
    mainProc,
    async init() {
      await mainProc.execute();
    },
  };
});
