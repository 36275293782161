import { SplashScreen } from "@capacitor/splash-screen";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

import * as api from "@/api";
import * as notifications from "@/notifications";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { useStorage } from "@vueuse/core";
import { SafeArea, SafeAreaInsets } from "capacitor-plugin-safe-area";
import { useRouter } from "vue-router";
import { useNotificationStore } from "../notification/notification.store";

// function getLocalSetting(key: string, defaultValue: any = null) {
//   return localStorage.getItem(`setting_${key}`) ?? defaultValue;
// }

// function setLocalSetting(key: string, value: any) {
//   return localStorage.setItem(`setting_${key}`, JSON.stringify(value));
// }

export const useAppStore = defineStore("app", () => {
  const isMobileVersion = ref<boolean>(window['env'].APP_FLG);
  const initialized = ref(false);  
  const canReserve = ref(false);
  const showSplash = ref(Capacitor.getPlatform() !== 'ios');
  const safeAreaInsets = ref<SafeAreaInsets>(null);
  const masterData = ref(null);
  const notificationStore = useNotificationStore();

  const settings = useStorage("settings", {
    notification: true,
  });

  const session = useStorage("session", {
    user: null,
    accessToken: null,
    refreshToken: null,
  });

  const router = useRouter();

  const isAuthenticated = computed(
    () => session.value.accessToken !== null && session.value.accessToken !== false
  );

  const platform = Capacitor.getPlatform();
  const isIos = Capacitor.getPlatform() === 'ios';

  return {
    platform,
    masterData,
    isMobileVersion,
    isMobile: computed(() => platform !== 'web'),
    isIos,
    showSplash,
    initialized,
    settings,
    session,
    isAuthenticated,
    safeAreaInsets,

    async logout() {
      session.value = {
        user: null,
        accessToken: null,
        refreshToken: null,
      };
    },

    async init() {
      safeAreaInsets.value = await SafeArea.getSafeAreaInsets();

      // マスタデータ取得
      masterData.value = await api.initialize();
      
      // スマホバックボタン処理
      App.addListener("backButton", () => {
        router.back();
      });

      watch(
        () => session.value.accessToken,
        async (token) => {
          if (token) {
            const profile = await api.profileHome();
            session.value.user = profile;

            await this.registerFcm();
          } else {
            session.value.user = null;
          }
        },
        { immediate: true }
      );

      //
      initialized.value = true;
      await SplashScreen.hide();

      notificationStore.init();

      setTimeout(() => {
        showSplash.value = false;
      }, 2000);
    },

    async registerFcm() {
      if (Capacitor.isNativePlatform()) {

        await notifications.addListeners({
          onTokenReceived: (token) => {
            api.registerFcmToken({ token });
          },
          onPushNotificationReceived: (notification) => {
            notificationStore.init();
          }
        });
        console.log('notifications.addListeners');

        await notifications.register();
        console.log('notifications.register');
      }
    },
  };
});
