<script setup lang="ts">
import * as api from "@/api";
import { formatDateTime } from '@/utils';
import "leaflet/dist/leaflet.css";
import { BellRing, Check, Clock10, MessageSquare } from 'lucide-vue-next';
import { onBeforeRouteUpdate } from "vue-router";
import { useNotificationStore } from './notification.store';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
const props = withDefaults(defineProps<{
  userPushHistoryUuid?: string,
}>(), {
})

const store = useNotificationStore();
store.init(props.userPushHistoryUuid, 1);

onBeforeRouteUpdate(() => {
  store.init(props.userPushHistoryUuid);
})
let page = 1;
async function loadMore() {
  return api.notification(++page)
}
const load = async $state => {
  if(!(store.mainProc.state !== undefined && store.mainProc.state.length === 0)){
    console.log("loading...");
    try {
      const res = await loadMore()
      if (res === undefined || res.length < 1) {
        $state.complete();
      }
      else {
        store.mainProc.state = store.mainProc.state.concat(res)
        $state.loaded();
      }
    } catch (error) {
      $state.error();
    }
  }else{
    $state.complete();
  }
};
</script>

<template>
  <main class="flex flex-col">
    <section class="relative h-14">
      <div class="z-10 flex items-center w-full h-full gap-1">
        <button type="button" class="p-4">
            <MessageSquare class="w-5 h-5" />
          </button>
          <div class="flex-1 p-4 pl-0 font-semibold">
            船からお知らせ
          </div>
      </div>
    </section>
    <div class="flex-1 overflow-auto">
      <v-async-block :proc="store.mainProc">
        <div class="z-0 flex flex-col gap-1 bg-gray-100">
          <div
            v-for="(data, k) in store.mainProc.state" :key="k"
            @click="store.activeNotification = data"
            class="flex items-center justify-center gap-4 px-4 py-2 bg-white">
            <BellRing v-if="data.read_flg === '0'"/><span v-if="data.read_flg === '0'" class="font-bold">未読</span>
            <Check v-if="data.read_flg === '1'" /><span v-if="data.read_flg === '1'">既読</span>
            <div class="flex flex-col flex-1 gap-1">
              <div :class="{
                'font-bold': data.read_flg === '0',
                'text-gray-500': data.read_flg === '1',
              }" class="flex justify-between">
                {{ data.push_title }}
                <span class="flex items-center gap-1 text-xs text-gray-400">
                  <Clock10 class="w-3 h-3" /> {{ formatDateTime(data.push_at, false) }}
                </span>
              </div>
              <div :class="{
                'font-bold': data.read_flg === '0',
                'text-gray-500': data.read_flg === '1',
              }" class="inline-block max-w-full overflow-hidden text-xs whitespace-pre-wrap line-clamp-2 text-ellipsis">
                {{ data.push_body }}
              </div>
            </div>
          </div>
        </div>
        <InfiniteLoading @infinite="load">
          <template #complete>
            <span> </span>
          </template>
        </InfiniteLoading>
        <div v-if="store.mainProc.state.length === 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
          船からお知らせがありません
        </div>
      </v-async-block>
    </div>
    <transition name="rawfade">
      <div v-if="store.activeNotification" @click.self="store.activeNotification = null"
        class="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-black/50">
        <div class="bg-white w-[80vw] rounded-md p-4 flex flex-col gap-1">
          <div class="">{{ store.activeNotification.push_title }}</div>
          <div class="flex items-center gap-1 text-xs text-gray-400"><Clock10 class="w-3 h-3" /> {{ formatDateTime(store.activeNotification.push_at, false) }}</div>
          <div class="whitespace-pre-wrap">{{ store.activeNotification.push_body }}</div>
        </div>
      </div>
    </transition>
  </main>
</template>
<style></style>
