<script setup lang="ts">
import * as api from "@/api";
import { formatDateTime, formatNumber } from '@/utils';
import { Clock10 } from "lucide-vue-next";
import { onMounted } from 'vue';
import TitleBar from '../../components/title-bar.vue';
import { usePointHstStore } from './profile-point-hst.store';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
const store = usePointHstStore();
onMounted(() => {
  store.Init(page);
})
let page = 1;
async function loadMore() {
  return api.profilePointHst(++page)
}
const load = async $state => {
  if(!(store.pointHst !== undefined && store.pointHst.length === 0)){
    console.log("loading...");
    try {
      const res = await loadMore()
      if (res === undefined || res.length < 1) {
        $state.complete();
      }
      else {
        store.pointHst = store.pointHst.concat(res)
        $state.loaded();
      }
    } catch (error) {
      $state.error();
    }
  }else{
    $state.complete();
  }
};
const divNames = {
  1:'取得',
  2:'利用',
  3:'特別付与',
  4:'没収',
  5:'返還',
  99:'その他'
}
</script>

<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="ポイント履歴" />
    <div v-if="store.pointHst.length === 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
      ポイント履歴がありません
    </div>
    <div class="flex flex-col flex-1 gap-4 p-4 overflow-auto">
      <div
          v-for="(info, k) in store.pointHst" :key="k"
          
          class="relative flex gap-4 p-4 border rounded h-16--">
          <!-- <img src="@/assets/amex.svg"/> -->
          <div class="flex items-center flex-1 gap-2">
            <div class="flex items-center justify-center w-4 h-4 text-xs text-white bg-blue-600 rounded-full">
              P
            </div>
            {{ formatNumber(info.user_point) }}
            ポイント
          </div>
          <div class="flex items-center gap-2">
            <Clock10 class="w-3 h-3"/>
            {{ formatDateTime(info.user_point_at, false) }}
          </div>
          <!--  -->
          <div 
          class="absolute top-0 right-0 px-2 text-xs text-gray-800 bg-gray-300 rounded-tr rounded-bl">{{ divNames[info.point_div] }}</div>
      </div>
      <InfiniteLoading @infinite="load">
        <template #complete>
          <span> </span>
        </template>
      </InfiniteLoading>
    </div>
  </main>
</template>
