import * as api from "@/api";
import { useAppStore } from '@/modules/app/app.store';
import { useLazyAsyncState } from "@/utils";
import { useAsyncValidator } from "@/validator";
import { useSessionStorage } from "@vueuse/core";
import type { Rules } from "async-validator";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";

export const useRegisterEmailSendStore = defineStore(
  "register-email-send",
  () => {

    const form = reactive({ email: '' })

    const rules = ({
      email: {
        type: "email",
        required: true,
      },
    }) as Rules;

    // 検証用オブジェクト
    const validate = useAsyncValidator(form, rules);
    const suppressValidateError = ref(true);

    const appStore = useAppStore();

    const msg = ref("");
    const mainProc = useLazyAsyncState(() => api.registerEmail({ 
      address: form.email,
      web_flg: !appStore.isMobileVersion
    }), {});

    const sentCode = useSessionStorage('code', null);
    const sentTime = useSessionStorage('sendTime', null);
    const sentAddress = useSessionStorage('address', null);

    async function send() {
      suppressValidateError.value = false;
      if (!validate.pass.value) {
        return;
      }

      const mainProcData = await mainProc.execute();
      if (mainProc.error.value) {
        const { response: { data } } = mainProc.error.value as any;
        msg.value = data.error;
        return;
      }

      if (mainProcData.res) {
        sentTime.value = Date.now().toString();
        sentCode.value = mainProcData.code;
        sentAddress.value = form.email;
        msg.value = "";
      }
    }
    return {
      form,
      validate,
      suppressValidateError,
      mainProc,
      msg,
      send,
    };
  }
);
