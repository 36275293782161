<script setup lang="ts">
import { onMounted } from 'vue';
import TitleBar from '../../components/title-bar.vue';
import { useUserInfoStore } from './profile-user-info.store';

const store = useUserInfoStore();
onMounted(() => {
  store.Init();
})
</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="会員情報" />
    <div class="flex-1 p-4 pt-0 overflow-auto">
      <table class="w-full">
        <tr class="border-b">
          <th class="w-20 font-semibold text-left whitespace-nowrap">利用者名</th>
          <td class="px-2 py-3 text-left">{{ store.userInfo.last_name }} {{ store.userInfo.first_name }}</td>
        </tr>

        <tr class="border-b">
          <th class="font-semibold text-left whitespace-nowrap">利用者カナ</th>
          <td class="px-2 py-3 text-left">{{ store.userInfo.last_name_kana }} {{ store.userInfo.first_name_kana }}</td>
        </tr>

        <tr class="border-b">
          <th class="font-semibold text-left whitespace-nowrap">電話番号</th>
          <td class="px-2 py-3 text-left">{{ store.userInfo.user_tel }}</td>
        </tr>

        <tr class="border-b">
          <th class="font-semibold text-left whitespace-nowrap">生年月日</th>
          <td class="px-2 py-3 text-left">{{ store.userInfo.user_birthday }}</td>
        </tr>

        <!--<tr class="border-b">
          <th class="font-semibold text-left whitespace-nowrap">メールアドレス</th>
          <td class="px-2 py-3 text-left">{{ store.userInfo.user_mail }}</td>
        </tr>-->

        <tr class="border-b">
          <th class="font-semibold text-left whitespace-nowrap">所有ポイント</th>
          <td class="px-2 py-3 text-left">{{ store.userInfo.have_point }}</td>
        </tr>

      </table>

      <router-link
        :to="{ name: 'profile-user-edit' }"
        class="block w-full mt-4 btn"
      >
        会員情報変更
      </router-link>
    </div>
  </main>
</template>
