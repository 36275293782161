import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useBusinessInfoDetailStore = defineStore(
  "business-info-detail",
  () => {
    const spotId = ref(null);

    const mainProc = useLazyAsyncState(() => api.businessInfoDetail({ spot_uuid: spotId.value, }), {});

    return {
      spotId,
      mainProc,
      async init() {
        await mainProc.execute();
      }
    };
  }
);
