import * as api from "@/api";
import {
  SearchFilters
} from "@/api/types";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { reactive } from "vue";

export const useSearchResultStore = defineStore("search-result", () => {
  let params = reactive(new SearchFilters());
  const mainProc = useLazyAsyncState(() => api.searchFilters(params), []);
  async function doSubmit(filters: SearchFilters) {
    params = filters;
    return await mainProc.execute();
  }
  return {
    mainProc,
    doSubmit,
  };
});
