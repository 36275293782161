import * as api from "@/api";
import { useLazyAsyncState } from '@/utils';
import { useAsyncValidator } from '@/validator';
import { Rules } from "async-validator";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import { UserInfo } from "../../api/types";

export const useProfileUserEditStore = defineStore("profile-user-edit", () => {
  // フォーム
  const form = reactive(new UserInfo());

  // フォームが触られたなら検証を行う
  const rules = computed(
    () =>
      ({
        last_name: { required: true },
        first_name: { required: true },
        last_name_kana: { required: true },
        first_name_kana: { required: true },
        user_tel: [
          { required: true },
          // {
          //   type: "pattern",
          //   pattern:
          //     /^(?:\d{3} - \d{3} - \d{4}|\d{2} - \d{4} - \d{4}|\d{3} - \d{4} - \d{4})$/,
          //   message: "有効な電話番号ではありません",
          // },
        ],
        user_birthday_yy: {
          required: true,
          transform: val => val ? Number(val) : val,
          type: 'number', min: (new Date).getFullYear() - 200, max: (new Date).getFullYear(),
        },
        user_birthday_mm: {
          required: true,
          transform: val => val ? Number(val) : val,
          type: 'number', min: 1, max: 12,
        },
        user_birthday_dd: {
          required: true,
          transform: val => val ? Number(val) : val,
          type: 'number', min: 1, max: 31,
        },
      }) as Rules
  );

  // 検証用オブジェクト
  const validate = useAsyncValidator(form, rules);
  const suppressValidateError = ref(true);

  const validateErrorModel = computed(() => {
    for (var idx in validate.errors) {
      const err = validate.errors[idx];
    }
  });

  const mainProc = useLazyAsyncState(() => api.updateAccount(form), {});



  const initialized = ref(false);
  const done = ref(false);
  const cardErrorMessage = ref(null);
  return {
    cardErrorMessage,
    suppressValidateError,
    form,
    validate,
    initialized,
    done,
    mainProc,

    async init() {
      const profile = await api.profileHome()
      form.last_name = profile.last_name;
      form.first_name = profile.first_name;
      form.last_name_kana = profile.last_name_kana;
      form.first_name_kana = profile.first_name_kana;
      form.user_tel = profile.user_tel;

      const { user_birthday } = profile;
      const birthDayParts = user_birthday.split("-");

      form.user_birthday_yy = birthDayParts[0] ?? "";
      form.user_birthday_mm = birthDayParts[1] ?? "";
      form.user_birthday_dd = birthDayParts[2] ?? "";

      initialized.value = true;
    },

    async doSubmit() {
      suppressValidateError.value = false;
      if (!validate.pass.value) {
        return;
      }


      try {
        const mainProcData = await mainProc.execute();

        if (mainProc.error.value) {
          return;
        }

        done.value = mainProcData;
        alert("保存しました。")
      } catch (error) {
        mainProc.error.value = error;
        return;
      }
    },
  };
});
