<script setup lang="ts">
import { RouterView } from 'vue-router';
import SplashView from '../splash/splash.vue';
import { useAppStore } from './app.store';

// import { useAppStore } from './stores/app';

var store = useAppStore();
store.init();

</script>
<template>
  <transition name="fade">
    <SplashView v-if="store.isMobileVersion && store.showSplash" :style="{zIndex: 99999999999999999}"/>
  </transition>

  <div v-if="store.initialized" class="flex flex-col w-screen h-screen overflow-auto" :style="store.isIos ? {
    paddingTop: store.safeAreaInsets.insets.top + 'px',
    paddingRight: store.safeAreaInsets.insets.right + 'px',
    paddingBottom: store.safeAreaInsets.insets.bottom + 'px',
    paddingLeft: store.safeAreaInsets.insets.left + 'px',
  } : {}">
    <RouterView />
  </div>
</template>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}

  
</style>
