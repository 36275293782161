<script setup lang="ts">
import TitleBar from '@/components/title-bar.vue';
import { Phone, Sailboat, MessageSquare } from "lucide-vue-next";
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAppStore } from '../app/app.store';
import { useBusinessReserveStore } from './business-reserve.store';

const props = withDefaults(defineProps<{
  businessId: string,
}>(), {
})

const appStore = useAppStore();
const router = useRouter();
const initialized = ref(false);
const store = useBusinessReserveStore();
store.initShipList(props.businessId);

const isLogin = appStore.isAuthenticated;

(async () => {
  if (!isLogin) {
    router.replace({ name: 'login', query: {
      redirect: encodeURIComponent(location.href)
    } });
  }

  initialized.value = true;
})();

function doPickShip(shipId, shipName) {
  store.form.ship_uuid = shipId;
  store.form.ship_name = shipName;
  if(store.reserve.user){
    if(store.reserve.business){
      router.push({ name: 'business-reserve-form' });
    }else{
      router.push({ name: 'business-reserve-calendar' });
    }
  }
}

</script>

<template>
  <main class="flex flex-col" v-if="initialized">
    <v-async-block :proc="store.businessShipProc">
      <div v-if="store.reserve.user">
        <TitleBar class="text-xl" title="船選択" :back="false" />

        <div v-if="store.businessShipProc.state.ship.length === 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
          船情報がありません
        </div>
        <div v-else class="grid w-full grid-cols-1 gap-4 p-4 overflow-auto md:grid-cols-2">
        <!-- <template v-for="i in 5"> -->
          <div v-for="(v, k) in store.businessShipProc.state.ship" :key="k" @click="doPickShip(v.ship_uuid, v.ship_name)" class="aspect-square">
            <v-img :src=v.img class="object-cover w-full h-full" />
            <div class="absolute top-0 right-0 px-12 py-4 rounded-bl-lg text-xl"
             :style="{ backgroundColor : `#${v.ship_background_color}`, color : `#${v.ship_character_color}`}">{{ v.ship_name }}</div>
            <!-- <div
                class="absolute bottom-0 flex flex-col w-full gap-2 p-2 overflow-hidden text-white bg-black/30 text-ellipsis">
                <div class="flex items-center gap-2"><Sailboat class="w-4 h-4"/>{{ v.ship_name }}</div>
                <div class="flex items-center gap-2"><MessageSquare class="w-4 h-4"/>{{ v.ship_explain }}</div>
            </div> -->
          </div>
        <!-- </template> -->
        </div>
      </div>
      <div v-else>
        <div class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
          <div class="inline-block">
            <div class="flex flex-col gap-2">
              <div>
                ご予約はこちらへお電話ください。
              </div>
              <!--<a v-for="(tel, k) in store.business_tel" 
                :key="k" :href="'tel:' + tel" 
                class="flex items-center justify-between gap-2 py-4 btn"
                @click="store.registerShop(props.businessId)">
                <Phone class="w-4 h-4" /><span class="flex-1" >{{ tel }}</span>
              </a>-->
              <a 
                :href="'tel:' + store.businessShipProc.state.business_tel" 
                class="flex items-center justify-between gap-2 py-4 btn"
                @click="store.registerShop(props.businessId)">
                <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_name }}　{{ store.businessShipProc.state.business_tel }}</span>
              </a>
              <a v-if="store.businessShipProc.state.business_name_2 && store.businessShipProc.state.business_tel_2" 
                :href="'tel:' + store.businessShipProc.state.business_tel_2" 
                class="flex items-center justify-between gap-2 py-4 btn"
                @click="store.registerShop(props.businessId)">
                <Phone class="w-4 h-4" /><span class="flex-1" >{{ store.businessShipProc.state.business_name_2 }}　{{ store.businessShipProc.state.business_tel_2 }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </v-async-block>
  </main>
</template>

<style></style>
