import * as api from "@/api";
import { CreditParams } from "@/api/types";
import { gmoInit, useLazyAsyncState } from '@/utils';
import { defineStore } from "pinia";
import { reactive, ref, watch } from "vue";
import { useAppStore } from "../app/app.store";
import { gmoGetCardBrand, gmoGetToken } from "./../../utils";

export const useCreditInfoStore = defineStore("credit-info", () => {
  // フォーム
  const form = reactive(new CreditParams());
  const credit = ref([]);
  const insertRes = ref(false);
  const updateRes = ref(false);
  const deleteRes = ref(false);
  const user_uuid = useAppStore().session.user.user_uuid;
  const mainProc = useLazyAsyncState(
    () => api.creditInfo(),
    {}
  );

  const insertProc = useLazyAsyncState(
    () => api.creditInsert(form),
    {}
  );

  const initialized = ref(false);
  async function Init() {
    gmoInit(window['env'].GMO_TEST);

    initialized.value = true;
    const mainProcData = await mainProc.execute();

    if (mainProc.error.value) {
      return;
    }

    credit.value = mainProcData;
  }
  const user_pay_uuid = ref([]);
  function UpdateInit(userPayId) {
    user_pay_uuid.value = userPayId;
  }

  const creditCardMask = ref({});

  // creditCardMaskが変わる時、フォームを更新
  watch(creditCardMask.value, (v: any) => {
    form.credit.number = v.unmasked;
  });

  const cardErrorMessage = ref(null);
  return {
    cardErrorMessage,
    creditInfo: credit,
    Init,
    form,
    creditCardMask,
    insertRes,
    updateRes,
    deleteRes,
    UpdateInit,
    user_pay_uuid,
    async Insert() {
      try {
        cardErrorMessage.value = null;
        const expire = form.credit.yy + form.credit.mm;
        const token = await gmoGetToken(form.credit.number, expire);
        if (credit.value.length == 0) {
          form.credit.defaultFlg = true;
        }
        form.credit.token = token.token;
        form.credit.token_at = token.token_at;
        form.credit.card_div = gmoGetCardBrand(form.credit.number);
        const insertProcData = await insertProc.execute();

        if (insertProc.error.value) {
          return;
        }
        insertRes.value = insertProcData;
      } catch (error) {
        console.log({ error })
        cardErrorMessage.value = error.message;
        return;
      }
    },

    async Update() {
      const userPayId = this.creditInfo[this.user_pay_uuid].user_pay_uuid;
      const memberID = this.creditInfo[this.user_pay_uuid].member_id;
      const cardSeq = this.creditInfo[this.user_pay_uuid].card_seq;
      const mm =  form.credit.mm;
      const yy =  form.credit.yy;
      const defaultFlg =  form.credit.defaultFlg;
      const updateProc = useLazyAsyncState(
        () => api.creditUpdate(userPayId,memberID,cardSeq,mm,yy,defaultFlg),
        {}
      );
      const updateProcData = await updateProc.execute();

      if (updateProc.error.value) {
        return;
      }
      updateRes.value = updateProcData;
    },

    async Delete() {
      const userPayId = this.creditInfo[this.user_pay_uuid].user_pay_uuid;
      const memberID = this.creditInfo[this.user_pay_uuid].member_id;
      const cardSeq = this.creditInfo[this.user_pay_uuid].card_seq;

      const deleteProc = useLazyAsyncState(
        () => api.creditDelete(userPayId,memberID,cardSeq),
        {}
      );

      const deleteProcData = await deleteProc.execute();

      if (deleteProc.error.value) {
        return;
      }
      deleteRes.value = deleteProcData;
    },
  };
});
