import * as api from "@/api";
//import { ResetPasswordEmailParams } from "@/api/types";
import { ResetPasswordParams } from "@/api/types";
import router from "@/router";
import { useLazyAsyncState } from "@/utils";
import { useAsyncValidator } from "@/validator";
import type { Rules } from "async-validator";
import { defineStore } from "pinia";
import { computed, reactive, ref, watch } from "vue";

export const useAuthResetPasswordStore = defineStore(
  "auth-reset-password",
  () => {
    // フォーム
    //const form = reactive(new ResetPasswordEmailParams());
    const form = reactive(new ResetPasswordParams());

    // フォームが触られたなら検証を行う
    const rules = computed(
      () =>
        ({
            //address: { type: "email", required: true },
            user_password: [{ required: true }],
            user_password_conf: [
              { required: true },
              {
                validator(rule, value, callback) {
                  return value === form.user_password;
                },
                message: 'パスワードと一致しません',
              }
            ],
          }) as Rules
    );

    // 検証用オブジェクト
    const validate = useAsyncValidator(form, rules);
    const suppressValidateError = ref(true);

    //const mainProc = useLazyAsyncState(() => api.resetPasswordEmail(form), {});
    const mainProc = useLazyAsyncState(() => api.resetPassword(form), {});
    const done = ref(false);
    return {
      form,
      suppressValidateError,
      validate,
      mainProc,
      done,
      async doSubmit() {
        suppressValidateError.value = false;
        
        if (!validate.pass.value) {
          return;
        }

        const mainProcData = await mainProc.execute();

        if (mainProc.error.value || !mainProcData.res) {
          // msg.value = "エラー";
          done.value = true;
          return;
        }
        
        await router.replace({ name: 'login' })
        // try {
        //   const loginResult = await api.login({
        //     email:form.user_id,
        //     password:form.user_password
        //   })
        // } catch(err) {
        //   // TODO
        // }

        
        // done.value = true;


      },
    };
  }
);
