<script setup lang="ts">
import { AlertOctagon } from "lucide-vue-next";
import TitleBar from "../../components/title-bar.vue";

import ErrorMessage from "@/components/error-message.vue";
import router from "@/router";
import { ref } from "vue";
import { useAppStore } from "../app/app.store";
import { useRegisterEmailSendStore } from "./register-email-send.store";

const appStore = useAppStore();
const store = useRegisterEmailSendStore();
store.$reset();
store.msg = "";

const showSentMessage = ref(false);
async function send() {
  await store.send();

  if (store.msg == "") {
    if (appStore.isMobileVersion) {
      router.push({ name: "register-email-confirm" });
    }

    else {
      showSentMessage.value = true;
    }
  }
}
</script>

<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="メールアドレス確認" />

    <hr class="mx-4" />

    <div v-if="!showSentMessage" class="flex flex-col gap-4 p-8">
      <div class="flex items-center gap-2 p-3 text-red-800 border border-red-500 rounded-lg bg-red-50" v-if="store.msg">
        <AlertOctagon class="w-4 h-4"/>
        {{ store.msg }}
      </div>
      <p class="font-medium">メールアドレスを入力してください</p>
      <input
        v-model="store.form.email"
        :class="{ error: !store.suppressValidateError && store.validate.errorFields['email']?.length }"
        type="email"
        class="w-full form-input"
      />
      
      <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['email'] ?? [])[0]?.message
            "
          />
      <button @click="send()" :disabled="!store.form.email" class="w-full btn">登録する</button>
    </div>
    <div v-else class="p-4 text-center">
      メールアドレス宛（{{ store.form.email }}）に、会員登録のURLを送信いたしました。
    </div>
  </main>
</template>
