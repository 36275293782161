<script setup lang="ts">
import router from "@/router";
import { gmoGetCardBrandName } from "@/utils";
import { Edit, Plus, Trash } from "lucide-vue-next";
import { onMounted } from "vue";
import TitleBar from "../../components/title-bar.vue";
import { useCreditInfoStore } from "./credit-info.store";

const store = useCreditInfoStore();
store.$reset();
onMounted(() => {
  store.Init();
});
function Update(user_pay_uuid) {
  router.push({ name: "credit-update", params: { userPayId: user_pay_uuid } });
}
function Delete(user_pay_uuid) {
  router.push({ name: "credit-delete", params: { userPayId: user_pay_uuid } });
}
function Insert() {
  router.push({ name: "credit-insert" });
}
</script>
<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="クレジットカード情報一覧" />
    <div v-if="store.creditInfo.length === 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
      クレジットカード情報がありません
    </div>
    <div class="flex flex-col flex-1 gap-4 p-4 overflow-auto">
      <div
          v-for="(info, k) in store.creditInfo" :key="k"
          class="flex gap-4 border rounded h-16--">
          <!-- <img src="@/assets/amex.svg"/> -->
          <div class="flex flex-col justify-between flex-1 p-4">
            <div>**** **** **** {{ info.card_number_4 }}</div>
            <div>{{ gmoGetCardBrandName(info.card_div) }}</div>
          </div>
          <div class="flex flex-col gap-4--">
            <button @click="Update(k)" class="flex items-center justify-center gap-2 p-4 pb-2 text-primary">
              <Edit class="w-4 h-4"/>
              更新
            </button>
            <button 
              v-if="store.creditInfo.length > 1 && info.credit_uuid !== info.user_pay_uuid"
              @click="Delete(k)" class="flex items-center justify-center gap-2 p-4 pt-2 text-red-600">
              <Trash class="w-4 h-4"/>
              削除
            </button>
          </div>
      </div>

      <button @click="Insert()" class="flex justify-center w-full gap-2 btn">
        <Plus/>
        追加
      </button>
    </div>
  </main>
</template>
