import { useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";

export const useRegisterEmailConfirmStore = defineStore(
  "register-email-confirm",
  () => {
    const msg = ref("");
    const codes = reactive(Array(4));
    
    const sentCode = useSessionStorage('code', null);
    const sentTime = useSessionStorage('sendTime', null);

    async function send() {
      const now = Date.now();
      if (sentTime.value && ((now - Number(sentTime.value)) / 86400000 > 1)) {
        msg.value = "コードが間違っています";
        return;
      }

      const valid = sentCode.value === codes.join('');

      msg.value = valid ? '' : 'コードが間違っています';

    }
    return {
      codes,
      send,
      msg
    };
  }
);
