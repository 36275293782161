<script setup lang="ts">
import * as api from "@/api";
import { SearchFilters } from '@/api/types';
import BusinessPreview from '@/components/business-preview.vue';
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import "leaflet/dist/leaflet.css";
import { Search } from "lucide-vue-next";
import { onMounted } from 'vue';
import { useRouter } from "vue-router";
import { useSearchResultStore } from './search-result.store';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

const props = withDefaults(defineProps<{
  filters: SearchFilters,}>(), {})
const router = useRouter();
const store = useSearchResultStore();
function moveBusiness(uuid) {
  router.push({ name: 'business-home', params: { businessId : uuid } })
}
let page = 1;
async function loadMore() {
  return api.searchFilters({...props.filters, p: ++page})
}
onMounted(() => {
  store.doSubmit(props.filters);
})
const load = async $state => {
  if(!(store.mainProc.state !== undefined && store.mainProc.state.length === 0)){
    console.log("loading...");
    try {
      const res = await loadMore()
      if (res === undefined || res.length < 1) {
        $state.complete();
      }
      else {
        store.mainProc.state = store.mainProc.state.concat(res)
        $state.loaded();
      }
    } catch (error) {
      $state.error();
    }
  }else{
    $state.complete();
  }
};
</script>
<template>
  <main class="flex flex-col">
    <section class="relative h-14">
      <div class="z-10 flex items-center w-full h-full gap-1">
        <button type="button" class="p-4">
          <Search class="w-5 h-5" />
        </button>
        <div class="flex-1 p-4 pl-0 font-semibold">
          検索結果
        </div>
        <a @click="router.replace({name:'search'})" class="p-4 font-semibold">
          <span class="flex-1 text-primary">戻る</span>
        </a>
      </div>
    </section>
    <div class="flex-1 overflow-auto">
      <v-async-block :proc="store.mainProc">
        <!-- <template #loading>
          xxx
        </template> -->
        <div class="z-0 flex flex-col bg-gray-100 flexgap-1">
          <BusinessPreview 
            v-for="(data, k) in store.mainProc.state" 
            :key="k" 
            :business-info="data" 
            @click="moveBusiness(data.business_uuid)"
            class="px-4 bg-white"/>
        </div>
        <InfiniteLoading @infinite="load">
          <template #complete>
            <span> </span>
          </template>
        </InfiniteLoading>
        <div v-if="store.mainProc.state !== undefined && store.mainProc.state.length === 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
          該当する釣り船がありません
        </div>
      </v-async-block>
    </div>
  </main>
</template>
<style></style>
