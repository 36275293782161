<script setup lang="ts">
import BottomNavigationBar from '@/components/bottom-navigation-bar.vue';
import { useAppStore } from '@/modules/app/app.store';
import { useBusinessHomeStore } from '@/modules/business-home/business-home.store';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const businessHomeStore = useBusinessHomeStore();
const appStore = useAppStore();
const props = withDefaults(defineProps<{
  businessId: string,
}>(), {
})

const route = useRoute();
const transitionName = computed(() => {
  const name = route.meta.isBack ? 'slide-fade-right'
    : route.meta.transition ? (route.meta.transition + '')
      : 'slide-fade-left';

  return name;
})

const preventReserve = computed(() => {
  const isLogin = appStore.isAuthenticated;
  const canReserve = businessHomeStore.canReserve;
  return !isLogin && canReserve;
});

</script>

<template>
  <main class="flex flex-col flex-1">
    <div class="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
      <router-view v-slot="{ Component }">
        <transition :name="transitionName">
          <component :is="Component" class="absolute w-full h-full bg-white" />
        </transition>
      </router-view>
    </div>
    <BottomNavigationBar :items="[
      { labelEn: 'HOME', labelJa: 'ホーム', route: { name: 'business-home', params: { businessId }} },
      { labelEn: 'RESERVE', labelJa: '予約', route: { name: preventReserve ? 'login' : 'business-reserve', params: { businessId }} },
      { labelEn: 'CATCH', labelJa: '釣果', route: { name: 'business-catch', params: { businessId }} },
      { labelEn: 'INFO', labelJa: '瀬情報', route: { name: 'business-info', params: { businessId }} },
      { labelEn: 'SHOP', labelJa: '釣船情報', route: { name: 'business-shop', params: { businessId }} },
    ]"
    class="sticky bottom-0 left-0 z-10 w-full shadow-2xl"
    />
  </main>
</template>

<style>

</style>
