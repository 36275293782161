<script setup lang="ts">
import { ChevronRight } from "lucide-vue-next";
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useBusinessHomeStore } from './business-home.store';

const props = withDefaults(defineProps<{
  businessId: string,
}>(), {
})

const store = useBusinessHomeStore();
store.$reset();

const router = useRouter();
onMounted(() => {
  store.getBusiness(props.businessId);
});
function moveNews(uuid) {
  router.push({ name: 'business-news-list'})
}
function moveNewsDetail(uuid) {
  router.push({ name: 'business-news-detail', params: { newsId: uuid } })
}
function moveCatchDetail(uuid) {
  router.push({ name: 'business-catch-detail', params: { catchId: uuid } })
}
</script>

<template>
  <main class="flex flex-col flex-1 no-select-drag">
    <div class="relative aspect-square--">
      <v-img :src="store.img" class="object-cover w-full h-full" />
      <!-- <div class="absolute top-0 left-0 flex flex-col w-full h-full">
                      <img src="@/assets/business-home-photo-edge-frame.svg" class="w-full"/>
                      <img src="@/assets/head-photo-overlay.png" class="object-contain flex-1 w-[80%] m-auto"/>
                      <img src="@/assets/business-home-photo-edge-frame.svg" class="w-full rotate-180"/>
                    </div> -->

    </div>
    <div class="p-8">
      <div class="font-shalimar text-8xl text-primary">News</div>
      <div class="text-xl font-yuji text-primary">お知らせ</div>
      <div class="flex flex-col gap-6 mt-10">
        <div v-if="store.news.length == 0">
          お知らせがありません
        </div>
        <div class="relative" v-for="(v, k) in  store.news" :key="k"  @click="moveNewsDetail(v.news_uuid)">
          <div class="absolute top-0 left-0 z-0 w-full h-full -skew-y-12 rotate-12 md:transform-none bg-primary/25">
          </div>
          <div class="absolute right-0 z-10 text-white/80 font-yuji -top-4">
            <div class="relative px-4 py-1">
              <div class="absolute top-0 left-0 w-full h-full -skew-y-12 rotate-12 md:transform-none" :style="{ background: `#${v.news_background_color}`, }">
              </div>
              <div :style="{ color: `#${v.news_character_color}` }">{{ v.category_news_name }}</div>
            </div>
          </div>
          <a class="flex items-center p-2 text-primary">
            <div class="flex flex-col items-center">
              <div class="font-semibold"> {{ v.at_yy }}</div>
              <div class="text-xl font-semibold border-t border-primary"> {{ v.at_mm }}.{{ v.at_dd }}</div>
            </div>

            <div class="flex-1 text-center">
              {{ v.category_news_title }}
            </div>
            <button>
              <ChevronRight class="w-4 h-4" />
            </button>
          </a>
        </div>

        <router-link v-if="store.news.length > 0" :to="{ name: 'business-news-list' }"
          class="block w-48 p-2 mx-auto mt-4 text-center border border-primary text-primary">
          もっと見る
        </router-link>
      </div>
    </div>

    <img src="@/assets/wave-left.png" />
    <div class="p-8 pt-0 bg-[#f5f8fc]">

      <div class="font-shalimar text-8xl text-primary">Fishing</div>
      <div class="relative text-xl font-yuji text-primary -top-5">最新釣果情報</div>

      <div v-if="store.catchList.length == 0">
        最新釣果情報がありません
      </div>
      <div class="grid grid-cols-2 gap-2 mt-5 -mx-2">
        <div v-for="(cv, k) in  store.catchList" :key="k"  @click="moveCatchDetail(cv.catch_uuid)" class="relative p-2 bg-highlight/20">
          <div class="absolute z-10 flex justify-end w-full -left-2 text-white/80 font-yuji top-2">
            <div class="relative px-4 py-1">
              <div class="absolute top-0 left-0 w-full h-full md:transform-none"
               :style="{
                backgroundColor : `#${cv.ship_background_color}`
              }"></div>
              <div :style="{color : `#${cv.ship_character_color}`}">{{ cv.ship_name }}</div>
            </div>
          </div>

          <div class="flex flex-col gap-2" >
            <div class="relative">
              <v-img :src="cv.img"
                class="object-cover w-full h-full aspect-[4/3]" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="store.catchList.length > 0">
        <router-link :to="{ name: 'business-catch-list' }"
          class="block w-48 p-2 mx-auto mt-8 text-center border border-primary text-primary">
          もっと見る
        </router-link>
      </div>
    </div>

    <!-- <img src="@/assets/wave-right.png" class=" bg-[#f5f8fc]"/> -->
  </main>
</template>

<style></style>