import * as api from "@/api";
import { useLazyAsyncState } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useBusinessNewsListStore = defineStore("business-news-list", () => {

  let businessId = null;
  let p = 1;

  const mainProc = useLazyAsyncState(() => api.businessNewsList( businessId, p ), {});

  return {
    mainProc,
    async init(uuid, page) {
      businessId = uuid;
      p = page;
      await mainProc.execute();
    },
  };
});