<script setup lang="ts">
import BlockingLoader from '@/components/blocking-loader.vue';
import { ref } from 'vue';
import ErrorMessage from '../../components/error-message.vue';
import TitleBar from '../../components/title-bar.vue';
import { useSetPasswordStore } from './set-password.store';

const store = useSetPasswordStore();
const showPassword = ref(false);

const props = withDefaults(defineProps<{
  token: string,
}>(), {
})
store.form.token = props.token;
store.done = false;
</script>

<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="パスワード再設定" :back="false"/>
    <hr class="mx-4" />
    <form class="p-8" v-if="!store.done" @submit="e => {
      e.preventDefault();
      store.doSubmit();
    }">
      <div class="relative flex flex-col gap-4">
        <p>
          新しいパスワードを入力してください
        </p>
        <div>
          {{ store.msg }}
          <label class="flex flex-col flex-1">
            <span>パスワード<span class="text-xs text-gray-600">（半角英数字8-32字）</span></span>
            <div class="relative">
              <input v-model="store.form.password" :class="{
                error:!store.suppressValidateError && store.validate.errorFields['password']?.length,
              }" :type="showPassword ? 'text' : 'password'" class="w-full pr-[100px] form-input" />

              <button @click="showPassword = !showPassword" type="button"
                class="absolute w-[80px] text-gray-400 bg-gray-200 rounded-full bottom-3 right-3">
                表示{{ showPassword ? "しない" : "する" }}
              </button>
            </div>
            <ErrorMessage  v-if="!store.suppressValidateError"  :message="
              (store.validate.errorFields['password'] ?? [])[0]?.message
            " />
          </label>
        </div>
        <div class="flex gap-2 mt-4">
          <label class="flex flex-col flex-1">
            <span>パスワード確認<span class="text-xs text-gray-600">（半角英数字8-32字）</span></span>
            <input v-model="store.form.password_conf" :class="{
              error:!store.suppressValidateError && store.validate.errorFields['password_conf']?.length,
            }" type="password" class="w-full form-input" />
            <ErrorMessage  v-if="!store.suppressValidateError" :message="
              (store.validate.errorFields['password_conf'] ?? [])[0]
                ?.message
            " />
          </label>
        </div>
      </div>
      <button class="w-full mt-4 btn">
        登録する
      </button>
    </form>
    <BlockingLoader v-if="store.mainProc.isLoading" />

    <div class="p-8" v-if="store.done">
      パスワードを再設定いたしました。
    </div>
  </main>
</template>
