<script setup lang="ts">
import TitleBar from "@/components/title-bar.vue";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useBusinessInfoStore } from "./business-info.store";
const props = withDefaults(
  defineProps<{
    businessId: string;
  }>(),
  {}
);

const store = useBusinessInfoStore();
const router = useRouter();
store.businessUuid = props.businessId;
onMounted(() => {
  store.init();
});
</script>

<template>
  <main class="flex flex-col flex-1 no-select-drag">
    <TitleBar title="エリア選択" :back="false" />
    <v-async-block :proc="store.mainProc">
      <div class="grid flex-1 w-full grid-cols-1 gap-4 overflow-auto md:grid-cols-2 auto-rows-min">
      <!-- <template v-for="i in 5"> -->
        <router-link
          v-for="(v, k) in store.mainProc.state"
          :key="k"
          :to="{ name: 'business-info-list', params: { areaId: v.ground_uuid } }"
          class="flex aspect-[16/9] text-primary h-min"
        >
            <v-img
              :src="v.img"
              class="w-[70%] object-cover clip-path-polygon-[0_0,100%_0,90%_100%,0_100%]"
            />
            <div
              class="absolute h-full left-[58%] top-0 flex items-center justify-center"
            >
              <img
                src="@/assets/selector-rapids-left.svg"
                class="z-0 object-contain h-full p-2"
              />
              <div
                class="[writing-mode:vertical-rl] z-10 text-white absolute text-3xl tracking-[.35em] font-yuji"
              >
                {{ v.ground_title }}
              </div>
            </div>
            <div class="w-[30%] flex flex-col h-full pt-3 pr-3">
              <div
                class="flex self-end flex-1 overflow-hidden [writing-mode:vertical-rl] tracking-[.25em] line-clamp-3----- w-[4.5em] text-sm"
              >
                {{ v.ground_body }}
              </div>
              <a
                class="mt-1 border-b-primary block w-20 self-end border-b text-4xl [line-height:1rem] pl-2 py-1 bg-red-200---- block-- font-shalimar"
              >
                more
                <img
                  src="@/assets/arrow-head-right.svg"
                  class="absolute bottom-0 right-0 object-contain w-4"
                />
              </a>
            </div>
        </router-link>
      <!-- </template> -->
        <div
          v-if="store.mainProc.state.length === 0"
          class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20"
        >
          エリア情報がありません
        </div>
      </div>
    </v-async-block>
  </main>
</template>

<style></style>
