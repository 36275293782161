import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import router from './router';

export const addListeners = async (callback) => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  await PushNotifications.addListener('registration', token => {
    console.info('Push notification registration token: ', token.value);
    callback && callback.onTokenReceived(token.value);
  });

  await PushNotifications.addListener('registrationError', err => {
    console.log('Push notification registration error: ' + JSON.stringify(err.error));
  });

  // プッシュ通知受信時
  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ' + JSON.stringify(notification));
    callback && callback.onPushNotificationReceived(notification);
  });

  // プッシュ通知クリック時
  await PushNotifications.addListener('pushNotificationActionPerformed', async notification => {
    console.log('Push notification action performed: ' + JSON.stringify(notification));

    const { user_push_history_uuid: userPushHistoryUuid } = notification.notification.data;

    if (userPushHistoryUuid) {
      router.push({ name: 'notification', query: { userPushHistoryUuid, _r: Date.now() } })
    }
    
    await PushNotifications.removeAllDeliveredNotifications();
  });
}

export const register = async () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
}

export const unregister = async () => {
  await PushNotifications.removeAllListeners();
  await PushNotifications.removeAllDeliveredNotifications();
}

export const getDeliveredNotifications = async () => {
  if (!Capacitor.isNativePlatform()) {
    return [];
  }
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
  return notificationList;
}