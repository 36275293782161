<script setup lang="ts">
import BusinessPreview from '@/components/business-preview.vue';
import router from '@/router';
import { Icon } from '@iconify/vue';
import "leaflet/dist/leaflet.css";
import { useMyshopStore } from './my-shop.store';

const store = useMyshopStore();

store.init();

function moveBusiness(uuid) {
  router.push({ name: 'business-home', params: { businessId : uuid } })
}
</script>

<template>
  <main class="flex flex-col">
    <section class="relative h-14">
      <div class="z-10 flex items-center w-full h-full gap-1">
        <button type="button" class="p-4">
          <Icon icon="ph:storefront-light" class="w-5 h-5" />
        </button>
        <div class="flex-1 p-4 pl-0 font-semibold">
          マイショップ
        </div>
      </div>
    </section>
    <div class="flex-1 gap-1 overflow-auto">
      <v-async-block :proc="store.mainProc">
        <div class="z-0 flex flex-col gap-1 bg-gray-100">
          <BusinessPreview 
            v-for="(data, k) in store.mainProc.state" 
            :key="k" 
            :business-info="data"
            class="px-4 bg-white"
            @click="moveBusiness(data.business_uuid)"
            />
        </div>
      
        <div v-if="store.mainProc.state.length === 0" class="p-5 mx-4 text-center border border-dashed rounded-md border-black/20">
          データがありません
        </div>
      </v-async-block>
    </div>
  </main>
</template>
<style></style>
