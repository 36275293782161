<script setup lang="ts">
import TitleBar from "@/components/title-bar.vue";
import { formatNumber } from "@/utils";
import { parseDate } from '@/utils';
import { useMounted } from '@vueuse/core';
import { addDays, startOfMonth } from 'date-fns';
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useBusinessReserveStore } from "./business-reserve.store";

const router = useRouter();
const store = useBusinessReserveStore();
const isMounted = useMounted();
const currentPageData = reactive({ month: undefined, year: undefined });
const availableDates = ref([])
const holidayDates = ref([])
const initMonth = ref(null)
const initialized = ref(false)

function doTimeSelect(val, name, flg) {
  store.form.time = val;
  store.form.time_name = name;
  store.form.detail_flg = flg;
  router.push({ name: "business-reserve-info" });
}

const refPicker = ref(null);

function onMoved({ month, year }) {
  currentPageData.month = month;
  currentPageData.year = year;

  if (!isMounted.value) {
    return;
  }

  updateCalendarOnMoved();
  setHoliday(year, month);
}

function updateCalendarOnMoved() {
  const { month, year } = currentPageData;
  const thisMonthStartDay = new Date(year, month - 1, 1);
  const lastMonthStartDay = startOfMonth(addDays(thisMonthStartDay, -1));

  const lastMonthDateRange = availableDates.value.find(v => v.start.getTime() <= thisMonthStartDay.getTime());
  // console.log('lastMonthDateRange', lastMonthDateRange)
  document.querySelector('.vc-arrow.is-left').classList.toggle('is-disabled', lastMonthDateRange === undefined);
  document.querySelector('.vc-arrow.is-right').classList.toggle('is-disabled', !availableDates.value.length);
}

let refHoliday = [];
let refDetail = ref([]);
async function setHoliday(year, month){
  initialized.value = false;
  const data = await store.getBusinessReserveHoliday(year, month, store.ship.ship_uuid);
  refHoliday = [];
  refDetail.value = [];
  if(data.holiday.length > 0){
    for(let i = 0;i < data.holiday.length;i++){
      refHoliday.push(Number(data.holiday[i].holiday_day))
    }
  }
  refDetail.value = data.detail;
  initialized.value = true;
}

const availableDateProc = store.getBusinessReserveDateList();
onMounted(async () => {
  availableDates.value = (await availableDateProc).map(v => ({
    start: parseDate(v.start),
    end: parseDate(v.end),
  }));

  // console.log('availableDates', availableDates.value)

  const startDateRange = availableDates.value.find(v => v.start.getTime() >= Date.now());
  initMonth.value = startDateRange?.start;
  // console.log('availableDates', startDateRange?.start)

  if (startDateRange) {
    updateCalendarOnMoved();
  }
  const { month, year } = currentPageData;
  await setHoliday(year, month);
  initialized.value = true;
});
const attributes = ref([
  {
    content: {
      style: {
        color: 'red',
      },
    },
    dates: [

    ],
  },
]);
</script>

<template>
  <main class="flex flex-col">
    <TitleBar class="text-xl pt-4" :title="store.ship.ship_name + `日程選択`"/>
    <div class="relative">
      <v-date-picker
        ref="refPicker"
        @update:to-page="onMoved"
        :available-dates='availableDates'
        :disabled-dates--='availableDates.length ? undefined : {}'
        :from-date="initMonth"
        :attributes="attributes"
        v-model="store.date" 
        is-expanded 
        class="overflow-auto border-0 rounded-none">
        <template #header-title="{ month, year }"> {{ year }}年{{ month }}月 </template>
        <template v-if="!initialized" #day-content="{ day }">
          <div class="w-full h-full bg-gray-300 animate-pulse"></div>
        </template>
        <template v-if="initialized" #day-content="{ day }">
            <span class="!w-full !h-full vc-day-content vc-focusable relative" @click="store.date = new Date(day.id)">
              <div v-if="day.day in refDetail" class="absolute top-1 w-[90%] text-xs overflow-hidden bg-blue-600 text-white p-0.5 whitespace-nowrap"
              :style="{color: `#${store.ship.ship_character_color}`, backgroundColor : `#${store.ship.ship_background_color}`}">
                <span v-if="refDetail[day.day].length === 1">
                  {{ refDetail[day.day][0].ship_service_detail_name }}
                </span>
                <span v-if="refDetail[day.day].length > 1">
                  予定確認
                </span>
              </div>
              <div v-if="refHoliday.includes(day.day)" class="text-red-600">{{ day.day }}</div>
              <div v-if="!refHoliday.includes(day.day)">{{ day.day }}</div>
            </span>
        </template>
      </v-date-picker>
      <div 
        v-if="initialized && !availableDates.length"
        class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center bg-white/50">
        <div class="p-10 border rounded-lg border-slate-600 bg-slate-50">
          出航予定がありません
        </div>
      </div>
    </div>
    <input type="hidden" value="{{month}}" />

    <div v-if="store.date"  class="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center gap-2 py-4 bg-black/30">
      <div :class="{
        'grid-cols-1': true,
        
        'grid-cols-1-------': store.times.service.length + store.times.sche.length < 2 || store.times.detail && store.times.detail['ship_service_detail_name_sub'] == '',
        'grid-cols-2-------': store.times.service.length + store.times.sche.length >= 2 || store.times.detail && store.times.detail['ship_service_detail_name_sub'] != '',
      }" class="grid max-h-full gap-4 p-4 overflow-auto" v-if="store.times != null">
        <template v-if="store.times.reserved > 0">
          <div class="flex flex-col items-center justify-center p-5 text-center bg-white border rounded-md border-slate-400">
            <span class="whitespace-pre-wrap" style="color:red;">既に{{ store.times.reserved }}名様のご予約を承っております。<br>重複予約にご注意ください。</span>
          </div>
        </template>
        <template v-if="Object.keys(store.times.detail).length > 0">
          <!--
          <div class="flex flex-col items-center justify-center p-5 text-center bg-white border rounded-md border-slate-400">
            <span class="whitespace-pre-wrap">{{ store.times.detail['ship_service_detail_name'] }}</span>
            <span class="whitespace-pre-wrap">{{ store.times.detail['ship_service_detail_explain']  }}</span>
            <button 
              @click="doTimeSelect(store.times.detail['ship_service_detail_uuid'], store.times.detail['ship_service_detail_name'], 1)"
              class="w-full mt-4 btn" type="button">
              選択する
            </button>
          </div>
          <div v-if="store.times.detail['ship_service_detail_name_sub'] != ''"
            class="flex flex-col items-center justify-center p-5 text-center bg-white border rounded-md border-slate-400">
            <span class="whitespace-pre-wrap">{{ store.times.detail['ship_service_detail_name_sub'] }}</span>
            <span class="whitespace-pre-wrap">{{ store.times.detail['ship_service_detail_explain_sub'] }}</span>
            <button 
              @click="doTimeSelect(store.times.detail['ship_service_detail_uuid'] + '_sub', store.times.detail['ship_service_detail_name_sub'], 1)"
              class="w-full mt-4 btn" type="button">
              選択する
            </button>
          </div>
          -->
          <div v-for="(time, k) in store.times.detail" :key="k"
            class="flex flex-col items-center justify-center p-5 text-center bg-white border rounded-md border-slate-400">
            <span class="whitespace-pre-wrap">{{ time.ship_service_detail_name }}　{{ formatNumber(time.ship_service_detail_fare) }}円</span>
            <button 
              @click="doTimeSelect(time.ship_service_detail_uuid, time.ship_service_detail_name, 1)"
              class="w-full mt-4 btn" type="button">
              選択する
            </button>
          </div>
        </template>
        <template v-if="Object.keys(store.times.detail).length === 0">
          <div v-for="(time, k) in store.times.service" :key="k"
            class="flex flex-col items-center justify-center p-5 text-center bg-white border rounded-md border-slate-400">
            <span class="whitespace-pre-wrap">{{ time.ship_service_name }}</span>
            <span class="whitespace-pre-wrap">{{ time.ship_service_explain }}</span>
            <button 
              @click="doTimeSelect(time.ship_service_uuid, time.ship_service_name, 0)"
              class="w-full mt-4 btn" type="button">
              選択する
            </button>
          </div>
          <div v-for="(sche, sk) in store.times.sche" :key="sk"
            class="flex flex-col items-center justify-center p-5 text-center bg-white border rounded-md border-slate-400">
            <span class="whitespace-pre-wrap">{{ sche.ship_service_name }}</span>
            <span class="whitespace-pre-wrap">{{ sche.ship_service_explain }}</span>
            <button 
              @click="doTimeSelect(sche.ship_service_uuid, sche.ship_service_name, 0)"
              class="w-full mt-4 btn" type="button">
              選択する
            </button>
          </div>
        </template>
        <button v-if="store.getServicesProc.isReady && !store.times.service.length && !store.times.sche.length"
          class="flex flex-col items-center justify-center p-5 text-center whitespace-pre-wrap bg-white rounded-md">
          <span>{{ store.times.message || '予約不可能な日付です' }}</span>
        </button>

        <button 
        v-if="store.getServicesProc.isReady"
        @click.self="
          (e) => {
            store.date = null;
            store.times = {
              day: '',
              sche: [],
              service: [],
              detail: [],
              reserved: 0,
              message: null,
            };
          }
              "
          class="w-full btn secondary" type="button">
          キャンセル
        </button>
      </div>
      <div v-else class="flex flex-col items-center justify-center p-10 bg-white rounded-md">
        <span>{{ store.errorMsg }}</span>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.weekday-1 {
  @apply text-red-600;
}

.weekday-7 {
  @apply text-blue-600;
}

.vc-day {
  @apply aspect-square border-r border-b;

  &:nth-child(7n) {
    @apply border-r-0;
  }
}

.vc-weekday {
  @apply bg-gray-100 border-y py-2 text-xs;
}

.vc-weeks {
  @apply p-0;
}

.vc-header {
  @apply pb-3;
}

.vc-arrows-container {
  @apply justify-around;

  .vc-arrow {
    @apply px-5;
  }
}

.vc-day-content {
  @apply w-[80%] h-[80%];
}
.vc-highlight {
  @apply w-[80%] h-[80%];
}
.is-today {
  @apply bg-primary/10;
}
</style>
<style>
.vc-day {
  aspect-ratio: 1 / 1.3;
}
</style>
