<script setup lang="ts">
import TitleBar from '@/components/title-bar.vue';
import { ref, computed } from 'vue';
import { useBusinessShopStore } from './business-shop.store';

const props = withDefaults(defineProps<{
  businessId: string,
}>(), {})

const store = useBusinessShopStore();
store.init(props.businessId);

const mainPhotoIdx = ref(0);

const address = computed(() => {
  return  '〒' + store.mainProc.state.business_postal_code +
          '\n' +
          store.mainProc.state.business_prefecture +
          store.mainProc.state.business_city +
          store.mainProc.state.business_area +
          store.mainProc.state.business_address +
          store.mainProc.state.business_building;
})
</script>

<template>
  <main class="flex flex-col flex-1">
    <TitleBar title="釣船情報" :back="false"/>
    <div class="flex-1 overflow-auto">
      <v-async-block :proc="store.mainProc">
        <div class="p-4 pt-0" >
          <v-img :src="store.photos[mainPhotoIdx]" class="object-cover aspect-[4/3] w-full" />
          <div v-if="store.photos.length > 1" class="relative grid grid-cols-5 gap-2 mt-2">
            <div class="w-full h-full"
                v-for="(v, k) in store.photos" :key="k"
                @click="e => { mainPhotoIdx = k }">
              <v-img 
                :src="v"
                class="object-cover w-full h-full" />
            </div>
          </div>
        </div>

        <div class="p-4 pt-0">
          <table class="w-full">
            <tr 
              v-if="store.mainProc.state.business_name"
              class="border-b">
              <th class="w-20 font-semibold text-left whitespace-nowrap">渡船名</th>
              <td class="px-2 py-1 text-left">{{ store.mainProc.state.business_name }}<span v-if="store.mainProc.state.business_name_2">／{{ store.mainProc.state.business_name_2 }}</span></td>
            </tr>

            <tr 
              v-if="store.mainProc.state.business_tel"
              class="border-b">
              <th class="font-semibold text-left whitespace-nowrap">電話番号</th>
              <td class="px-2 py-1 text-left"><a :href="`tel:${store.mainProc.state.business_tel}`">{{ store.mainProc.state.business_tel }}</a>
                <span v-if="store.mainProc.state.business_tel_2">／<a :href="`tel:${store.mainProc.state.business_tel_2}`">{{ store.mainProc.state.business_tel_2 }}</a></span>
              </td>
            </tr>

            <tr 
              v-if="store.mainProc.state.business_mail"
              class="border-b">
              <th class="font-semibold text-left whitespace-nowrap">メール</th>
              <td class="px-2 py-1 text-left"><a :href="`mailto:${store.mainProc.state.business_mail}`">{{ store.mainProc.state.business_mail }}</a></td>
            </tr>

            <tr 
              v-if="address"
              class="border-b">
              <th class="font-semibold text-left whitespace-nowrap">出航場所</th>
              <td class="px-2 py-1 text-left whitespace-pre-wrap">
                <span v-if="store.mainProc.state.business_place">{{ store.mainProc.state.business_place }}</span>
                <span v-if="!store.mainProc.state.business_place">{{ address }}</span>
              </td>
            </tr>

            <tr 
              v-if="store.mainProc.state.business_explain"
              class="border-b">
              <th class="font-semibold text-left whitespace-nowrap">メッセージ</th>
              <td class="px-2 py-1 text-left whitespace-pre-wrap">{{ store.mainProc.state.business_explain }}</td>
            </tr>

          </table>
        </div>
      </v-async-block>
    </div>
  </main>
</template>

<style></style>