<script setup lang="ts">
import ErrorMessage from '@/components/error-message.vue';
import LoadingIcon from '@/components/loading-icon.vue';
import { vMaska } from "maska";
import { onMounted } from 'vue';
import TitleBar from '../../components/title-bar.vue';
import { useProfileUserEditStore } from './profile-user-edit.store';

const store = useProfileUserEditStore();
onMounted(() => {
  store.init();
})
</script>
<template>
  <fieldset class="flex flex-col flex-1 gap-1" :disabled="!store.initialized || store.mainProc.isLoading">
    <TitleBar title="会員情報変更" />

    <hr class="mx-4" />

    <form
      class="flex-1 p-4 overflow-auto"
      @submit="
        async (e) => {
          e.preventDefault();
          await store.doSubmit();
        }
      "
    >
      <p class="text-gray-600">名前</p>

      <div class="flex gap-2">
        <label class="flex flex-col flex-1 gap-1">
          姓
          <input
            v-model="store.form.last_name"
            :class="{ error: !store.suppressValidateError && store.validate.errorFields['last_name']?.length }"
            type="text"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['last_name'] ?? [])[0]?.message
            "
          />
        </label>

        <label class="flex flex-col flex-1 gap-1">
          名
          <input
            v-model="store.form.first_name"
            :class="{ error: !store.suppressValidateError && store.validate.errorFields['first_name']?.length }"
            type="text"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['first_name'] ?? [])[0]?.message
            "
          />
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          セイ
          <input
            v-model="store.form.last_name_kana"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['last_name_kana']?.length,
            }"
            type="text"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['last_name_kana'] ?? [])[0]?.message
            "
          />
        </label>

        <label class="flex flex-col flex-1 gap-1">
          メイ
          <input
            v-model="store.form.first_name_kana"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['first_name_kana']?.length,
            }"
            type="text"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['first_name_kana'] ?? [])[0]?.message
            "
          />
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          電話番号
          <input
            v-model="store.form.user_tel"
            :class="{ error: !store.suppressValidateError && store.validate.errorFields['user_tel']?.length }"
            type="tel"
            maxlength="13"
            v-maska
            data-maska="#"
            data-maska-tokens="#:\d:multiple"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_tel'] ?? [])[0]?.message
            "
          />
        </label>
      </div>

      <div class="flex gap-2 mt-4">
        <label class="flex flex-col flex-1 gap-1">
          生年月日
          <input
            v-model="store.form.user_birthday_yy"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_birthday_yy']?.length,
            }"
            type="tel"
            placeholder="年"
            v-maska
            data-maska="####"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_birthday_yy'] ?? [])[0]?.message
            "
          />
        </label>
        <label class="flex flex-col flex-1 gap-1">
          &nbsp;
          <input
            v-model="store.form.user_birthday_mm"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_birthday_mm']?.length,
            }"
            type="tel"
            v-maska
            data-maska="##"
            placeholder="月"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_birthday_mm'] ?? [])[0]?.message
            "
          />
        </label>
        <label class="flex flex-col flex-1 gap-1">
          &nbsp;
          <input
            v-model="store.form.user_birthday_dd"
            :class="{
              error: !store.suppressValidateError && store.validate.errorFields['user_birthday_dd']?.length,
            }"
            type="tel"
            v-maska
            data-maska="##"
            placeholder="日"
            class="w-full form-input"
          />
          <ErrorMessage
            v-if="!store.suppressValidateError"
            :message="
              (store.validate.errorFields['user_birthday_dd'] ?? [])[0]?.message
            "
          />
        </label>
      </div>
      
      <button
        :disabled="!store.initialized || store.mainProc.isLoading"
        class="w-full mt-4 btn"
      >
        <LoadingIcon class="absolute w-4 h-4 left-4" v-if="store.mainProc.isLoading"/>
        保存
      </button>
    </form>
  </fieldset>
</template>
