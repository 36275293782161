<script setup lang="ts">
import TitleBar from '@/components/title-bar.vue';
import { onMounted } from 'vue';
import { useBusinessNewsDetailStore } from './business-news-detail.store';
const props = withDefaults(defineProps<{
  newsId: string,
}>(), {
})

const store = useBusinessNewsDetailStore();
store.init(props.newsId);

</script>

<template>
  <main class="flex flex-col flex-1 no-select-drag">
    <TitleBar title="お知らせ" />
    <v-async-block :proc="store.mainProc">
      <div class="flex-1 gap-1 overflow-auto">
        <div class="p-4 m-4 bg-gray-100">
          <div class="p-4 bg-white rounded-md">
            <div class="flex justify-between text-sm text-gray-400">
              {{ store.mainProc.state.news_at }}
              <span class="text-white/80">
                  <span class="px-2 py-1 text-xs rounded" :style="{
                    background: `#${store.mainProc.state.category_news_background_color}`,
                    color: `#${store.mainProc.state.category_news_character_color}`,
                  }">{{ store.mainProc.state.category_news_name }}</span>
                </span>
            </div>

            <div class="mt-2 font-bold">
              {{ store.mainProc.state.news_title }}
            </div>

            <div class="mt-2 text-justify whitespace-pre-wrap">
              {{ store.mainProc.state.news_body }}
            </div>
          </div>
        </div>
      </div>
    </v-async-block>
  </main>
</template>

<style></style>